import {
  ChevronDownIcon,
  SortAscendingIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline";

interface SortButtonProps {
  sortOrder: "DESC" | "ASC" | "";
  sortField: string;
  active: boolean;
  updateSortCriteria: (newSortField: string) => void;
}

export default function SortButton(props: SortButtonProps) {
  return (
    <button onClick={() => props.updateSortCriteria(props.sortField)}>
      {props.active ? (
        props.sortOrder === "DESC" ? (
          <SortDescendingIcon className="text-gray-500 h-4 w-4 ml-1.5" />
        ) : (
          props.sortOrder === "ASC" && (
            <SortAscendingIcon className="text-gray-500 h-4 w-4 ml-1.5" />
          )
        )
      ) : (
        <ChevronDownIcon className="text-gray-500 h-4 w-4 ml-1.5" />
      )}
    </button>
  );
}
