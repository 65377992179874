import { RefreshIcon } from "@heroicons/react/outline";
import { UserCircleIcon } from "@heroicons/react/solid";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageNumbers from "../../../components/PageNumbers/PageNumbers";
import SearchFormV2 from "../../../components/SearchFormV2/SearchFormV2";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { useAuth } from "../../../utils/auth/auth";
import { apiV2 } from "../../../utils/helpers/api";
import { SearchFilter, SelfServeAuditSearchParams } from "../../../utils/types";

export default function SelfServeAuditsV2() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [selfServeAudits, setSelfServeAudits] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [newPageNumber, setNewPageNumber] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<0 | 1>(1);
  const [sortField, setSortField] = useState<string>("createdAt");
  const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);

  useEffect(() => {
    if (!auth.loading && auth.user) {
      apiV2.updateClient(auth.v2Token, auth.v2TokenExpiry);
      fetchSelfServeAudits();
    }
  }, [auth.user]);

  useEffect(() => {
    fetchSelfServeAudits();
  }, [pageNumber, searchFilters, sortField, sortOrder, rowsPerPage]);

  const fetchSelfServeAudits = async () => {
    setLoading(true);
    try {
      const searchParams = {} as SelfServeAuditSearchParams;
      for (const filter of searchFilters) {
        searchParams[filter.searchField as keyof SelfServeAuditSearchParams] =
          filter.searchQuery;
      }

      const asics = await apiV2.getSelfServeAudits(
        pageNumber - 1,
        rowsPerPage,
        sortOrder,
        searchParams
      );

      setSelfServeAudits(asics.data.items);
      setTotalCount(asics.data.total);
    } catch (error) {
      console.error(
        `Error occurred while fetching ${rowsPerPage} selfServeAudits of page ${pageNumber} - `,
        error
      );
    }
    setLoading(false);
  };

  const updateSortCriteria = (newSortField: string) => {
    setSortField(newSortField);
    if (newSortField === sortField) {
      if (sortOrder === 0) setSortOrder(1);
      else setSortField("");
    } else {
      setSortOrder(0);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <UserCircleIcon className="h-8 w-8 mr-1.5" />
                    Self Serve Audits
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the self serve audits in AEV including their
                    details.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none items-center flex flex-row">
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => fetchSelfServeAudits()}
                    className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-stone-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 sm:w-auto disabled:hover:bg-stone-600"
                  >
                    <RefreshIcon className="h-5 w-5 mr-1.5" />
                    Refresh
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col">
                <SearchFormV2
                  searchOptions={[
                    { name: "ASIC ID", value: "asicId" },
                    { name: "First Name", value: "firstName" },
                    { name: "Last Name", value: "lastName" },
                    { name: "Company", value: "company" },
                    { name: "Access Location", value: "accessLocation" },
                  ]}
                  updateSearchFilters={(newSearchFilters) => {
                    setSearchFilters(newSearchFilters);
                  }}
                />
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                ASIC ID
                                {/* <SortButton
                                  active={sortField === "vehicleRego"}
                                  sortOrder={sortOrder}
                                  sortField={"vehicleRego"}
                                  updateSortCriteria={updateSortCriteria}
                                /> */}
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                First Name
                                {/* <SortButton
                                  active={sortField === "location"}
                                  sortOrder={sortOrder}
                                  sortField={"location"}
                                  updateSortCriteria={updateSortCriteria}
                                /> */}
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Last Name
                                {/* <SortButton
                                  active={sortField === "lane"}
                                  sortOrder={sortOrder}
                                  sortField={"lane"}
                                  updateSortCriteria={updateSortCriteria}
                                /> */}
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Company
                                {/* <SortButton
                                  active={sortField === "numOccupants"}
                                  sortOrder={sortOrder}
                                  sortField={"numOccupants"}
                                  updateSortCriteria={updateSortCriteria}
                                /> */}
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Submitted On
                                {/* <SortButton
                                  active={sortField === "createdAt"}
                                  sortOrder={sortOrder}
                                  sortField={"createdAt"}
                                  updateSortCriteria={updateSortCriteria}
                                /> */}
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Access Location
                                {/* <SortButton
                                  active={sortField === "completedDate"}
                                  sortOrder={sortOrder}
                                  sortField={"completedDate"}
                                  updateSortCriteria={updateSortCriteria}
                                /> */}
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">View</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {selfServeAudits.map((selfServeAudit) => (
                            <tr
                              key={selfServeAudit.id}
                              className="hover:bg-slate-50 cursor-pointer"
                              onClick={() =>
                                navigate(
                                  "/v2/self-serve-audits/edit/" +
                                    selfServeAudit.id
                                )
                              }
                            >
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {selfServeAudit.asicId}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {selfServeAudit.firstName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {selfServeAudit.lastName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {selfServeAudit.company}
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {format(
                                  new Date(selfServeAudit.submittedOn),
                                  "dd/MM/yyyy hh:mm aa"
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {selfServeAudit.accessLocation}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <Link
                                  to={
                                    "/vehicle-audits/edit/" + selfServeAudit.id
                                  }
                                >
                                  <div className="text-amber-600 hover:text-amber-900">
                                    View
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="py-3 flex items-center justify-between border-t border-gray-200">
                      <div className="flex-1 flex justify-between sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50"
                        >
                          Previous
                        </a>
                        <a
                          href="#"
                          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50"
                        >
                          Next
                        </a>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="flex flex-row items-center">
                          {loading ? (
                            <p className="text-sm text-gray-700">Loading...</p>
                          ) : totalCount > 0 ? (
                            <>
                              <p className="text-sm text-gray-700">
                                Showing{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber - rowsPerPage + 1}
                                </span>{" "}
                                to{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber > totalCount
                                    ? totalCount
                                    : rowsPerPage * pageNumber}
                                </span>{" "}
                                of{" "}
                                <span className="font-medium">
                                  {totalCount}
                                </span>{" "}
                                results
                              </p>
                            </>
                          ) : (
                            <p className="text-sm text-gray-700">
                              Showing 0 results.
                            </p>
                          )}
                          <div className="text-sm text-gray-700 inline-flex flex-row items-center ml-1.5">
                            <div>Results per page: </div>
                            <div>
                              <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                className="h-8 ml-1.5 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-600 text-sm sm:leading-6"
                                value={rowsPerPage}
                                onChange={(e) =>
                                  setRowsPerPage(parseInt(e.target.value))
                                }
                                disabled={loading}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <PageNumbers
                          rowsPerPage={rowsPerPage}
                          totalCount={totalCount}
                          pageNumber={pageNumber}
                          setPageNumber={setPageNumber}
                          newPageNumber={newPageNumber}
                          setNewPageNumber={setNewPageNumber}
                          disabled={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
