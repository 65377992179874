import { Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CloudDownloadIcon,
  XIcon,
} from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV2 } from "../../../../utils/helpers/api";
import { classNames } from "../../../../utils/helpers/misc";

export default function ImportedASICV2() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [importedAsic, setImportedAsic] = useState<any>(null);
  const [importedAsicFileUrl, setImportedAsicFileUrl] = useState<string>("");
  const [importedAsicReportFileUrl, setImportedAsicReportFileUrl] =
    useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchImportedAsic().finally(() => setLoading(false));
  }, []);

  const fetchImportedAsic = async () => {
    // Get imported ASIC details from database
    const asicImportId = params.asicImportId;

    try {
      if (asicImportId) {
        apiV2.getASICImportById(asicImportId).then((asicImportRes) => {
          setImportedAsic(asicImportRes.data);
          fetchImportedAsicFile(asicImportRes.data);
          fetchImportedAsicReportFile(asicImportRes.data);
        });
      } else {
        setError(true);
      }
    } catch (error) {
      console.error(
        `Error occurred while initializing user and airports with user id ${asicImportId}`,
        error
      );
    }
  };

  const fetchImportedAsicFile = async (importedAsic: any) => {
    setLoading(true);
    // Get file details from database
    try {
      apiV2
        .getASICImportFileById(importedAsic.originalFileName)
        .then((asicImportFileRes) => {
          const blob = new Blob([asicImportFileRes.data], {
            type: "text/csv;charset=utf-8;",
          });
          const url = URL.createObjectURL(blob);
          setImportedAsicFileUrl(url);
        });
    } catch (error) {
      setError(true);
      console.error(`Error occurred while fetching imported ASIC file`, error);
    }
    setLoading(false);
  };

  const fetchImportedAsicReportFile = async (importedAsic: any) => {
    setLoading(true);
    // Get file details from database
    try {
      apiV2
        .getASICImportReportFileById(importedAsic.reportFileName)
        .then((asicImportReportFileRes) => {
          const blob = new Blob([asicImportReportFileRes.data], {
            type: "text/csv;charset=utf-8;",
          });
          const url = URL.createObjectURL(blob);
          setImportedAsicReportFileUrl(url);
        });
    } catch (error) {
      setError(true);
      console.error(
        `Error occurred while fetching imported ASIC report file`,
        error
      );
    }
    setLoading(false);
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    ASIC Import File
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    View the details of an imported ASIC file
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              {!loading && importedAsic ? (
                <div className="mt-8 flex flex-col max-w-screen-md">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200">
                      <div>
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            File information
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            This information will be displayed publicly
                          </p>
                        </div>

                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-4">
                            <label
                              htmlFor="username"
                              className="block text-sm font-medium text-gray-700"
                            >
                              File name
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <a
                                href={importedAsicFileUrl}
                                download={importedAsic.originalFileName}
                                className={classNames(
                                  "inline-flex flex-row items-center flex-1 font-semibold hover:underline cursor-pointer p-2 block w-full min-w-0 rounded-md sm:text-sm border border-gray-300 bg-gray-100",
                                  importedAsicFileUrl
                                    ? "text-indigo-500"
                                    : "cursor-not-allowed opacity-60"
                                )}
                              >
                                <CloudDownloadIcon className="h-4 w-4 mr-1.5" />
                                {importedAsic.originalFileName ?? ""}
                              </a>
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Imported At
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                disabled
                                value={
                                  new Date(
                                    importedAsic.importedOn ?? ""
                                  ).toString() ?? ""
                                }
                                className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Status
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                autoComplete="family-name"
                                disabled
                                value={importedAsic.importState ?? ""}
                                className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-4">
                            <label
                              htmlFor="processingStartTime"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Processing Started At
                            </label>
                            <div className="mt-1">
                              <input
                                id="processingStartTime"
                                name="processingStartTime"
                                type="text"
                                autoComplete="processingStartTime"
                                disabled
                                value={
                                  new Date(
                                    importedAsic.processingStartTime ?? ""
                                  ).toString() ?? ""
                                }
                                className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-4">
                            <label
                              htmlFor="processingEndTime"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Processing Ended At
                            </label>
                            <div className="mt-1">
                              <input
                                id="processingEndTime"
                                name="processingEndTime"
                                type="text"
                                autoComplete="processingEndTime"
                                disabled
                                value={
                                  new Date(
                                    importedAsic.processingEndTime ?? ""
                                  ).toString() ?? ""
                                }
                                className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Import Stats
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Statistics of the import process.
                          </p>
                        </div>
                        <div className="pt-2">
                          <div>
                            <span className="font-semibold mr-1.5">
                              ASICS before Import
                            </span>
                            <span>{importedAsic.databaseRecordsBefore}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">
                              Lines in file
                            </span>
                            <span>{importedAsic.lines}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">
                              Valid records in file
                            </span>
                            <span>{importedAsic.incomingRecords}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">
                              Malformed records in file
                            </span>
                            <span>{importedAsic.malformedRecords}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">
                              Records selected in file
                            </span>
                            <span>{importedAsic.incomingRecordsSelected}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">
                              ASICs added
                            </span>
                            <span>{importedAsic.newAsicsAdded}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">
                              ASICs updated
                            </span>
                            <span>{importedAsic.asicsUpdated}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">
                              ASICs withdrawn
                            </span>
                            <span>{importedAsic.asicsDeactivated}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">
                              Identical ASICs
                            </span>
                            <span>{importedAsic.identicalDetails}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">
                              ASICs after import
                            </span>
                            <span>{importedAsic.databaseRecordsAfter}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-5">
                      <div className="flex space-x-2 justify-start items-center">
                        <button
                          onClick={() => navigate("/v2/asic-import")}
                          type="button"
                          className="inline-flex flex-row items-center bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        >
                          <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                          Back
                        </button>
                        <a
                          href={importedAsicFileUrl}
                          download={importedAsic.originalFileName}
                          className={classNames(
                            "inline-flex flex-row items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500",
                            importedAsicFileUrl
                              ? ""
                              : "cursor-not-allowed opacity-60"
                          )}
                        >
                          <CloudDownloadIcon className="h-4 w-4 mr-1.5" />
                          Original File
                        </a>
                        <a
                          href={importedAsicReportFileUrl}
                          download={importedAsic.reportFileName}
                          className={classNames(
                            "inline-flex flex-row items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60",
                            importedAsicReportFileUrl
                              ? ""
                              : "cursor-not-allowed opacity-60"
                          )}
                        >
                          <CloudDownloadIcon className="h-4 w-4 mr-1.5" />
                          Report File
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
