import {
  ArrowLeftIcon,
  DocumentTextIcon,
  LocationMarkerIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { LocationAuditPolicy } from "../../../../utils/types";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function CreateAEVLocationConfigurationV3() {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPercentage, setTotalPercentage] = useState<number>(0);
  const [duplicateAuditPolicies, setDuplicateAuditPolicies] = useState<boolean>(false);
  const [requiredScan, setRequiredScan] = useState<number>(0);
  const [extensionThreshold, setExtensionThreshold] = useState<number>(0);
  const [extensionAmount, setExtensionAmount] = useState<number>(0);
  const [locationAuditPolicies, setLocationAuditPolicies] = useState<LocationAuditPolicy[]>([]);
  const [auditPolicies, setAuditPolicies] = useState<any[]>([]);

  const createLocationConfiguration = async () => {
    setLoading(true);
    try {
      if (params.locationId) {
        await apiV3.createLocationAuditConfiguration({
          locationId: params.locationId,
          extensionAmount,
          extensionThreshold,
          requiredScan,
          locationAuditPolicies,
        });
        showSuccessToast("Successfully created location audit configuration");
        navigate(`/v3/aev-locations/edit/${params.locationId}`);
      } else {
        console.error(
          "Error occurred while creating location audit configuration - no location id in params"
        );
        showErrorToast();
        setLoading(false);
      }
    } catch (error) {
      console.error("Error occurred while creating location audit configuration - ", error);
      showErrorToast();
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchInputData();
  }, []);

  useEffect(() => {
    let totalPercentage = 0;
    for (const locationAuditPolicy of locationAuditPolicies) {
      totalPercentage += locationAuditPolicy.percentage;
    }

    setTotalPercentage(totalPercentage);

    // Check for duplicate audit policies
    let seen = new Set();
    const hasDuplicates = locationAuditPolicies.some((lap) => {
      return seen.size === seen.add(lap.auditPolicyId).size;
    });

    setDuplicateAuditPolicies(hasDuplicates);
  }, [locationAuditPolicies]);

  const fetchInputData = async () => {
    setLoading(true);
    try {
      await fetchAuditPolicies();
    } catch (error) {
      console.error("Error occurred while fetching input data - ", error);
      showErrorToast();
    } finally {
      setLoading(false);
    }
  };

  const fetchAuditPolicies = async () => {
    const auditPolicies = await apiV3.getAuditPolicies(1, 1000, "", "", "DESC", "");
    if (auditPolicies.status === 200 && auditPolicies.data.data) {
      setAuditPolicies(auditPolicies.data.data);
    }
  };

  const addLocationAuditPolicy = () => {
    setLocationAuditPolicies([
      ...locationAuditPolicies,
      {
        id: locationAuditPolicies.length + 1,
        auditPolicyId: auditPolicies[0].id,
        percentage: 0,
      },
    ]);
  };

  const removeLocationAuditPolicy = (id: number) => {
    const newLocationAuditPolicies = locationAuditPolicies.filter((e) => e.id !== id);

    for (let i = 0; i < newLocationAuditPolicies.length; i++) {
      newLocationAuditPolicies[i].id = i + 1;
    }

    setLocationAuditPolicies(newLocationAuditPolicies);
  };

  const editLocationAuditPolicy = (id: number, editedLocationAuditPolicy: LocationAuditPolicy) => {
    setLocationAuditPolicies(
      locationAuditPolicies.map((lap) => (lap.id === id ? editedLocationAuditPolicy : lap))
    );
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <LocationMarkerIcon className="h-7 w-7 mr-1.5" />
                    Create Location Configuration
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Create a new configuration for an AEV location
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8">
                    <div>
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="requiredScan"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Required Scan{" "}
                            <span className="text-gray-500">(initial scans estimated per day)</span>
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="number"
                              name="requiredScan"
                              id="requiredScan"
                              autoComplete="requiredScan"
                              disabled={loading}
                              value={requiredScan}
                              onChange={(e) => setRequiredScan(parseInt(e.target.value, 10))}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="extensionThreshold"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Extension Threshold{" "}
                            <span className="text-gray-500">
                              (the remaining amount of scans prior to extending amount)
                            </span>
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="number"
                              name="extensionThreshold"
                              id="extensionThreshold"
                              autoComplete="extensionThreshold"
                              disabled={loading}
                              value={extensionThreshold}
                              onChange={(e) => setExtensionThreshold(parseInt(e.target.value, 10))}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="extensionAmount"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Extension Amount{" "}
                            <span className="text-gray-500">
                              (the number of scans to extend by or add to the daily count)
                            </span>
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="number"
                              name="extensionAmount"
                              id="extensionAmount"
                              autoComplete="extensionAmount"
                              disabled={loading}
                              value={extensionAmount}
                              onChange={(e) => setExtensionAmount(parseInt(e.target.value, 10))}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-4 mt-2">
                          <div className="text-base font-semibold leading-6 text-gray-900 flex flex-row items-center">
                            <DocumentTextIcon className="h-6 w-6 mr-1.5" />
                            Audit Policy Configuration
                          </div>
                          <div className="text-sm py-1 text-gray-500">
                            <span>Total Percentage: </span>
                            <span>{totalPercentage}%</span>
                          </div>
                          {totalPercentage !== 100 && (
                            <div className="my-0.5 text-sm text-red-500 font-semibold">
                              The total percentages of all location audit policies must add up to
                              100%
                            </div>
                          )}
                          {duplicateAuditPolicies && (
                            <div className="my-0.5 text-sm text-red-500 font-semibold">
                              No duplicate audit policies should be selected
                            </div>
                          )}

                          <div className="space-y-2 divide-y divide-gray-200">
                            {locationAuditPolicies.map((locationAuditPolicy) => {
                              // Return the location audit policy as editable inputs
                              return (
                                <div className="py-4">
                                  <div className="font-semibold">
                                    Location Audit Policy {locationAuditPolicy.id}
                                  </div>
                                  <div className="py-2">
                                    <label
                                      htmlFor="audit-policy"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Audit Policy
                                    </label>
                                    <select
                                      id="audit-policy"
                                      name="audit-policy"
                                      disabled={loading}
                                      onChange={(e) =>
                                        editLocationAuditPolicy(locationAuditPolicy.id, {
                                          id: locationAuditPolicy.id,
                                          percentage: locationAuditPolicy.percentage,
                                          auditPolicyId: e.target.value,
                                        })
                                      }
                                      value={locationAuditPolicy.auditPolicyId}
                                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-500 sm:text-sm sm:leading-6"
                                    >
                                      {auditPolicies.map((auditPolicy) => (
                                        <option key={auditPolicy.id} value={auditPolicy.id}>
                                          {auditPolicy.title}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="py-2">
                                    <label
                                      htmlFor="percentage"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Percentage (%)
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm">
                                      <input
                                        type="number"
                                        max={100}
                                        name="percentage"
                                        id="percentage"
                                        autoComplete="percentage"
                                        disabled={loading}
                                        value={locationAuditPolicy.percentage}
                                        onChange={(e) =>
                                          editLocationAuditPolicy(locationAuditPolicy.id, {
                                            id: locationAuditPolicy.id,
                                            percentage:
                                              parseInt(e.target.value, 10) > 100
                                                ? 100
                                                : parseInt(e.target.value, 10),
                                            auditPolicyId: locationAuditPolicy.auditPolicyId,
                                          })
                                        }
                                        className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                                      />
                                    </div>
                                  </div>
                                  <div className="mt-4">
                                    <button
                                      type="button"
                                      disabled={loading}
                                      onClick={() =>
                                        removeLocationAuditPolicy(locationAuditPolicy.id)
                                      }
                                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                                    >
                                      <MinusIcon className="h-4 w-4 mr-1" />
                                      Remove Audit Policy
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="mt-4">
                            <button
                              type="button"
                              disabled={loading}
                              onClick={() => addLocationAuditPolicy()}
                              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                            >
                              <PlusIcon className="h-4 w-4 mr-1" />
                              Add Audit Policy
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate(-1)}
                        type="button"
                        disabled={loading}
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={() => createLocationConfiguration()}
                        disabled={
                          loading ||
                          locationAuditPolicies.length === 0 ||
                          totalPercentage !== 100 ||
                          duplicateAuditPolicies
                        }
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Create
                      </button>
                      {(locationAuditPolicies.length === 0 ||
                        totalPercentage !== 100 ||
                        duplicateAuditPolicies) && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please add valid audit policies with a combined percentage of 100% to
                          create a location audit configuration
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
