import { Route, Routes } from "react-router-dom";
import PersonSecuritySubmissionV2 from "../../pages/V2/PersonSecuritySubmissionsV2/PersonSecuritySubmissionV2/PersonSecuritySubmissionV2";
import PersonSecuritySubmissionsV2 from "../../pages/V2/PersonSecuritySubmissionsV2/PersonSecuritySubmissionsV2";
import { RequireAuth } from "../../utils/auth/auth";

export default function PersonAuditsV2Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <PersonSecuritySubmissionsV2 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:personSecuritySubmissionId"
        element={
          <RequireAuth>
            <PersonSecuritySubmissionV2 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
