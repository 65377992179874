import { ArrowLeftIcon } from "@heroicons/react/outline";
import { UserAddIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { CreateUserRequest } from "../../../../utils/types";
import { useAuth } from "../../../../utils/auth/auth";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function AddUserV3() {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [userType, setUserType] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [adminRole, setAdminRole] = useState<boolean>(false);
  const [airportAdminRole, setAirportAdminRole] = useState<boolean>(false);
  const [airportOfficerRole, setAirportOfficerRole] = useState<boolean>(false);
  const [selfServeAdminRole, setSelfServeAdminRole] = useState<boolean>(false);
  const [selfServeLocationId, setSelfServeLocationId] = useState<string>("");
  const [selfServeLocations, setSelfServeLocations] = useState<any[]>([]);

  const addUser = async () => {
    setLoading(true);
    try {
      let role = 0;
      if (userType === "Admin" && adminRole) role = 1;
      if (airportAdminRole) role = 2;
      if (airportOfficerRole) role = 3;
      if (selfServeAdminRole) role = 4;

      const createUserRequest = {
        username,
        password,
        firstName,
        lastName,
        email,
        role: role,
        active: true,
      } as CreateUserRequest;

      if (airportOfficerRole || selfServeAdminRole) {
        createUserRequest.selfServeLocationId = selfServeLocationId;
      }

      await apiV3.createUser(createUserRequest);

      showSuccessToast("Successfully created user");
      navigate("/v3/user-management");
    } catch (error) {
      console.error("Error occurred while creating user - ", error);
      showErrorToast();
      setLoading(false);
    }
  };

  const fetchSelfServeLocations = async () => {
    try {
      const locations = await apiV3.getSelfServeLocations(1, 50, "", "", "DESC", "");
      setSelfServeLocations(locations.data.data);
    } catch (error) {
      console.error(`Error occurred while fetching self serve locations`, error);
    }
  };

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSelfServeLocations().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!auth.loading && auth.user) {
      if (
        (auth.user.hasOwnProperty("roleName") &&
          (auth.user.roleName === "Super Admin" || auth.user.roleName === "Admin")) ||
        (auth.user.hasOwnProperty("role") && auth.user.role === 1)
      ) {
        setUserType("Admin");
      }
    }
  }, [auth.loading]);

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <UserAddIcon className="h-7 w-7 mr-1.5" />
                    Add User
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Create a new user with a specific role
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8">
                    <div>
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="username"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Username{showAsteriskIfEmpty(username)}
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="username"
                              id="username"
                              autoComplete="username"
                              disabled={loading}
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First name{showAsteriskIfEmpty(firstName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              disabled={loading}
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last name{showAsteriskIfEmpty(lastName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              disabled={loading}
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-4">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address{showAsteriskIfEmpty(email)}
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              disabled={loading}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="new-password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Password{showAsteriskIfEmpty(password)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="password"
                              name="new-password"
                              id="new-password"
                              autoComplete="new-password"
                              disabled={loading}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="confirm-password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Confirm Password
                            {showAsteriskIfEmpty(confirmPassword)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="password"
                              name="confirm-password"
                              id="confirm-password"
                              autoComplete="confirm-password"
                              disabled={loading}
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Roles
                          {showAsteriskIfEmpty(
                            adminRole || airportAdminRole || airportOfficerRole ? "not empty" : ""
                          )}
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">Control user permissions</p>
                      </div>
                      <div className="pt-2">
                        <fieldset>
                          <div className="mt-4 space-y-4">
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="admin"
                                  name="admin"
                                  type="checkbox"
                                  disabled={
                                    loading ||
                                    airportAdminRole ||
                                    airportOfficerRole ||
                                    selfServeAdminRole
                                  }
                                  checked={adminRole}
                                  onChange={(e) => setAdminRole(e.target.checked)}
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded disabled:opacity-50"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="admin" className="font-medium text-gray-700">
                                  Admin
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="airportAdmin"
                                  name="airportAdmin"
                                  type="checkbox"
                                  disabled={
                                    loading || adminRole || airportOfficerRole || selfServeAdminRole
                                  }
                                  checked={airportAdminRole}
                                  onChange={(e) => setAirportAdminRole(e.target.checked)}
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded disabled:opacity-50"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="airportAdmin" className="font-medium text-gray-700">
                                  Airport Admin
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="airportOfficer"
                                  name="airportOfficer"
                                  type="checkbox"
                                  disabled={
                                    loading || airportAdminRole || adminRole || selfServeAdminRole
                                  }
                                  checked={airportOfficerRole}
                                  onChange={(e) => setAirportOfficerRole(e.target.checked)}
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded disabled:opacity-50"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="airportOfficer"
                                  className="font-medium text-gray-700"
                                >
                                  Airport Officer
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="selfServeAdmin"
                                  name="selfServeAdmin"
                                  type="checkbox"
                                  disabled={
                                    loading || airportAdminRole || adminRole || airportOfficerRole
                                  }
                                  checked={selfServeAdminRole}
                                  onChange={(e) => setSelfServeAdminRole(e.target.checked)}
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded disabled:opacity-50"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="selfServeAdmin"
                                  className="font-medium text-gray-700"
                                >
                                  Self Serve Admin
                                </label>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    {(airportOfficerRole || selfServeAdminRole) && (
                      <div className="">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">Location</h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Choose an optional self-serve location to restrict this user to
                          </p>
                        </div>
                        <div className="pt-2">
                          <div>
                            <label
                              htmlFor="self-serve-location"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Self Serve Location
                            </label>
                            <select
                              id="self-serve-location"
                              name="self-serve-location"
                              disabled={loading}
                              onChange={(e) => setSelfServeLocationId(e.target.value)}
                              value={selfServeLocationId}
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-500 sm:text-sm sm:leading-6"
                            >
                              <option key="all" value="">
                                All
                              </option>
                              {selfServeLocations.map((ssl) => (
                                <option key={ssl.id} value={ssl.id}>
                                  {ssl.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v3/user-management")}
                        type="button"
                        disabled={loading}
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={() => addUser()}
                        disabled={
                          loading ||
                          password !== confirmPassword ||
                          !username ||
                          !firstName ||
                          !lastName ||
                          !email ||
                          !password ||
                          !confirmPassword ||
                          (!adminRole &&
                            !airportAdminRole &&
                            !airportOfficerRole &&
                            !selfServeAdminRole)
                        }
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Create
                      </button>
                      {!username ||
                      !firstName ||
                      !lastName ||
                      !email ||
                      !password ||
                      !confirmPassword ||
                      (!adminRole &&
                        !airportAdminRole &&
                        !airportOfficerRole &&
                        !selfServeAdminRole) ? (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to create a user
                        </div>
                      ) : (
                        password !== confirmPassword && (
                          <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                            Passwords don't match
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
