import { Route, Routes } from "react-router-dom";
import NotesV3 from "../../pages/V3/NotesV3/NotesV3";
import { RequireAuth } from "../../utils/auth/auth";
import AddNoteV3 from "../../pages/V3/NotesV3/AddNoteV3/AddNoteV3";
import EditNoteV3 from "../../pages/V3/NotesV3/EditNoteV3/EditNoteV3";

export default function NotesV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <NotesV3 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddNoteV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:noteId"
        element={
          <RequireAuth>
            <EditNoteV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
