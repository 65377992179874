import { Route, Routes } from "react-router-dom";
import OperationalNeedsV3 from "../../pages/V3/OperationalNeedsV3/OperationalNeedsV3";
import { RequireAuth } from "../../utils/auth/auth";
import AddOperationalNeedV3 from "../../pages/V3/OperationalNeedsV3/AddOperationalNeedV3/AddOperationalNeedV3";
import EditOperationalNeedV3 from "../../pages/V3/OperationalNeedsV3/EditOperationalNeedV3/EditOperationalNeedV3";

export default function OperationalNeedsV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <OperationalNeedsV3 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddOperationalNeedV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:id"
        element={
          <RequireAuth>
            <EditOperationalNeedV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
