import { Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { UserAddIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV2 } from "../../../../utils/helpers/api";
import { CreateUserRequest } from "../../../../utils/types";

export default function AddUserV2() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [adminRole, setAdminRole] = useState<boolean>(false);
  const [airportAdminRole, setAirportAdminRole] = useState<boolean>(false);
  const [airportOfficerRole, setAirportOfficerRole] = useState<boolean>(false);
  const [selfServeAdminRole, setSelfServeAdminRole] = useState<boolean>(false);

  const addUser = async () => {
    setLoading(true);
    try {
      let role = 0;
      if (adminRole) role = 2;
      if (airportAdminRole) role = 4;
      if (airportOfficerRole) role = 5;
      if (selfServeAdminRole) role = 6;
      const createUserRequest = {
        username,
        password,
        firstName,
        lastName,
        email,
        role: role,
        active: true,
      } as CreateUserRequest;

      if (adminRole) {
        await apiV2.createAdminUser(createUserRequest);
      } else {
        await apiV2.createAirportUser(createUserRequest);
      }

      setSuccess(true);
      setTimeout(() => navigate("/v2/user-management"), 3000);
    } catch (error) {
      console.error("Error occurred while creating user - ", error);
      setError(true);
      setLoading(false);
    }
  };

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <UserAddIcon className="h-7 w-7 mr-1.5" />
                    Add User
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Create a new user with a specific role
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8">
                    <div>
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="username"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Username{showAsteriskIfEmpty(username)}
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="username"
                              id="username"
                              autoComplete="username"
                              disabled={loading}
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First name{showAsteriskIfEmpty(firstName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              disabled={loading}
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last name{showAsteriskIfEmpty(lastName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              disabled={loading}
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-4">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address{showAsteriskIfEmpty(email)}
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              disabled={loading}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="new-password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Password{showAsteriskIfEmpty(password)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="password"
                              name="new-password"
                              id="new-password"
                              autoComplete="new-password"
                              disabled={loading}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="confirm-password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Confirm Password
                            {showAsteriskIfEmpty(confirmPassword)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="password"
                              name="confirm-password"
                              id="confirm-password"
                              autoComplete="confirm-password"
                              disabled={loading}
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Roles
                          {showAsteriskIfEmpty(
                            adminRole ||
                              airportAdminRole ||
                              airportOfficerRole ||
                              selfServeAdminRole
                              ? "not empty"
                              : ""
                          )}
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Control user permissions
                        </p>
                      </div>
                      <div className="pt-2">
                        <fieldset>
                          <div className="mt-4 space-y-4">
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="admin"
                                  name="admin"
                                  type="checkbox"
                                  disabled={
                                    loading ||
                                    airportAdminRole ||
                                    airportOfficerRole ||
                                    selfServeAdminRole
                                  }
                                  checked={adminRole}
                                  onChange={(e) =>
                                    setAdminRole(e.target.checked)
                                  }
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded disabled:opacity-50"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="admin"
                                  className="font-medium text-gray-700"
                                >
                                  Admin
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="airportAdmin"
                                  name="airportAdmin"
                                  type="checkbox"
                                  disabled={
                                    loading ||
                                    adminRole ||
                                    airportOfficerRole ||
                                    selfServeAdminRole
                                  }
                                  checked={airportAdminRole}
                                  onChange={(e) =>
                                    setAirportAdminRole(e.target.checked)
                                  }
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded disabled:opacity-50"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="airportAdmin"
                                  className="font-medium text-gray-700"
                                >
                                  Airport Admin
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="airportOfficer"
                                  name="airportOfficer"
                                  type="checkbox"
                                  disabled={
                                    loading ||
                                    airportAdminRole ||
                                    adminRole ||
                                    selfServeAdminRole
                                  }
                                  checked={airportOfficerRole}
                                  onChange={(e) =>
                                    setAirportOfficerRole(e.target.checked)
                                  }
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded disabled:opacity-50"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="airportOfficer"
                                  className="font-medium text-gray-700"
                                >
                                  Airport Officer
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="selfServeAdmin"
                                  name="selfServeAdmin"
                                  type="checkbox"
                                  disabled={
                                    loading ||
                                    airportAdminRole ||
                                    adminRole ||
                                    airportOfficerRole
                                  }
                                  checked={selfServeAdminRole}
                                  onChange={(e) =>
                                    setSelfServeAdminRole(e.target.checked)
                                  }
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded disabled:opacity-50"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="selfServeAdmin"
                                  className="font-medium text-gray-700"
                                >
                                  Self Serve Admin
                                </label>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v2/user-management")}
                        type="button"
                        disabled={loading}
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={() => addUser()}
                        disabled={
                          loading ||
                          password !== confirmPassword ||
                          !username ||
                          !firstName ||
                          !lastName ||
                          !email ||
                          !password ||
                          !confirmPassword ||
                          (!adminRole &&
                            !airportAdminRole &&
                            !airportOfficerRole &&
                            !selfServeAdminRole)
                        }
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Create
                      </button>
                      {!username ||
                      !firstName ||
                      !lastName ||
                      !email ||
                      !password ||
                      !confirmPassword ||
                      (!adminRole &&
                        !airportAdminRole &&
                        !airportOfficerRole &&
                        !selfServeAdminRole) ? (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to create a user
                        </div>
                      ) : (
                        password !== confirmPassword && (
                          <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                            Passwords don't match
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={success}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully created user
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Returning to user management portal...
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
