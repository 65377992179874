import { Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { UserCircleIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV2 } from "../../../../utils/helpers/api";
import { classNames } from "../../../../utils/helpers/misc";

export default function SelfServeAuditV2() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [selfServeAudit, setSelfServeAudit] = useState<any>(null);
  const [faceImageUrl, setFaceImageUrl] = useState<string>("");
  const [idImageUrl, setIdImageUrl] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    // Get selfServeAudit from database
    const selfServeAuditId = params.selfServeAuditId;
    if (selfServeAuditId) {
      fetchSelfServeAudit(selfServeAuditId);
    } else {
      setError(true);
    }
  }, []);

  const fetchSelfServeAudit = async (selfServeAuditId: string) => {
    try {
      setLoading(true);
      const res = await apiV2.getSelfServeAuditById(selfServeAuditId);
      const selfServeAudit = res.data;
      setSelfServeAudit(selfServeAudit);

      // Get images
      if (selfServeAudit.faceImage) {
        const faceImageBlob = await apiV2.getFile(0, selfServeAudit.faceImage);
        setFaceImageUrl(URL.createObjectURL(faceImageBlob.data));
      }

      if (selfServeAudit.idImage) {
        const idImageBlob = await apiV2.getFile(0, selfServeAudit.idImage);
        setIdImageUrl(URL.createObjectURL(idImageBlob.data));
      }

      setLoading(false);
    } catch (error) {
      console.error(
        `Error occurred while fetching selfServeAudit with id ${selfServeAuditId}`,
        error
      );
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            {selfServeAudit && !loading ? (
              <>
                <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                  <div className="flex items-center space-x-5">
                    <div>
                      <h1 className="text-2xl font-bold text-gray-900 items-center flex flex-row">
                        <UserCircleIcon className="h-8 w-8 mr-1.5" />
                        {selfServeAudit.firstName +
                          " " +
                          selfServeAudit.lastName}
                      </h1>
                    </div>
                  </div>
                  <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                    <button
                      onClick={() => navigate(-1)}
                      type="button"
                      className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                    >
                      <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                      Back
                    </button>
                  </div>
                </div>

                <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                  <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                    {/* Description list*/}
                    <section aria-labelledby="applicant-information-title">
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h2
                            id="applicant-information-title"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Self Serve Audit
                          </h2>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Submitted on{" "}
                            {new Date(
                              selfServeAudit.submittedOn
                            ).toLocaleString("en-AU")}
                          </p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6 flex flex-row">
                          <div className="flex flex-col mr-4">
                            <div>
                              <img
                                className="rounded-md w-64 mb-2"
                                src={
                                  faceImageUrl
                                    ? faceImageUrl
                                    : "https://i1.wp.com/www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg?ssl=1"
                                }
                              />
                            </div>
                            <div>
                              <img
                                className="rounded-md w-64"
                                src={
                                  idImageUrl
                                    ? idImageUrl
                                    : "https://i1.wp.com/www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg?ssl=1"
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  ASIC ID
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {selfServeAudit.asicId}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Company
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {selfServeAudit.company}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Expiry Date
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {new Date(
                                    selfServeAudit.expiredDate
                                  ).getUTCMonth() +
                                    1 +
                                    "/" +
                                    new Date(
                                      selfServeAudit.expiredDate
                                    ).getUTCFullYear()}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Airport Code
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {selfServeAudit.airportCode}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Access Level
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {selfServeAudit.accessLevel}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Access Location
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {selfServeAudit.accessLocation}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Face Match
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  <div
                                    className={classNames(
                                      "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                                      selfServeAudit.faceMatchScore > 50
                                        ? "bg-green-100 text-green-800"
                                        : "bg-red-100 text-red-800"
                                    )}
                                  >
                                    {(+selfServeAudit.faceMatchScore.toFixed(
                                      2
                                    )).toString() + "%"}
                                  </div>
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Operational Requirement
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {selfServeAudit.operationalRequirement}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-sm mt-2">Loading...</div>
            )}
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={success}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully updated visitor
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
      <div className="min-h-full">
        <main className="py-10">{/* Page header */}</main>
      </div>
    </>
  );
}
