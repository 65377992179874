import { Route, Routes } from "react-router-dom";
import AddUserV2 from "../../pages/V2/UserManagementV2/AddUserV2/AddUserV2";
import EditUserV2 from "../../pages/V2/UserManagementV2/EditUserV2/EditUserV2";
import UserManagementV2 from "../../pages/V2/UserManagementV2/UserManagementV2";
import { RequireAuth } from "../../utils/auth/auth";

export default function UserManagementV2Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <UserManagementV2 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddUserV2 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:userId"
        element={
          <RequireAuth>
            <EditUserV2 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
