import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import G99Logo from "../../../assets/img/g99-logo.png";
import { useAuth } from "../../../utils/auth/auth";
import { apiV2, apiV3 } from "../../../utils/helpers/api";

export default function SignIn() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [signInErrorMessage, setSignInErrorMessage] = useState<string>("");

  const signIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (username && password) {
      auth
        .signin(username, password)
        .then(() => {
          if (auth.v2Token) {
            navigate("/v2/self-serve-audits", { replace: true });
          } else if (auth.v3Token) {
            navigate("/v3/self-serve-audits", { replace: true });
          }
        })
        .catch((error) => {
          console.error("Error occurred while signing in -", error);
          setSignInErrorMessage("Invalid username or password");
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (!auth.loading && auth.user) {
      if (auth.v2Token) {
        apiV2.updateClient(auth.v2Token, auth.v2TokenExpiry);
        navigate("/v2/self-serve-audits", { replace: true });
      }

      if (auth.v3Token) {
        apiV3.updateClient(auth.v3Token, auth.v3TokenExpiry);
        navigate("/v3/self-serve-audits", { replace: true });
      }
    }
    setLoading(false);
  }, [auth.loading]);

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-40 w-auto" src={G99Logo} alt="Workflow" />
          <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
            AEV Admin Portal
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={(e) => signIn(e)}>
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700"
                >
                  Username
                </label>
                <div className="mt-1">
                  <input
                    id="username"
                    name="username"
                    type="username"
                    autoComplete="username"
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loading || !username || !password}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-500 opacity-90 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200"
                >
                  Sign in
                </button>
                <div className="block text-sm font-medium text-red-500 mt-4">
                  {signInErrorMessage}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
