import { Route, Routes } from "react-router-dom";
import AddSelfServeLocationV2 from "../../pages/V2/SelfServeLocationsV2/AddSelfServeLocationV2/AddSelfServeLocationV2";
import EditSelfServeLocationV2 from "../../pages/V2/SelfServeLocationsV2/EditSelfServeLocationV2/EditSelfServeLocationV2";
import SelfServeLocationsV2 from "../../pages/V2/SelfServeLocationsV2/SelfServeLocationsV2";
import { RequireAuth } from "../../utils/auth/auth";

export default function SelfServeLocationsV2Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <SelfServeLocationsV2 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddSelfServeLocationV2 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:locationId"
        element={
          <RequireAuth>
            <EditSelfServeLocationV2 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
