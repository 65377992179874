import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { AuthProvider } from "../utils/auth/auth";
import V2Router from "./V2/V2Router";
import SignOut from "../pages/Auth/SignOut/SignOut";
import SignIn from "../pages/Auth/SignIn/SignIn";
import V3Router from "./V3/V3Router";

export default function MainRouter() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/signin" />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signout" element={<SignOut />} />
          <Route path="/v2/*" element={<V2Router />} />
          <Route path="/v3/*" element={<V3Router />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}
