import { ArrowLeftIcon } from "@heroicons/react/outline";
import { SwitchHorizontalIcon } from "@heroicons/react/solid";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function ImportASICV3() {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [importFile, setImportFile] = useState<File>();

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImportFile(e.target.files[0]);
    }
  };

  const importASICs = async () => {
    try {
      setIsBusy(true);
      if (importFile) {
        await apiV3.importAsicFile(importFile);
        showSuccessToast("Successfully started ASIC import");
        navigate("/v3/asic-import");
      } else {
        setIsBusy(false);
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while importing ASIC - ", error);
      setIsBusy(false);
      showErrorToast();
    }
  };

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <SwitchHorizontalIcon className="h-7 w-7 mr-1.5" />
                    Import ASICs
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">Import ASICs by uploading a file</p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8">
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="asic-import-file"
                        className="block text-sm font-medium text-gray-700"
                      >
                        ASIC Import File
                        {showAsteriskIfEmpty(importFile ? "not empty" : "")}
                      </label>
                      <div className="mt-1 flex justify-center items-center text-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                        <div className="space-y-1 text-center">
                          <svg
                            aria-hidden="true"
                            className="mx-auto h-12 w-12 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <div className="flex text-sm text-gray-600 text-center items-center mx-auto justify-center">
                            <label
                              htmlFor="file"
                              className="disabled:opacity-60 relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                              <span>Upload a CSV file</span>
                              <input
                                id="file"
                                name="file"
                                type="file"
                                disabled={loading || isBusy}
                                onChange={handleFileUpload}
                                className="sr-only"
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">up to 10MB</p>
                          {importFile && (
                            <p className="bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
                              {`${importFile.name} - ${(importFile.size / (1024 * 1024)).toFixed(
                                2
                              )}MB`}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v3/asic-import")}
                        type="button"
                        disabled={loading || isBusy}
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={() => importASICs()}
                        disabled={!importFile || loading || isBusy}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Import
                      </button>
                      {!importFile && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please upload a file to import an ASIC
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
