import { RefreshIcon } from "@heroicons/react/outline";
import { CreditCardIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageNumbers from "../../../components/PageNumbers/PageNumbers";
import SearchFormV2 from "../../../components/SearchFormV2/SearchFormV2";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { useAuth } from "../../../utils/auth/auth";
import { apiV2 } from "../../../utils/helpers/api";
import { AsicSearchParams, SearchFilter } from "../../../utils/types";

export default function ASICManagement() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [asics, setAsics] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [newPageNumber, setNewPageNumber] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<0 | 1>(0);
  const [sortField, setSortField] = useState<string>("createdAt");
  const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);

  useEffect(() => {
    if (!auth.loading && auth.user) {
      apiV2.updateClient(auth.v2Token, auth.v2TokenExpiry);
      fetchAsics();
    }
  }, [auth.user]);

  useEffect(() => {
    fetchAsics();
  }, [pageNumber, searchFilters, sortField, sortOrder, rowsPerPage]);

  const fetchAsics = async () => {
    setLoading(true);
    try {
      const searchParams = {} as AsicSearchParams;
      for (const filter of searchFilters) {
        searchParams[filter.searchField as keyof AsicSearchParams] =
          filter.searchQuery;
      }

      const asics = await apiV2.getAsics(
        pageNumber - 1,
        rowsPerPage,
        sortOrder,
        searchParams
      );

      setAsics(asics.data.items);
      setTotalCount(asics.data.total);
    } catch (error) {
      console.error(
        `Error occurred while fetching ${rowsPerPage} asics of page ${pageNumber} - `,
        error
      );
    }
    setLoading(false);
  };

  const updateSortCriteria = (newSortField: string) => {
    setSortField(newSortField);
    if (newSortField === sortField) {
      if (sortOrder === 0) setSortOrder(1);
      else setSortField("");
    } else {
      setSortOrder(0);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <CreditCardIcon className="h-6 w-6 mr-1.5" />
                    ASIC Management
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the ASICs in AEV including their details.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none items-center flex flex-row">
                  {/* <button
                    onClick={() => navigate("/v2/asic-management/add")}
                    type="button"
                    disabled={loading}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-amber-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-amber-500"
                  >
                    <PlusIcon className="h-5 w-5 mr-1.5" />
                    Add ASIC
                  </button> */}
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => fetchAsics()}
                    className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-stone-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-stone-600"
                  >
                    <RefreshIcon className="h-5 w-5 mr-1.5" />
                    Refresh
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col">
                <SearchFormV2
                  searchOptions={[
                    { name: "ASIC ID", value: "asicId" },
                    { name: "First Name", value: "firstName" },
                    { name: "Last Name", value: "lastName" },
                    { name: "Company Name", value: "companyName" },
                  ]}
                  updateSearchFilters={(newSearchFilters) => {
                    setSearchFilters(newSearchFilters);
                  }}
                />
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                ASIC ID
                                {/* <SortButton
                                  active={sortField === "createdAt"}
                                  sortOrder={sortOrder}
                                  sortField={"createdAt"}
                                  updateSortCriteria={updateSortCriteria}
                                /> */}
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                First Name
                                {/* <SortButton
                                  active={sortField === "firstName"}
                                  sortOrder={sortOrder}
                                  sortField={"firstName"}
                                  updateSortCriteria={updateSortCriteria}
                                /> */}
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Last Name
                                {/* <SortButton
                                  active={sortField === "lastName"}
                                  sortOrder={sortOrder}
                                  sortField={"lastName"}
                                  updateSortCriteria={updateSortCriteria}
                                /> */}
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Company
                                {/* <SortButton
                                  active={sortField === "role"}
                                  sortOrder={sortOrder}
                                  sortField={"role"}
                                  updateSortCriteria={updateSortCriteria}
                                /> */}
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Expiry Date
                                {/* <SortButton
                                  active={sortField === "role"}
                                  sortOrder={sortOrder}
                                  sortField={"role"}
                                  updateSortCriteria={updateSortCriteria}
                                /> */}
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Airport Code
                                {/* <SortButton
                                  active={sortField === "role"}
                                  sortOrder={sortOrder}
                                  sortField={"role"}
                                  updateSortCriteria={updateSortCriteria}
                                /> */}
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Status
                                {/* <SortButton
                                  active={sortField === "role"}
                                  sortOrder={sortOrder}
                                  sortField={"role"}
                                  updateSortCriteria={updateSortCriteria}
                                /> */}
                              </div>
                            </th>

                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {asics.map((asic) => (
                            <tr
                              key={asic.asicId}
                              className="hover:bg-slate-50 cursor-pointer"
                              onClick={() =>
                                navigate(
                                  "/v2/asic-management/edit/" + asic.asicId
                                )
                              }
                            >
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {asic.asicId}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {asic.firstName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {asic.lastName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {asic.company}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {new Date(asic.expires).getMonth() +
                                  1 +
                                  "/" +
                                  new Date(asic.expires).getFullYear()}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {asic.airportCode}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {asic.status === "ACTIVE" && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Active
                                  </div>
                                )}
                                {asic.status === "WITHDRAWN" && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                    Withdrawn
                                  </div>
                                )}
                                {asic.status === "DELETED" && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Deleted
                                  </div>
                                )}
                                {asic.status === "SUSPENDED" && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800">
                                    Suspended
                                  </div>
                                )}
                                {asic.status === "LOST" && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                    Lost
                                  </div>
                                )}
                              </td>

                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <Link to={"/asic-management/edit/" + asic.id}>
                                  <div className="text-amber-600 hover:text-amber-900">
                                    Edit
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="py-3 flex items-center justify-between border-t border-gray-200">
                      <div className="flex-1 flex justify-between sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50"
                        >
                          Previous
                        </a>
                        <a
                          href="#"
                          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50"
                        >
                          Next
                        </a>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="flex flex-row items-center">
                          {loading ? (
                            <p className="text-sm text-gray-700">Loading...</p>
                          ) : totalCount > 0 ? (
                            <>
                              <p className="text-sm text-gray-700">
                                Showing{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber - rowsPerPage + 1}
                                </span>{" "}
                                to{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber > totalCount
                                    ? totalCount
                                    : rowsPerPage * pageNumber}
                                </span>{" "}
                                of{" "}
                                <span className="font-medium">
                                  {totalCount}
                                </span>{" "}
                                results
                              </p>
                            </>
                          ) : (
                            <p className="text-sm text-gray-700">
                              Showing 0 results.
                            </p>
                          )}
                          <div className="text-sm text-gray-700 inline-flex flex-row items-center ml-1.5">
                            <div>Results per page: </div>
                            <div>
                              <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                className="h-8 ml-1.5 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-600 text-sm sm:leading-6"
                                value={rowsPerPage}
                                onChange={(e) =>
                                  setRowsPerPage(parseInt(e.target.value))
                                }
                                disabled={loading}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <PageNumbers
                          rowsPerPage={rowsPerPage}
                          totalCount={totalCount}
                          pageNumber={pageNumber}
                          setPageNumber={setPageNumber}
                          newPageNumber={newPageNumber}
                          setNewPageNumber={setNewPageNumber}
                          disabled={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
