import { ArrowLeftIcon, LockClosedIcon } from "@heroicons/react/outline";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function EditAuditPolicyV3() {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [auditPolicy, setAuditPolicy] = useState<any>(null);
  const [auditPolicyDeletable, setAuditPolicyDeletable] = useState<boolean>(false);
  const [openDeleteAuditPolicyModal, setOpenDeleteAuditPolicyModal] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [type, setType] = useState<number>(0);
  const [securityTierId, setSecurityTierId] = useState<string>("");
  const [securityCheckIds, setSecurityCheckIds] = useState<string[]>([]);
  const [securityTiers, setSecurityTiers] = useState<any[]>([]);
  const [securityChecks, setSecurityChecks] = useState<any[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchInputData();
  }, []);

  const fetchInputData = async () => {
    setLoading(true);
    try {
      const auditPolicyId = params.auditPolicyId;
      if (auditPolicyId) {
        await fetchAuditPolicy(auditPolicyId);
        await checkIfAuditPolicyCanBeDeleted(auditPolicyId);
        await fetchSecurityChecks();
        await fetchSecurityTiers();
      } else {
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while fetching input data - ", error);
      showErrorToast();
    } finally {
      setLoading(false);
    }
  };

  const fetchAuditPolicy = async (auditPolicyId: string) => {
    apiV3
      .getAuditPolicyById(auditPolicyId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          const auditPolicy = res.data;
          setAuditPolicy(auditPolicy);
          setTitle(auditPolicy.title);
          setType(auditPolicy.type);
          setSecurityTierId(auditPolicy.securityTier.id);
          setSecurityCheckIds(
            auditPolicy.auditPolicySecurityChecks.map((sc: any) => sc.securityCheck.id)
          );
        }
      })
      .catch((error) => {
        console.error(`Error occurred while fetching auditPolicy with id ${auditPolicyId}`, error);
      });
  };

  const checkIfAuditPolicyCanBeDeleted = async (auditPolicyId: string) => {
    try {
      const res = await apiV3.checkIfAuditPolicyCanBeDeleted(auditPolicyId);
      setAuditPolicyDeletable(res.data);
    } catch (error) {
      console.error(
        `Error occurred while check if auditPolicy deletable with id ${auditPolicyId}`,
        error
      );
    }
  };

  const fetchSecurityTiers = async () => {
    const securityTiers = await apiV3.getSecurityTiers(1, 1000, "", "", "DESC", "");

    if (securityTiers.status === 200 && securityTiers.data.data) {
      setSecurityTiers(securityTiers.data.data);
    }
  };

  const fetchSecurityChecks = async () => {
    const securityChecks = await apiV3.getSecurityChecks(1, 1000, "", "", "DESC", "");
    if (securityChecks.status === 200 && securityChecks.data.data) {
      setSecurityChecks(securityChecks.data.data);
    }
  };

  const updateAuditPolicy = async () => {
    try {
      setIsBusy(true);
      if (params.auditPolicyId) {
        await apiV3.updateAuditPolicyById(params.auditPolicyId, {
          title,
          type,
          securityTierId,
          securityCheckIds,
          randomizedChecksGroupIds: [],
        });
        showSuccessToast("Successfully update audit policy");
      } else {
        console.error(
          "Error occurred while updating auditPolicy - couldn't find the auditPolicy's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while updating auditPolicy - ", error);
      showErrorToast();
    } finally {
      setIsBusy(false);
    }
  };

  const deleteAuditPolicy = async () => {
    try {
      setIsBusy(true);
      setOpenDeleteAuditPolicyModal(false);
      if (params.auditPolicyId) {
        await apiV3.deleteAuditPolicyById(params.auditPolicyId);
        showSuccessToast("Successfully deleted audit policy");
        setIsBusy(false);
        navigate("/v3/audit-policies");
      } else {
        console.error(
          "Error occurred while deleting auditPolicy - couldn't find the auditPolicy's id from the URL parameters"
        );
        showErrorToast();
        setIsBusy(false);
      }
    } catch (error) {
      console.error("Error occurred while deleting auditPolicy - ", error);
      showErrorToast();
      setIsBusy(false);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    <span>
                      Edit Audit Policy
                      {title && (
                        <span>
                          <em className="ml-1">({title})</em>
                        </span>
                      )}
                    </span>
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Edit the details of an audit policy or delete it from the system
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="title"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Title
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              disabled={loading}
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <div>
                            <label
                              htmlFor="security-tier"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Security Tier
                            </label>
                            <select
                              id="security-tier"
                              name="security-tier"
                              disabled={loading}
                              onChange={(e) => setSecurityTierId(e.target.value)}
                              value={securityTierId}
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-500 sm:text-sm sm:leading-6"
                            >
                              {securityTiers.map((tier) => (
                                <option key={tier.id} value={tier.id}>
                                  {tier.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <div>
                            <label
                              htmlFor="type"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Type
                            </label>
                            <select
                              id="type"
                              name="type"
                              disabled={loading}
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-500 sm:text-sm sm:leading-6"
                              onChange={(e) => setType(parseInt(e.target.value, 10))}
                              value={type}
                            >
                              <option key={0} value={0}>
                                Person
                              </option>
                              <option key={1} value={1}>
                                Vehicle
                              </option>
                            </select>
                          </div>
                        </div>
                        <fieldset className="sm:col-span-4 mt-2">
                          <legend className="text-base font-semibold leading-6 text-gray-900 flex flex-row items-center">
                            <LockClosedIcon className="h-6 w-6 mr-1.5" />
                            Applicable Security Checks
                          </legend>
                          <div className="mt-4 max-h-64 overflow-y-auto divide-y divide-gray-200 border-b border-t border-gray-200">
                            {securityChecks.map((securityCheck) => (
                              <div
                                key={securityCheck.id}
                                className="relative flex items-start py-4"
                              >
                                <div className="min-w-0 flex-1 text-sm leading-6">
                                  <label
                                    htmlFor={`securityCheck-${securityCheck.id}`}
                                    className="select-none font-base text-gray-900"
                                  >
                                    {securityCheck.name}
                                  </label>
                                </div>
                                <div className="ml-3 mr-6 flex h-6 items-center">
                                  <input
                                    id={`securityCheck-${securityCheck.id}`}
                                    name={`securityCheck-${securityCheck.id}`}
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-amber-500 focus:ring-amber-500"
                                    checked={securityCheckIds.includes(securityCheck.id)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setSecurityCheckIds([
                                          ...securityCheckIds,
                                          securityCheck.id,
                                        ]);
                                      } else {
                                        setSecurityCheckIds(
                                          securityCheckIds.filter((id) => id !== securityCheck.id)
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="pt-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Delete Audit Policy
                        </h3>
                        {auditPolicyDeletable ? (
                          <p className="mt-1 text-sm text-gray-500">
                            Permanently delete an audit policy from the AEV database
                          </p>
                        ) : (
                          <p className="mt-1 text-sm text-gray-300">
                            This audit policy cannot be deleted since there are location audit
                            configurations that use it.
                          </p>
                        )}
                      </div>
                      <div className="mt-6">
                        <button
                          type="button"
                          disabled={loading || !auditPolicyDeletable || isBusy}
                          onClick={() => setOpenDeleteAuditPolicyModal(true)}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800 disabled:opacity-50"
                        >
                          <TrashIcon className="h-4 w-4 mr-1" />
                          Delete Audit Policy
                        </button>
                      </div>
                      <DeleteModal
                        description="Deleting an audit policy is permanent. No location audit configurations will be able to be created using this audit policy and it will be erased from AEV databases."
                        open={openDeleteAuditPolicyModal}
                        setOpen={setOpenDeleteAuditPolicyModal}
                        title={"Are you sure you want to delete this audit policy?"}
                        action={() => deleteAuditPolicy()}
                        actionButtonText={"Delete"}
                      />
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v3/audit-policies")}
                        type="button"
                        disabled={isBusy}
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => updateAuditPolicy()}
                        disabled={
                          loading ||
                          !title ||
                          !securityTierId ||
                          securityCheckIds.length === 0 ||
                          isBusy
                        }
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Save
                      </button>
                      {(!title || !securityTierId || securityCheckIds.length === 0) && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to update the audit policy
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
