import { Route, Routes } from "react-router-dom";
import PersonSecuritySubmissionV3 from "../../pages/V3/PersonSecuritySubmissionsV3/PersonSecuritySubmissionV3/PersonSecuritySubmissionV3";
import PersonSecuritySubmissionsV3 from "../../pages/V3/PersonSecuritySubmissionsV3/PersonSecuritySubmissionsV3";
import { RequireAuth } from "../../utils/auth/auth";
import PersonSecuritySubmissionCheckinsV3 from "../../pages/V3/PersonSecuritySubmissionCheckinsV3/PersonSecuritySubmissionCheckinsV3";
import PersonSecuritySubmissionCheckinV3 from "../../pages/V3/PersonSecuritySubmissionCheckinsV3/PersonSecuritySubmissionCheckinV3/PersonSecuritySubmissionCheckinV3";

export default function PersonAuditsV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <PersonSecuritySubmissionsV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:personSecuritySubmissionId"
        element={
          <RequireAuth>
            <PersonSecuritySubmissionV3 />
          </RequireAuth>
        }
      />
      <Route
        path="checkins"
        element={
          <RequireAuth>
            <PersonSecuritySubmissionCheckinsV3 />
          </RequireAuth>
        }
      />
      <Route
        path="checkins/edit/:personSecuritySubmissionCheckinId"
        element={
          <RequireAuth>
            <PersonSecuritySubmissionCheckinV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
