import { Route, Routes } from "react-router-dom";
import AddAirportV2 from "../../pages/V2/AirportManagementV2/AddAirportV2/AddAirportV2";
import AirportManagementV2 from "../../pages/V2/AirportManagementV2/AirportManagementV2";
import EditAirportV2 from "../../pages/V2/AirportManagementV2/EditAirportV2/EditAirportV2";
import { RequireAuth } from "../../utils/auth/auth";

export default function AirportManagementV2Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <AirportManagementV2 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddAirportV2 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:airportId"
        element={
          <RequireAuth>
            <EditAirportV2 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
