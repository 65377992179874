import { Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  BanIcon,
  CheckCircleIcon,
  CheckIcon,
  LocationMarkerIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/outline";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV2 } from "../../../../utils/helpers/api";

export default function EditAEVLocationV2() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [disableSuccess, setDisableSuccess] = useState<boolean>(false);
  const [enableSuccess, setEnableSuccess] = useState<boolean>(false);
  const [addLaneSuccess, setAddLaneSuccess] = useState<boolean>(false);
  const [location, setLocation] = useState<any>(null);
  const [name, setName] = useState<string>("");
  const [openDisableLocationModal, setOpenDisableLocationModal] =
    useState<boolean>(false);
  const [openDeleteLocationModal, setOpenDeleteLocationModal] =
    useState<boolean>(false);
  const [openEnableLocationModal, setOpenEnableLocationModal] =
    useState<boolean>(false);
  const [openDeleteLaneModal, setOpenDeleteLaneModal] =
    useState<boolean>(false);
  const [locationDeletable, setLocationDeletable] = useState<boolean>(false);
  const [laneIdToDelete, setLaneIdToDelete] = useState<string>("");
  const [newLaneName, setNewLaneName] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    // Get location from database
    const locationId = params.locationId;
    if (locationId) {
      fetchLocation(locationId);
    } else {
      setError(true);
    }
  }, []);

  const fetchLocation = async (locationId: string) => {
    apiV2
      .getAEVLocationById(locationId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          const location = res.data;
          setLocation(location);
          setName(location.name);
          setLocationDeletable(location.locationLanes.length === 0);
        }
      })
      .catch((error) => {
        console.error(
          `Error occurred while fetching location with id ${locationId}`,
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const updateLocation = async () => {
    setLoading(true);
    try {
      if (params.locationId) {
        await apiV2.updateAEVLocation(params.locationId, {
          name,
          airportId: parseInt(location.airportId),
          active: location.active,
          fixedLocation: location.fixedLocation,
          fixedLocationReportingLatitude:
            location.fixedLocationReportingLatitude,
          fixedLocationReportingLongitude:
            location.fixedLocationReportingLongitude,
        });
        setSuccess(true);
      } else {
        console.error(
          "Error occurred while updating location - couldn't find the location's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while updating location - ", error);
      setError(true);
    }
    setLoading(false);
  };

  const enableLocation = async () => {
    setLoading(true);
    setOpenEnableLocationModal(false);
    try {
      if (params.locationId) {
        await apiV2.updateAEVLocation(params.locationId, {
          airportId: parseInt(location.airportId),
          name: location.name,
          active: true,
          fixedLocation: location.fixedLocation,
          fixedLocationReportingLatitude:
            location.fixedLocationReportingLatitude,
          fixedLocationReportingLongitude:
            location.fixedLocationReportingLongitude,
        });
        setEnableSuccess(true);
        setTimeout(() => setEnableSuccess(false), 3000);

        fetchLocation(params.locationId);
      } else {
        console.error(
          "Error occurred while enabling location - couldn't find the location's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while enabling location - ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const disableLocation = async () => {
    setLoading(true);
    setOpenDisableLocationModal(false);
    try {
      if (params.locationId) {
        await apiV2.updateAEVLocation(params.locationId, {
          airportId: parseInt(location.airportId),
          name: location.name,
          active: false,
          fixedLocation: location.fixedLocation,
          fixedLocationReportingLatitude:
            location.fixedLocationReportingLatitude,
          fixedLocationReportingLongitude:
            location.fixedLocationReportingLongitude,
        });
        setDisableSuccess(true);
        setTimeout(() => setDisableSuccess(false), 3000);
        fetchLocation(params.locationId);
      } else {
        console.error(
          "Error occurred while disabling location - couldn't find the location's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while disabling location - ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteLocation = async () => {
    setLoading(true);
    setOpenDeleteLocationModal(false);
    try {
      if (params.locationId) {
        await apiV2.deleteAEVLocation(params.locationId);
        setDeleteSuccess(true);
        setTimeout(() => navigate("/v2/aev-locations"), 3000);
      } else {
        console.error(
          "Error occurred while deleting location - couldn't find the location's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while deleting location - ", error);
      setError(true);
      setLoading(false);
    }
  };

  const addLane = async () => {
    setLoading(true);
    try {
      if (params.locationId) {
        await apiV2.addAEVLocationLane(params.locationId, newLaneName);
        setAddLaneSuccess(true);
        setNewLaneName("");
        setTimeout(() => setAddLaneSuccess(false), 2000);
        await fetchLocation(params.locationId);
      } else {
        console.error(
          "Error occurred while adding lane - couldn't find the location's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while adding lane - ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteLane = async (laneId: string) => {
    setLoading(true);
    setOpenDeleteLaneModal(false);
    try {
      if (params.locationId) {
        await apiV2.deleteAEVLocationLane(params.locationId!, laneId);
        setDeleteSuccess(true);
        setLaneIdToDelete("");
        setTimeout(() => setDeleteSuccess(false), 2000);
        await fetchLocation(params.locationId);
      } else {
        console.error(
          "Error occurred while deleting lane - couldn't find the location's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while deleting lane - ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    Edit AEV Location{" "}
                    {name && <em className="ml-1">({name})</em>}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Edit the name, status and lanes of an AEV location or delete
                    it from the system
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              {!loading && location ? (
                <div className="mt-8 flex flex-col max-w-screen-md">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200">
                      <div className="text-sm">
                        <div>
                          <span className="font-semibold">Status: </span>
                          {location && location.hasOwnProperty("active") && (
                            <span>
                              {location.active ? (
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                  Active
                                </div>
                              ) : (
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                  Inactive
                                </div>
                              )}
                            </span>
                          )}
                        </div>
                        <div>
                          <span className="font-semibold">Created At: </span>
                          <span className="">
                            {new Date(location.created).toDateString()}
                          </span>
                        </div>
                      </div>
                      <div className="pt-4">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-4">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Name
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                autoComplete="name"
                                disabled={
                                  loading || disableSuccess || deleteSuccess
                                }
                                value={name ?? ""}
                                onChange={(e) => setName(e.target.value)}
                                className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="flex justify-start items-center">
                            <button
                              onClick={() => navigate("/v2/aev-locations")}
                              type="button"
                              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => updateLocation()}
                              disabled={loading || !name}
                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                            >
                              Save
                            </button>
                            {!name && (
                              <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                                Please enter all the required fields to update
                                location
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Related Information
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            View information associated with this location
                          </p>
                        </div>
                        <div className="mt-6">
                          <h4 className="text-md leading-6 font-medium text-gray-900">
                            Lanes
                          </h4>
                          {location &&
                          location.hasOwnProperty("locationLanes") &&
                          location.locationLanes.length === 0 ? (
                            <div className="mt-1 text-sm text-gray-500">
                              No lanes for this location.
                            </div>
                          ) : (
                            <div className="my-3 grid grid-cols-1 gap-4 sm:grid-cols-4">
                              <table className="min-w-full divide-y divide-gray-300">
                                <tbody className="divide-y divide-gray-200">
                                  {location.locationLanes.map((lane: any) => (
                                    <tr key={lane.id}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                        {lane.laneName}
                                      </td>
                                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                        <a
                                          onClick={() => {
                                            setOpenDeleteLaneModal(true);
                                            setLaneIdToDelete(lane.id);
                                          }}
                                          className="text-red-600 hover:text-red-900 cursor-pointer"
                                        >
                                          Delete
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                          <div className="mt-6">
                            <div>
                              <label
                                htmlFor="lane-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Add Lane
                              </label>
                              <div className="mt-2 flex rounded-md shadow-sm">
                                <div className="relative flex focus-within:z-10">
                                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <LocationMarkerIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <input
                                    type="text"
                                    name="lane-name"
                                    id="lane-name"
                                    value={newLaneName}
                                    onChange={(e) =>
                                      setNewLaneName(e.target.value)
                                    }
                                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                    placeholder="Lane Name"
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-green-600 bg-green-600 hover:bg-green-700"
                                  onClick={() => addLane()}
                                >
                                  <PlusIcon
                                    className="-ml-0.5 h-5 w-5 text-white"
                                    aria-hidden="true"
                                  />
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {location &&
                      location.hasOwnProperty("active") &&
                      location.active ? (
                        <div className="pt-8">
                          <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Disable Location
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                              Disable a location so it cannot be used in AEV
                            </p>
                          </div>
                          <div className="mt-6">
                            <button
                              type="button"
                              disabled={loading}
                              onClick={() => setOpenDisableLocationModal(true)}
                              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                            >
                              <BanIcon className="h-4 w-4 mr-1" />
                              Disable Location
                            </button>
                          </div>
                          <DeleteModal
                            description="Disabling an location will prevent any future visitor passes from being created for the location"
                            open={openDisableLocationModal}
                            setOpen={setOpenDisableLocationModal}
                            title={
                              "Are you sure you want to disable this location?"
                            }
                            action={() => disableLocation()}
                            actionButtonText={"Disable"}
                          />
                        </div>
                      ) : (
                        <div className="pt-8">
                          <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Enable Location
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                              Enable a location so it can be used in AEV
                            </p>
                          </div>
                          <div className="mt-6">
                            <button
                              type="button"
                              disabled={loading}
                              onClick={() => enableLocation()}
                              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                            >
                              <CheckIcon className="h-4 w-4 mr-1" />
                              Enable Location
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="py-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Delete Location
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Permanently delete a location from the AEV database
                          </p>
                        </div>
                        <div className="mt-6">
                          <button
                            type="button"
                            disabled={loading || !locationDeletable}
                            onClick={() => setOpenDeleteLocationModal(true)}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800 disabled:opacity-50"
                          >
                            <TrashIcon className="h-4 w-4 mr-1" />
                            Delete Location
                          </button>
                          {!locationDeletable && (
                            <div className="mt-4 text-red-500 text-sm font-semibold border-none">
                              Please delete all lanes before deleting a location
                            </div>
                          )}
                        </div>
                        <DeleteModal
                          description="Deleting a location is permanent. It will be erased from AEV databases."
                          open={openDeleteLocationModal}
                          setOpen={setOpenDeleteLocationModal}
                          title={
                            "Are you sure you want to delete this location?"
                          }
                          action={() => deleteLocation()}
                          actionButtonText={"Delete"}
                        />
                        <DeleteModal
                          description="Deleting a lane is permanent. This lane will no longer belong to this location and will be erased from AEV databases."
                          open={openDeleteLaneModal}
                          setOpen={setOpenDeleteLaneModal}
                          title={"Are you sure you want to delete this lane?"}
                          action={() => deleteLane(laneIdToDelete)}
                          actionButtonText={"Delete"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={success}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully updated location
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={deleteSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Deleted successfully
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setDeleteSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={disableSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully disabled location
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setDisableSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={enableSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully enabled location
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setEnableSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={addLaneSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully added new lane
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setAddLaneSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
