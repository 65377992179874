import { ArrowLeftIcon, BanIcon, CheckIcon, EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../../utils/helpers/api";
import { useToast } from "../../../../../components/Toastr/Toastr";

export default function EditAPIKey() {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [apiKey, setApiKey] = useState<any>(null);
  const [description, setDescription] = useState<string>("");
  const [openDisableApiKeyModal, setOpenDisableApiKeyModal] = useState<boolean>(false);
  const [openDeleteApiKeyModal, setOpenDeleteApiKeyModal] = useState<boolean>(false);
  const [openEnableApiKeyModal, setOpenEnableApiKeyModal] = useState<boolean>(false);
  const [keyVisible, setKeyVisible] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Get apiKey from database
    const apiKeyId = params.apiKeyId;
    if (apiKeyId) {
      fetchApiKey(apiKeyId);
    } else {
      showErrorToast();
    }
  }, []);

  const fetchApiKey = async (apiKeyId: string) => {
    apiV3
      .getApiKeyById(apiKeyId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          const apiKey = res.data;
          setApiKey(apiKey);
          setDescription(apiKey.description);
        }
      })
      .catch((error) => {
        console.error(`Error occurred while fetching apiKey with id ${apiKeyId}`, error);
        showErrorToast();
      })
      .finally(() => setLoading(false));
  };

  const updateApiKey = async () => {
    setLoading(true);
    try {
      if (params.apiKeyId) {
        await apiV3.updateApiKeyById(params.apiKeyId, description);
        showSuccessToast("Successfully updated API key");
      } else {
        console.error(
          "Error occurred while updating api key - couldn't find the api key's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while updating api key - ", error);
      showErrorToast();
    }
    setLoading(false);
  };

  const enableApiKey = async () => {
    setLoading(true);
    setOpenEnableApiKeyModal(false);
    try {
      if (params.apiKeyId) {
        await apiV3.enableApiKeyById(params.apiKeyId);
        showSuccessToast("Successfully enabled API key");
        await fetchApiKey(params.apiKeyId);
      } else {
        console.error(
          "Error occurred while enabling api key - couldn't find the api key's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while enabling api key - ", error);
      showErrorToast();
      setLoading(false);
    }
  };

  const disableApiKey = async () => {
    setLoading(true);
    setOpenDisableApiKeyModal(false);
    try {
      if (params.apiKeyId) {
        await apiV3.disableApiKeyById(params.apiKeyId);
        showSuccessToast("Successfully disabled API key");
        await fetchApiKey(params.apiKeyId);
      } else {
        console.error(
          "Error occurred while disabling api key - couldn't find the api key's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while disabling api key - ", error);
      showErrorToast();
      setLoading(false);
    }
  };

  const deleteApiKey = async () => {
    setLoading(true);
    setOpenDeleteApiKeyModal(false);
    try {
      if (params.apiKeyId) {
        await apiV3.deleteApiKeyById(params.apiKeyId);
        showSuccessToast("Successfully deleted API key");
        navigate("/v3/api-keys");
      } else {
        console.error(
          "Error occurred while deleting api key - couldn't find the api key's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while deleting api key - ", error);
      showErrorToast();
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    Edit API Key
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Edit the description and status of an api key or delete it from the system
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="text-sm">
                      <span className="font-semibold">Status: </span>
                      {apiKey && apiKey.hasOwnProperty("active") && (
                        <span>
                          {apiKey.active ? (
                            <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              Active
                            </div>
                          ) : (
                            <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                              Inactive
                            </div>
                          )}
                        </span>
                      )}
                    </div>
                    <div className="pt-4">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="api-key"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Key
                          </label>
                          <div className="relative mt-2 rounded-md shadow-sm">
                            <input
                              type="text"
                              name="api-key"
                              id="api-key"
                              value={keyVisible ? apiKey.key ?? "" : "******************"}
                              disabled
                              className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100"
                              placeholder="000-00-0000"
                            />
                            <div
                              onClick={() => setKeyVisible(!keyVisible)}
                              className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3 opacity-80 hover:opacity-100"
                            >
                              {keyVisible ? (
                                <EyeOffIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              ) : (
                                <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="description"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Description
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="description"
                              id="description"
                              autoComplete="description"
                              disabled={loading}
                              value={description ?? ""}
                              onChange={(e) => setDescription(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {apiKey && apiKey.hasOwnProperty("active") && apiKey.active ? (
                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Disable API Key
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Disable an api key so it cannot be used to authenticate future API
                            requests
                          </p>
                        </div>
                        <div className="mt-6">
                          <button
                            type="button"
                            disabled={loading}
                            onClick={() => setOpenDisableApiKeyModal(true)}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                          >
                            <BanIcon className="h-4 w-4 mr-1" />
                            Disable API Key
                          </button>
                        </div>
                        <DeleteModal
                          description="Disabling an API key will prevent any future requests from being authenticated with this key."
                          open={openDisableApiKeyModal}
                          setOpen={setOpenDisableApiKeyModal}
                          title={"Are you sure you want to disable this API key?"}
                          action={() => disableApiKey()}
                          actionButtonText={"Disable"}
                        />
                      </div>
                    ) : (
                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Enable API Key
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Enable an API key so it can be used to authenticate future API requests
                          </p>
                        </div>
                        <div className="mt-6">
                          <button
                            type="button"
                            disabled={loading}
                            onClick={() => enableApiKey()}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                          >
                            <CheckIcon className="h-4 w-4 mr-1" />
                            Enable API Key
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="pt-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Delete API Key
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Permanently delete an API key from the Visitor Pass database
                        </p>
                      </div>
                      <div className="mt-6">
                        <button
                          type="button"
                          disabled={loading}
                          onClick={() => setOpenDeleteApiKeyModal(true)}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800 disabled:opacity-50"
                        >
                          <TrashIcon className="h-4 w-4 mr-1" />
                          Delete API Key
                        </button>
                      </div>
                      <DeleteModal
                        description="Deleting an API key is permanent. This API key will not be able to be used to authenticate future API requests and it will be erased from AEV's databases."
                        open={openDeleteApiKeyModal}
                        setOpen={setOpenDeleteApiKeyModal}
                        title={"Are you sure you want to delete this API key?"}
                        action={() => deleteApiKey()}
                        actionButtonText={"Delete"}
                      />
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v3/api-keys")}
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => updateApiKey()}
                        disabled={loading || !description}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Save
                      </button>
                      {!description && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter a description to update API key
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
