import { ArrowLeftIcon, ShieldCheckIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { classNames, getSubmittedOnFormattedDate } from "../../../../utils/helpers/misc";
import { useToast } from "../../../../components/Toastr/Toastr";
import { months } from "../../../../utils/helpers/misc";

export default function PersonSecuritySubmissionV3() {
  const navigate = useNavigate();
  const { showErrorToast } = useToast();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [personSecuritySubmission, setPersonSecuritySubmission] = useState<any>(null);
  const [faceImageUrl, setFaceImageUrl] = useState<string>("");
  const [idImageUrl, setIdImageUrl] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    // Get personSecuritySubmission from database
    const personSecuritySubmissionId = params.personSecuritySubmissionId;
    if (personSecuritySubmissionId) {
      fetchPersonSecuritySubmission(personSecuritySubmissionId);
    } else {
      showErrorToast();
    }
  }, []);

  const fetchPersonSecuritySubmission = async (personSecuritySubmissionId: string) => {
    try {
      setLoading(true);
      const res = await apiV3.getPersonSecuritySubmissionById(personSecuritySubmissionId);
      const personSecuritySubmission = res.data;
      setPersonSecuritySubmission(personSecuritySubmission);
      setLoading(false);

      // Get images
      const getImagesPromises = [];

      if (personSecuritySubmission.faceImage) {
        const faceImagePromise = apiV3.getPersonSecuritySubmissionFileById(
          personSecuritySubmission.id,
          personSecuritySubmission.faceImage
        );
        getImagesPromises.push(faceImagePromise);
      }

      if (personSecuritySubmission.idImage) {
        const idImagePromise = apiV3.getPersonSecuritySubmissionFileById(
          personSecuritySubmission.id,
          personSecuritySubmission.idImage
        );
        getImagesPromises.push(idImagePromise);
      }

      const [faceImageRes, idImageRes] = await Promise.all(getImagesPromises);

      if (faceImageRes && faceImageRes.data.url) {
        setFaceImageUrl(faceImageRes.data.url);
      }

      if (idImageRes && idImageRes.data.url) {
        setIdImageUrl(idImageRes.data.url);
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching personSecuritySubmission with id ${personSecuritySubmissionId}`,
        error
      );
      showErrorToast();
    }
  };

  return (
    <>
      <Sidebar />
      <div className="flex flex-col flex-1 md:pl-64">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            {personSecuritySubmission && !loading ? (
              <>
                <div className="max-w-3xl px-4 mx-auto sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                  <div className="flex items-center space-x-5">
                    <div>
                      <h1 className="flex flex-row items-center text-2xl font-bold text-gray-900">
                        <ShieldCheckIcon className="h-8 w-8 mr-1.5" />
                        {personSecuritySubmission.firstName +
                          " " +
                          personSecuritySubmission.lastName}
                      </h1>
                    </div>
                  </div>
                  <div className="flex flex-col-reverse mt-6 space-y-4 space-y-reverse justify-stretch sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                    <button
                      onClick={() => navigate(-1)}
                      type="button"
                      className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                    >
                      <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                      Back
                    </button>
                  </div>
                </div>

                <div className="grid max-w-3xl grid-cols-1 gap-6 mx-auto mt-8 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
                  <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                    {/* Description list*/}
                    <section aria-labelledby="applicant-information-title">
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h2
                            id="applicant-information-title"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Person Audit
                          </h2>
                          <p className="max-w-2xl mt-1 text-sm text-gray-500">
                            Submitted on{" "}
                            {getSubmittedOnFormattedDate(personSecuritySubmission.submittedOnLocal)}
                          </p>
                        </div>
                        <div className="flex flex-row px-4 py-5 border-t border-gray-200 sm:px-6">
                          <div className="flex gap-4 mr-12">
                            <div>
                              <img
                                className="w-64 mb-2 rounded-md"
                                src={
                                  faceImageUrl
                                    ? faceImageUrl
                                    : "https://i1.wp.com/www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg?ssl=1"
                                }
                              />
                            </div>
                            <div>
                              <img
                                className="w-64 rounded-md"
                                src={
                                  idImageUrl
                                    ? idImageUrl
                                    : "https://i1.wp.com/www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg?ssl=1"
                                }
                              />
                            </div>
                          </div>
                          <div className="flex-1">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">ASIC ID</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {personSecuritySubmission.asicId}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">Company</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {personSecuritySubmission.company}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  ASIC Expiry Date
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {(personSecuritySubmission.asicExpiryDate
                                    ? months[
                                        new Date(
                                          personSecuritySubmission.asicExpiryDate
                                        ).getUTCMonth()
                                      ]
                                    : "") +
                                    " " +
                                    new Date(
                                      personSecuritySubmission.asicExpiryDate
                                    ).getUTCFullYear()}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Location Name
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {personSecuritySubmission.locationName ?? "N/A"}
                                </dd>
                              </div>
                              {personSecuritySubmission.vehicleRegistration && (
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-semibold text-gray-500">
                                    Vehicle Registration
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {personSecuritySubmission.vehicleRegistration ?? "N/A"}
                                  </dd>
                                </div>
                              )}

                              {personSecuritySubmission.submittedLatitude &&
                                personSecuritySubmission.submittedLongitude && (
                                  <div className="sm:col-span-1">
                                    <dt className="text-sm font-semibold text-gray-500">
                                      Submitted From
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                      ({personSecuritySubmission.submittedLatitude},{" "}
                                      {personSecuritySubmission.submittedLongitude})
                                    </dd>
                                  </div>
                                )}

                              {personSecuritySubmission.deviceName && (
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-semibold text-gray-500">
                                    Device Name
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {personSecuritySubmission.deviceName ?? "N/A"}
                                  </dd>
                                </div>
                              )}

                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">Pass</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {personSecuritySubmission.pass ? (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                      Passed
                                    </div>
                                  ) : (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                      Failed
                                    </div>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">Note</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {personSecuritySubmission.note}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">Face Match</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  <div
                                    className={classNames(
                                      "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                                      parseFloat(personSecuritySubmission.match) > 50
                                        ? "bg-green-100 text-green-800"
                                        : "bg-red-100 text-red-800"
                                    )}
                                  >
                                    {(+parseFloat(personSecuritySubmission.match).toFixed(
                                      2
                                    )).toString() + "%"}
                                  </div>
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Submitted By
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {personSecuritySubmission.submittedBy}
                                </dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-semibold text-gray-500">
                                  Security Tier
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {`${personSecuritySubmission.securityTierName ?? "N/A"} ${
                                    personSecuritySubmission.securityTierLevel
                                      ? `(Level ${personSecuritySubmission.securityTierLevel})`
                                      : ""
                                  }`}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section aria-labelledby="applicant-information-title">
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h2
                            id="applicant-information-title"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Checks
                          </h2>
                        </div>
                        <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
                          <div>
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                              {personSecuritySubmission.hasOwnProperty("checks") &&
                                JSON.parse(personSecuritySubmission.checks).map((check: any) => {
                                  return (
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-semibold text-gray-500">
                                        {check.name}
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {check.pass ? (
                                          <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                            {check.name === "Driver" ? "Yes" : "Passed"}
                                          </div>
                                        ) : (
                                          <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                            {check.name === "Driver" ? "No" : "Failed"}
                                          </div>
                                        )}
                                      </dd>
                                    </div>
                                  );
                                })}
                            </dl>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </>
            ) : (
              <div className="mt-2 text-sm">Loading...</div>
            )}
          </div>
        </main>
      </div>
    </>
  );
}
