import { Route, Routes } from "react-router-dom";
import AddAirportV3 from "../../pages/V3/AirportManagementV3/AddAirportV3/AddAirportV3";
import AirportManagementV3 from "../../pages/V3/AirportManagementV3/AirportManagementV3";
import EditAirportV3 from "../../pages/V3/AirportManagementV3/EditAirportV3/EditAirportV3";
import { RequireAuth } from "../../utils/auth/auth";

export default function AirportManagementV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <AirportManagementV3 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddAirportV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:airportId"
        element={
          <RequireAuth>
            <EditAirportV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
