import { Route, Routes } from "react-router-dom";
import AEVLocationsV3 from "../../pages/V3/AEVLocationsV3/AEVLocationsV3";
import AddAEVLocationV3 from "../../pages/V3/AEVLocationsV3/AddAEVLocationV3/AddAEVLocationV3";
import EditAEVLocationV3 from "../../pages/V3/AEVLocationsV3/EditAEVLocationV3/EditAEVLocationV3";
import { RequireAuth } from "../../utils/auth/auth";
import CreateAEVLocationConfigurationV3 from "../../pages/V3/AEVLocationsV3/CreateAEVLocationConfigurationV3/CreateAEVLocationConfigurationV3";

export default function AEVLocationsV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <AEVLocationsV3 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddAEVLocationV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:locationId"
        element={
          <RequireAuth>
            <EditAEVLocationV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:locationId/create-audit-configuration"
        element={
          <RequireAuth>
            <CreateAEVLocationConfigurationV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
