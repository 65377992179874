import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../utils/auth/auth";
import ReportsV3 from "../../pages/V3/ReportsV3/ReportsV3";
import ConfigureReportV3 from "../../pages/V3/ReportsV3/ConfigureReportV3/ConfigureReportV3";

export default function ReportsV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <ReportsV3 />
          </RequireAuth>
        }
      />
      <Route
        path="configure/:reportType"
        element={
          <RequireAuth>
            <ConfigureReportV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
