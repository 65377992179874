import { ArrowLeftIcon, GlobeIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV2 } from "../../../../utils/helpers/api";
import { iannaTimeZones } from "../../../../utils/helpers/misc";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function AddAirportV3() {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [timezone, setTimezone] = useState<string>("Australia/Melbourne");
  const [currentTime, setCurrentTime] = useState<string>("");

  const addAirport = async () => {
    setLoading(true);
    try {
      await apiV2.createAirport({
        name,
        timezone,
      });
      showSuccessToast("Successfully created airport");
      navigate("/v2/airport-management");
    } catch (error) {
      console.error("Error occurred while creating airport - ", error);
      showErrorToast();
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let localizedTimeString = "Invalid Timezone - couldn't find current time";
    try {
      localizedTimeString = new Date().toLocaleString("en-US", {
        timeZone: timezone,
      });
    } catch (error) {
      console.error("Error while setting currentTime -", error);
    }

    setCurrentTime(localizedTimeString);
  }, [timezone]);

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <GlobeIcon className="h-7 w-7 mr-1.5" />
                    Add Airport
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Create a new airport with a specific timezone
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8">
                    <div>
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Name
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              disabled={loading}
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="timezone"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Timezone
                          </label>
                          <select
                            id="timezone"
                            name="timezone"
                            autoComplete="timezone"
                            disabled={loading}
                            onChange={(e) => setTimezone(e.target.value)}
                            value={timezone}
                            className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                          >
                            {iannaTimeZones.map((tz) => {
                              return <option>{tz}</option>;
                            })}
                          </select>
                        </div>
                        <div className="sm:col-span-4">
                          <div className="block">
                            <span className="font-semibold">Current Time:</span> {currentTime}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v2/airport-management")}
                        type="button"
                        disabled={loading}
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={() => addAirport()}
                        disabled={loading || !name || !timezone}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Create
                      </button>
                      {!name ||
                        (!timezone && (
                          <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                            Please enter all the required fields to create an airport
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
