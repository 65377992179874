export enum UserRole {
  Unknown = 0,
  Admin = 1,
  AirportAdmin = 2,
  AirportOfficer = 3,
}

export type User = {
  id: string;
  username: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  hashedPassword?: string;
  roles: UserRole[];
  firstName: string;
  lastName: string;
};

export type CreateUserRequest = {
  username: string;
  email: string;
  password?: string;
  role?: number;
  firstName: string;
  lastName: string;
  active: boolean;
  defaultLogoutTime?: string;
  selfServeLocationId?: string;
};

export type UpdateUserRequest = {
  id: string;
  username: string;
  email: string;
  password?: string;
  role?: number;
  firstName: string;
  lastName: string;
  active: boolean;
  defaultLogoutTime?: string;
  selfServeLocationId?: string;
};

export type CreateAirportRequest = {
  name: string;
  timezone: string;
};

export type UpdateAirportRequest = {
  name?: string;
  timezone?: string;
};

export type CreateAuditPolicyRequest = {
  title: string;
  securityTierId: string;
  type: number;
  randomizedChecksGroupIds: string[];
  securityCheckIds: string[];
};

export type UpdateAuditPolicyRequest = {
  title: string;
  securityTierId: string;
  type: number;
  randomizedChecksGroupIds: string[];
  securityCheckIds: string[];
};

export type CreateLocationRequest = {
  name: string;
  active: boolean;
  fixedLocation: boolean;
  fixedLocationReportingLatitude?: number;
  fixedLocationReportingLongitude?: number;
};

export type UpdateLocationRequest = {
  name: string;
  active: boolean;
  hasVehicles: boolean;
  fixedLocation: boolean;
  fixedLocationReportingLatitude?: number;
  fixedLocationReportingLongitude?: number;
};

export type CreateLocationAuditConfigurationRequest = {
  locationId: string;
  requiredScan: number;
  extensionThreshold: number;
  extensionAmount: number;
  locationAuditPolicies: {
    percentage: number;
    auditPolicyId: string;
  }[];
};

export type LocationAuditPolicy = {
  id: number;
  auditPolicyId: string;
  percentage: number;
};

export type CreateReportConfigurationRequest = {
  reportFrequency: string;
  sendFrequency: string;
  sendTime: number;
  recipientEmailAddresses: string[];
};

export type UpdateReportConfigurationRequest = {
  reportFrequency?: string;
  sendFrequency?: string;
  sendTime?: number;
  recipientEmailAddresses?: string[];
};

export type CreateSecurityCheckRequest = {
  name: string;
  key: string;
  defaultResult: string;
  active: boolean;
};

export type UpdateSecurityCheckRequest = {
  name?: string;
  type?: string;
  key?: string;
  defaultResult?: string;
  active?: boolean;
};

export type CreateSecurityTierRequest = {
  name: string;
  level: number;
};

export type UpdateSecurityTierRequest = {
  name?: string;
  level?: number;
};

export enum VisitorPassStatus {
  Unknown = 0,
  Requested = 1,
  Approved = 2,
  Declined = 3,
  Expired = 4,
  Cancelled = 5,
  Printed = 6,
}

export type AsicSearchParams = {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  asicId?: string;
};

export type PersonSecuritySubmissionSearchParams = {
  locationName?: string;
  asicId?: string;
  firstName?: string;
  lastName?: string;
  company?: string;
};

export type SelfServeAuditSearchParams = {
  asicId?: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  accessLocation?: string;
};

export type SearchFilter = {
  id: number;
  searchField: string;
  searchQuery: string;
};
