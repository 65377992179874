import { ArrowLeftIcon } from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function EditSecurityTierV3() {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [securityTier, setSecurityTier] = useState<any>(null);
  const [name, setName] = useState<string>("");
  const [level, setLevel] = useState<number>(1);
  const [openDeleteSecurityTierModal, setOpenDeleteSecurityTierModal] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Get securityTier from database
    const securityTierId = params.securityTierId;
    if (securityTierId) {
      fetchSecurityTier(securityTierId);
    } else {
      showErrorToast();
    }
  }, []);

  const fetchSecurityTier = async (securityTierId: string) => {
    apiV3
      .getSecurityTierById(securityTierId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          const securityTier = res.data;
          setSecurityTier(securityTier);
          setName(securityTier.name);
          setLevel(securityTier.level);
        }
      })
      .catch((error) => {
        console.error(
          `Error occurred while fetching securityTier with id ${securityTierId}`,
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const updateSecurityTier = async () => {
    setLoading(true);
    try {
      if (params.securityTierId) {
        await apiV3.updateSecurityTierById(params.securityTierId, {
          name,
          level,
        });
        showSuccessToast("Successfully updated security tier");
      } else {
        console.error(
          "Error occurred while updating securityTier - couldn't find the securityTier's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while updating securityTier - ", error);
      showErrorToast();
    }
    setLoading(false);
  };

  const deleteSecurityTier = async () => {
    setLoading(true);
    setOpenDeleteSecurityTierModal(false);
    try {
      if (params.securityTierId) {
        await apiV3.deleteSecurityTierById(params.securityTierId);
        showSuccessToast("Successfully deleted security tier");
        navigate("/v3/security-tiers");
      } else {
        console.error(
          "Error occurred while deleting security tier - couldn't find the security tier's id from the URL parameters"
        );
        showErrorToast();
        setLoading(false);
      }
    } catch (error) {
      console.error("Error occurred while deleting security tier - ", error);
      showErrorToast();
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    Edit Security Tier {name && <em className="ml-1">({name})</em>}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Edit the name and level of a security tier or delete it from the system
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="pt-4">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Name
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              disabled={loading}
                              value={name ?? ""}
                              onChange={(e) => setName(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="level"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Level
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="number"
                              name="level"
                              id="level"
                              autoComplete="level"
                              disabled={loading}
                              value={level ?? ""}
                              onChange={(e) => setLevel(parseInt(e.target.value, 10))}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DeleteModal
                    description="Deleting a security tier is permanent. It will be erased from AEV databases."
                    open={openDeleteSecurityTierModal}
                    setOpen={setOpenDeleteSecurityTierModal}
                    title={"Are you sure you want to delete this security tier?"}
                    action={() => deleteSecurityTier()}
                    actionButtonText={"Delete"}
                  />

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v3/security-tiers")}
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => updateSecurityTier()}
                        disabled={loading || !name || !level}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => setOpenDeleteSecurityTierModal(true)}
                        disabled={loading}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60"
                      >
                        Delete
                      </button>
                      {(!name || !level) && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to update the security tier
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
