import { Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import {
  PencilIcon,
  ShieldCheckIcon,
  TruckIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV2 } from "../../../../utils/helpers/api";

export default function VehicleAuditV2() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [vehicleAudit, setVehicleAudit] = useState<any>(null);
  const [vehicleSecurityImage, setVehicleSecurityImage] = useState<string>("");
  const [occupantFaceImages, setOccupantFaceImages] = useState<string[]>([]);
  const [occupantIdImages, setOccupantIdImages] = useState<string[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Get vehicleAudit from database
    const vehicleAuditId = params.vehicleAuditId;
    if (vehicleAuditId) {
      fetchVehicleAudit(vehicleAuditId);
    } else {
      setError(true);
    }
  }, []);

  const fetchVehicleAudit = async (vehicleAuditId: string) => {
    try {
      setLoading(true);
      const res = await apiV2.getVehicleAuditById(vehicleAuditId);
      const vehicleAudit = res.data;
      setVehicleAudit(vehicleAudit);
      setLoading(false);

      if (
        vehicleAudit.hasOwnProperty("vehicleSecuritySubmission") &&
        vehicleAudit.vehicleSecuritySubmission.hasOwnProperty("image") &&
        vehicleAudit.vehicleSecuritySubmission.image
      ) {
        // Get the image
        const vehicleSecurityImageRes = await apiV2.getFile(
          0,
          vehicleAudit.vehicleSecuritySubmission.image
        );
        setVehicleSecurityImage(
          URL.createObjectURL(vehicleSecurityImageRes.data)
        );
      }

      if (
        vehicleAudit.hasOwnProperty("vehicleOccupants") &&
        vehicleAudit.vehicleOccupants.length > 0
      ) {
        const faceImages = [];
        const idImages = [];
        for (const vehicleOccupant of vehicleAudit.vehicleOccupants) {
          let faceImageUrl = "";
          if (
            vehicleOccupant.hasOwnProperty("personSecuritySubmission") &&
            vehicleOccupant.personSecuritySubmission.hasOwnProperty("faceImage")
          ) {
            // Get the image
            const faceImageBlob = await apiV2.getFile(
              0,
              vehicleOccupant.personSecuritySubmission.faceImage
            );
            faceImageUrl = URL.createObjectURL(faceImageBlob.data);
          }

          faceImages.push(faceImageUrl);

          let idImageUrl = "";
          if (
            vehicleOccupant.hasOwnProperty("personSecuritySubmission") &&
            vehicleOccupant.personSecuritySubmission.hasOwnProperty("idImage")
          ) {
            // Get the image
            const idImageBlob = await apiV2.getFile(
              0,
              vehicleOccupant.personSecuritySubmission.idImage
            );
            idImageUrl = URL.createObjectURL(idImageBlob.data);
          }
          idImages.push(idImageUrl);
        }

        setOccupantFaceImages(faceImages);
        setOccupantIdImages(idImages);
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching vehicleAudit with id ${vehicleAuditId}`,
        error
      );
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    Vehicle Audit
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    View the details of a vehicle audit
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              {vehicleAudit && !loading ? (
                <div className="mt-4 flex flex-col max-w-screen-md">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200">
                      <div>
                        <div className="text-sm">
                          <span className="font-semibold">
                            Vehicle Registration:
                          </span>{" "}
                          {vehicleAudit.vehicleRegistration}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">Airport:</span>{" "}
                          {vehicleAudit.airportName}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">
                            Number of Occupants:
                          </span>{" "}
                          {vehicleAudit.numberOfOccupants}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">Created On:</span>{" "}
                          {new Date(vehicleAudit.createdOn).toDateString()}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">Status:</span>{" "}
                          {vehicleAudit.status}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">Vehicle Type:</span>{" "}
                          {vehicleAudit.vehicleType}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">Location:</span>{" "}
                          {vehicleAudit.locationName}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">Created By:</span>{" "}
                          {vehicleAudit.createdByUserName}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">Completed On:</span>{" "}
                          {new Date(vehicleAudit.completedOn).toDateString()}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">Overruled:</span>{" "}
                          {vehicleAudit.overruled ? "Yes" : "No"}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">
                            Overruled Reason:
                          </span>{" "}
                          {vehicleAudit.overruledReason}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">Lane:</span>{" "}
                          {vehicleAudit.lane}
                        </div>
                      </div>
                      <div className="space-y-1">
                        <div>
                          <h2 className="text-xl leading-6 font-medium text-gray-900 pt-3 inline-flex items-center justify-center">
                            <TruckIcon className="h-5 w-5 mr-1.5" />
                            Vehicle Security Submission
                          </h2>
                        </div>
                        <div>
                          {vehicleSecurityImage && (
                            <img
                              className="rounded-md w-64 my-2"
                              src={vehicleSecurityImage}
                            />
                          )}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">Created By:</span>{" "}
                          {vehicleAudit.vehicleSecuritySubmission.createdBy}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">Created On:</span>{" "}
                          {new Date(
                            vehicleAudit.vehicleSecuritySubmission.createdOn
                          ).toDateString()}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">Pass:</span>{" "}
                          {vehicleAudit.vehicleSecuritySubmission.pass
                            ? "Yes"
                            : "No"}
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold">Submitted On:</span>{" "}
                          {new Date(
                            vehicleAudit.vehicleSecuritySubmission.submittedOn
                          ).toDateString()}
                        </div>
                        <div>
                          <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900 mt-2 mb-1 inline-flex items-center justify-center">
                              <ShieldCheckIcon className="h-5 w-5 mr-1.5" />
                              Security Checks
                            </h3>
                          </div>
                          <fieldset className="space-y-2">
                            {vehicleAudit.vehicleSecuritySubmission.hasOwnProperty(
                              "securityChecks"
                            ) &&
                              vehicleAudit.vehicleSecuritySubmission
                                .securityChecks.length > 0 && (
                                <>
                                  {vehicleAudit.vehicleSecuritySubmission.securityChecks.map(
                                    (securityCheck: any) => {
                                      return (
                                        <div className="relative flex items-start">
                                          <div className="flex h-5 items-center">
                                            <input
                                              id="comments"
                                              aria-describedby="comments-description"
                                              name="comments"
                                              type="checkbox"
                                              checked={
                                                securityCheck.result === "Pass"
                                              }
                                              disabled
                                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                          </div>
                                          <div className="ml-3 text-sm">
                                            <label
                                              htmlFor="comments"
                                              className="text-gray-700"
                                            >
                                              {securityCheck.name}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              )}
                          </fieldset>
                        </div>
                      </div>
                      <div className="space-y-1">
                        <div>
                          <h2 className="text-xl leading-6 font-medium text-gray-900 pt-3 inline-flex items-center justify-center">
                            <UserGroupIcon className="h-5 w-5 mr-1.5" />
                            Occupant Security Submissions
                          </h2>
                        </div>
                        {vehicleAudit.hasOwnProperty("vehicleOccupants") &&
                          vehicleAudit.vehicleOccupants.length > 0 && (
                            <div>
                              {vehicleAudit.vehicleOccupants.map(
                                (vehicleOccupant: any, i: number) => {
                                  return (
                                    <div>
                                      <h3 className="text-lg leading-6 font-medium text-gray-900 mt-2 mb-1 inline-flex items-center justify-center">
                                        <UserCircleIcon className="h-5 w-5 mr-1.5" />
                                        {vehicleOccupant.isDriver
                                          ? "Driver"
                                          : "Passenger"}
                                      </h3>
                                      <div>
                                        {vehicleOccupant.hasOwnProperty(
                                          "personSecuritySubmission"
                                        ) &&
                                          vehicleOccupant.personSecuritySubmission && (
                                            <div className="space-y-1 mb-6">
                                              <div className="flex flex-row space-x-2">
                                                <img
                                                  src={occupantFaceImages[i]}
                                                  className="w-64 rounded-md"
                                                />
                                                <img
                                                  src={occupantIdImages[i]}
                                                  className="w-64 rounded-md"
                                                />
                                              </div>
                                              <div className="text-sm">
                                                <span className="font-semibold">
                                                  First Name:
                                                </span>{" "}
                                                {
                                                  vehicleOccupant
                                                    .personSecuritySubmission
                                                    .firstName
                                                }
                                              </div>
                                              <div className="text-sm">
                                                <span className="font-semibold">
                                                  Last Name:
                                                </span>{" "}
                                                {
                                                  vehicleOccupant
                                                    .personSecuritySubmission
                                                    .lastName
                                                }
                                              </div>
                                              <div className="text-sm">
                                                <span className="font-semibold">
                                                  Company:
                                                </span>{" "}
                                                {
                                                  vehicleOccupant
                                                    .personSecuritySubmission
                                                    .company
                                                }
                                              </div>
                                              <div className="text-sm">
                                                <span className="font-semibold">
                                                  ASIC ID:
                                                </span>{" "}
                                                {
                                                  vehicleOccupant
                                                    .personSecuritySubmission
                                                    .asicId
                                                }
                                              </div>
                                              <div className="text-sm">
                                                <span className="font-semibold">
                                                  ASIC ID Expiry:
                                                </span>{" "}
                                                {new Date(
                                                  vehicleOccupant.personSecuritySubmission.asicExpirationDate
                                                ).toDateString()}
                                              </div>
                                              <div className="text-sm">
                                                <span className="font-semibold">
                                                  Pass:
                                                </span>{" "}
                                                {vehicleOccupant
                                                  .personSecuritySubmission.pass
                                                  ? "Yes"
                                                  : "No"}
                                              </div>
                                              <div className="text-sm">
                                                <span className="font-semibold">
                                                  Status:
                                                </span>{" "}
                                                {
                                                  vehicleOccupant
                                                    .personSecuritySubmission
                                                    .status
                                                }
                                              </div>
                                              <div className="text-sm">
                                                <span className="font-semibold">
                                                  Note:
                                                </span>{" "}
                                                {
                                                  vehicleOccupant
                                                    .personSecuritySubmission
                                                    .note
                                                }
                                              </div>
                                              <div className="text-sm">
                                                <span className="font-semibold">
                                                  Submitted By:
                                                </span>{" "}
                                                {
                                                  vehicleOccupant
                                                    .personSecuritySubmission
                                                    .submittedBy
                                                }
                                              </div>
                                              <div className="text-sm">
                                                <span className="font-semibold">
                                                  Submitted On:
                                                </span>{" "}
                                                {new Date(
                                                  vehicleOccupant.personSecuritySubmission.submittedOn
                                                ).toDateString()}
                                              </div>
                                              <div>
                                                <div>
                                                  <h4 className="text-md leading-6 font-medium text-gray-900 mt-2 mb-1 inline-flex items-center justify-center">
                                                    <ShieldCheckIcon className="h-5 w-5 mr-1.5" />
                                                    Security Checks
                                                  </h4>
                                                </div>
                                                <fieldset className="space-y-2">
                                                  {vehicleOccupant.personSecuritySubmission.hasOwnProperty(
                                                    "securityChecks"
                                                  ) &&
                                                    vehicleOccupant
                                                      .personSecuritySubmission
                                                      .securityChecks.length >
                                                      0 && (
                                                      <>
                                                        {vehicleOccupant.personSecuritySubmission.securityChecks.map(
                                                          (
                                                            securityCheck: any
                                                          ) => {
                                                            return (
                                                              <div className="relative flex items-start">
                                                                <div className="flex h-5 items-center">
                                                                  <input
                                                                    id="comments"
                                                                    aria-describedby="comments-description"
                                                                    name="comments"
                                                                    type="checkbox"
                                                                    checked={
                                                                      securityCheck.result ===
                                                                      "Pass"
                                                                    }
                                                                    disabled
                                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                  />
                                                                </div>
                                                                <div className="ml-3 text-sm">
                                                                  <label
                                                                    htmlFor="comments"
                                                                    className="text-gray-700"
                                                                  >
                                                                    {
                                                                      securityCheck.name
                                                                    }
                                                                  </label>
                                                                </div>
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </>
                                                    )}
                                                </fieldset>
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-sm mt-2">Loading...</div>
              )}
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={success}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully updated visitor
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
