import { Routes, Route } from "react-router-dom";
import AEVLocationsV2Router from "./AEVLocationsV2Router";
import ASICImportV2Router from "./ASICImportV2Router";
import ASICManagementV2Router from "./ASICManagementV2Router";
import AirportManagementV2Router from "./AirportManagementV2Router";
import OtherASICsV2Router from "./OtherASICsV2Router";
import PersonAuditsV2Router from "./PersonAuditsV2Router";
import ReportsV2Router from "./ReportsV2Router";
import SecuritySubmissionsV2Router from "./SecuritySubmissionsV2Router";
import SelfServeAuditsV2Router from "./SelfServeAuditsV2Router";
import SelfServeLocationsV2Router from "./SelfServeLocationsV2Router";
import UserManagementV2Router from "./UserManagementV2Router";
import VehicleAuditsV2Router from "./VehicleAuditsV2Router";

export default function V2Router() {
  return (
    <Routes>
      <Route
        path="airport-management/*"
        element={<AirportManagementV2Router />}
      />
      <Route path="aev-locations/*" element={<AEVLocationsV2Router />} />
      <Route path="asic-management/*" element={<ASICManagementV2Router />} />
      <Route path="asic-import/*" element={<ASICImportV2Router />} />
      <Route path="other-asics/*" element={<OtherASICsV2Router />} />
      <Route path="person-audits/*" element={<PersonAuditsV2Router />} />
      <Route path="reports/*" element={<ReportsV2Router />} />
      <Route
        path="security-submissions/*"
        element={<SecuritySubmissionsV2Router />}
      />
      <Route path="self-serve-audits/*" element={<SelfServeAuditsV2Router />} />
      <Route
        path="self-serve-locations/*"
        element={<SelfServeLocationsV2Router />}
      />
      <Route path="user-management/*" element={<UserManagementV2Router />} />
      <Route path="vehicle-audits/*" element={<VehicleAuditsV2Router />} />
    </Routes>
  );
}
