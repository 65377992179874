import { Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  TicketIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../../components/Sidebar/Sidebar";

export default function SecuritySubmissionV2() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [visitor, setVisitor] = useState<any>(null);
  const [vpNumber, setVPNumber] = useState<string>("");
  const [vpApprovedCount, setVPApprovedCount] = useState<number>(0);
  const [vpStartDate, setVPStartDate] = useState<Date | null>(new Date());
  const [vpExpiryDate, setVPExpiryDate] = useState<Date | null>(new Date());
  const [email, setEmail] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [job, setJob] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [verified, setVerified] = useState<string>("false");
  const [status, setStatus] = useState<string>("");
  const [openDeleteVisitorModal, setOpenDeleteVisitorModal] =
    useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    // // Get visitor from database
    // const visitorId = params.visitorId;
    // if (visitorId) {
    //   api
    //     .getVisitorById(visitorId)
    //     .then((res) => {
    //       if (res.status === 200 && res.data) {
    //         const visitor = res.data;
    //         setVisitor(visitor);
    //         setVPNumber(visitor.vpNumber);
    //         setVPApprovedCount(visitor.vpApprovedCount);
    //         setVPStartDate(
    //           visitor.vpStartDate ? new Date(visitor.vpStartDate) : null
    //         );
    //         setVPExpiryDate(
    //           visitor.vpExpiryDate ? new Date(visitor.vpExpiryDate) : null
    //         );
    //         setEmail(visitor.email);
    //         setImage(visitor.image);
    //         setDob(visitor.dob);
    //         setGender(visitor.gender);
    //         setPhone(visitor.phone);
    //         setCompany(visitor.company);
    //         setJob(visitor.job);
    //         setFirstName(visitor.firstName);
    //         setLastName(visitor.lastName);
    //         setVerified(visitor.verified.toString());
    //         setStatus(visitor.status);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(
    //         `Error occurred while fetching visitor with id ${visitorId}`,
    //         error
    //       );
    //     })
    //     .finally(() => setLoading(false));
    // } else {
    //   setError(true);
    // }
  }, []);

  const updateVisitor = async () => {
    setLoading(true);
    try {
      if (params.visitorId) {
        // const updatedVisitor = {
        //   email,
        //   image,
        //   dob,
        //   gender,
        //   phone,
        //   company,
        //   job,
        //   firstName,
        //   lastName,
        //   verified: verified === "true" ? true : false,
        //   status,
        //   id: params.visitorId,
        // } as UpdateVisitorRequest;
        // await apiV2.updateVisitorById(params.visitorId, updatedVisitor);
        setSuccess(true);
      } else {
        console.error(
          "Error occurred while updating visitor - couldn't find the visitor's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while updating visitor - ", error);
      setError(true);
    }
    setLoading(false);
  };

  const deleteVisitor = async () => {
    setLoading(true);
    setOpenDeleteVisitorModal(false);
    try {
      if (params.visitorId) {
        // await apiV2.deleteVisitor(params.visitorId);
        setDeleteSuccess(true);
        setTimeout(() => navigate("/v2/visitor-management"), 3000);
      } else {
        console.error(
          "Error occurred while deleting visitor - couldn't find the visitor's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while deleting visitor - ", error);
      setError(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    Edit Visitor {email && <em className="ml-1">({email})</em>}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Edit the details of a visitor or delete them from the system
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div>
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Profile
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          This information will be displayed publicly
                        </p>
                      </div>

                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        {image && (
                          <div className="sm:col-span-6 h-16 w-16 overflow-hidden rounded-full">
                            <img
                              className="inline-block h-full w-full"
                              src={image}
                            />
                          </div>
                        )}

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First name
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              disabled={loading || deleteSuccess}
                              value={firstName ?? ""}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last name
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              disabled={loading || deleteSuccess}
                              value={lastName ?? ""}
                              onChange={(e) => setLastName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              disabled={loading || deleteSuccess}
                              value={email ?? ""}
                              onChange={(e) => setEmail(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="dob"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Date of Birth
                          </label>
                          <div className="mt-1">
                            <input
                              id="dob"
                              name="dob"
                              type="text"
                              autoComplete="dob"
                              disabled
                              value={dob ?? ""}
                              onChange={(e) => setDob(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="gender"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Gender
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="gender"
                              id="gender"
                              autoComplete="gender"
                              disabled={loading || deleteSuccess}
                              value={gender ?? ""}
                              onChange={(e) => setGender(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              autoComplete="phone"
                              disabled={loading || deleteSuccess}
                              value={phone ?? ""}
                              onChange={(e) => setPhone(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="company"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Company
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="company"
                              id="company"
                              autoComplete="company"
                              disabled={loading || deleteSuccess}
                              value={company ?? ""}
                              onChange={(e) => setCompany(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="job"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Job
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="job"
                              id="job"
                              autoComplete="job"
                              disabled={loading || deleteSuccess}
                              value={job ?? ""}
                              onChange={(e) => setJob(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Metadata
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          This information is only viewable by admins
                        </p>
                      </div>

                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="status"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Status
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="status"
                              id="status"
                              autoComplete="status"
                              disabled={loading || deleteSuccess}
                              value={status ?? ""}
                              onChange={(e) => setStatus(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="verified"
                            className="inline-flex relative items-center cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              checked={verified === "true"}
                              onChange={(e) =>
                                setVerified(String(e.target.checked))
                              }
                              id="verified"
                              className="sr-only peer"
                            />
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-amber-300 rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-amber-500"></div>
                            <span className="ml-3 text-sm font-medium text-gray-900">
                              Verified
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="pt-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Related Information
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          View information associated with this visitor
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-semibold">VP Number:</span>
                          <span className="ml-2">{vpNumber}</span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-semibold">Start Date:</span>
                          <span className="ml-2">
                            {vpStartDate
                              ? vpStartDate.toDateString()
                              : "No Date Specified"}
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-semibold">End Date:</span>
                          <span className="ml-2">
                            {vpExpiryDate
                              ? vpExpiryDate.toDateString()
                              : "No Date Specified"}
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-semibold">
                            Approved Pass Count:
                          </span>
                          <span className="ml-2">
                            {vpApprovedCount} of 28 issued
                          </span>
                        </p>
                        <button
                          type="button"
                          onClick={() =>
                            navigate(
                              "/v2/visitor-pass-requests/visitor/" +
                                params.visitorId
                            )
                          }
                          className="mt-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                          <TicketIcon className="h-4 w-4 mr-1" />
                          View Visitor Passes
                        </button>
                      </div>
                    </div>

                    <div className="pt-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Delete Visitor
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Delete a visitor and all associated data permanently
                        </p>
                      </div>
                      <div className="mt-6">
                        <button
                          type="button"
                          onClick={() => setOpenDeleteVisitorModal(true)}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <TrashIcon className="h-4 w-4 mr-1" />
                          Delete Visitor
                        </button>
                      </div>
                      <DeleteModal
                        description="Deleting a visitor is permanent. You will not be able to recover data once deleted"
                        open={openDeleteVisitorModal}
                        setOpen={setOpenDeleteVisitorModal}
                        title={"Are you sure you want to delete this visitor?"}
                        action={() => deleteVisitor()}
                      />
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v2/visitor-management")}
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => updateVisitor()}
                        disabled={loading || !firstName || !lastName || !email}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Save
                      </button>
                      {(!firstName || !lastName || !email) && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to update visitor
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={success}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully updated visitor
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={deleteSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully deleted visitor
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Returning to visitor management portal...
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setDeleteSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
