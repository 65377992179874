import { ArrowLeftIcon, DocumentTextIcon, LockClosedIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function AddAuditPolicyV3() {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [type, setType] = useState<number>(0);
  const [securityTierId, setSecurityTierId] = useState<string>("");
  const [securityCheckIds, setSecurityCheckIds] = useState<string[]>([]);
  const [securityTiers, setSecurityTiers] = useState<any[]>([]);
  const [securityChecks, setSecurityChecks] = useState<any[]>([]);

  const createAuditPolicy = async () => {
    try {
      setIsBusy(true);
      await apiV3.createAuditPolicy({
        title: title,
        type: type,
        securityTierId: securityTierId,
        securityCheckIds: securityCheckIds,
        randomizedChecksGroupIds: [],
      });
      showSuccessToast("Successfully created audit policy");
      setIsBusy(false);
      navigate("/v3/audit-policies");
    } catch (error) {
      console.error("Error occurred while creating audit policy - ", error);
      showErrorToast();
      setIsBusy(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchInputData();
  }, []);

  const fetchInputData = async () => {
    setLoading(true);
    try {
      await fetchSecurityChecks();
      await fetchSecurityTiers();
    } catch (error) {
      console.error("Error occurred while fetching input data - ", error);
      showErrorToast();
    } finally {
      setLoading(false);
    }
  };

  const fetchSecurityTiers = async () => {
    const securityTiers = await apiV3.getSecurityTiers(1, 1000, "", "", "DESC", "");

    if (securityTiers.status === 200 && securityTiers.data.data) {
      setSecurityTiers(securityTiers.data.data);
      setSecurityTierId(securityTiers.data.data[0].id);
    }
  };

  const fetchSecurityChecks = async () => {
    const securityChecks = await apiV3.getSecurityChecks(1, 1000, "", "", "DESC", "");
    if (securityChecks.status === 200 && securityChecks.data.data) {
      setSecurityChecks(securityChecks.data.data);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <DocumentTextIcon className="h-7 w-7 mr-1.5" />
                    Create Audit Policy
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">Create a new audit policy</p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8">
                    <div>
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="title"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Title
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              disabled={loading}
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <div>
                            <label
                              htmlFor="security-tier"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Security Tier
                            </label>
                            <select
                              id="security-tier"
                              name="security-tier"
                              disabled={loading}
                              onChange={(e) => setSecurityTierId(e.target.value)}
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-500 sm:text-sm sm:leading-6"
                            >
                              {securityTiers.map((tier) => (
                                <option key={tier.id} value={tier.id}>
                                  {tier.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <div>
                            <label
                              htmlFor="type"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Type
                            </label>
                            <select
                              id="type"
                              name="type"
                              disabled={loading}
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-500 sm:text-sm sm:leading-6"
                              defaultValue={0}
                              onChange={(e) => setType(parseInt(e.target.value, 10))}
                            >
                              <option key={0} value={0}>
                                Person
                              </option>
                              <option key={1} value={1}>
                                Vehicle
                              </option>
                            </select>
                          </div>
                        </div>
                        <fieldset className="sm:col-span-4 mt-2">
                          <legend className="text-base font-semibold leading-6 text-gray-900 flex flex-row items-center">
                            <LockClosedIcon className="h-6 w-6 mr-1.5" />
                            Applicable Security Checks
                          </legend>
                          <div className="mt-4 max-h-64 overflow-y-auto divide-y divide-gray-200 border-b border-t border-gray-200">
                            {securityChecks.map((securityCheck) => (
                              <div
                                key={securityCheck.id}
                                className="relative flex items-start py-4"
                              >
                                <div className="min-w-0 flex-1 text-sm leading-6">
                                  <label
                                    htmlFor={`securityCheck-${securityCheck.id}`}
                                    className="select-none font-base text-gray-900"
                                  >
                                    {securityCheck.name}
                                  </label>
                                </div>
                                <div className="ml-3 mr-6 flex h-6 items-center">
                                  <input
                                    id={`securityCheck-${securityCheck.id}`}
                                    name={`securityCheck-${securityCheck.id}`}
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-amber-500 focus:ring-amber-500"
                                    checked={securityCheckIds.includes(securityCheck.id)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setSecurityCheckIds([
                                          ...securityCheckIds,
                                          securityCheck.id,
                                        ]);
                                      } else {
                                        setSecurityCheckIds(
                                          securityCheckIds.filter((id) => id !== securityCheck.id)
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v3/audit-policies")}
                        type="button"
                        disabled={loading}
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={() => createAuditPolicy()}
                        disabled={
                          loading ||
                          !title ||
                          !securityTierId ||
                          securityCheckIds.length === 0 ||
                          isBusy
                        }
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Create
                      </button>
                      {(!title || !securityTierId || securityCheckIds.length === 0) && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to create an audit policy
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
