import { Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  CheckIcon,
  ClipboardCheckIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV2 } from "../../../../utils/helpers/api";

export default function EditOtherASICV2() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [openDeleteOtherAsicModal, setOpenDeleteOtherAsicModal] =
    useState<boolean>(false);
  const [verifySuccess, setVerifySuccess] = useState<boolean>(false);
  const [reviewSuccess, setReviewSuccess] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [asic, setAsic] = useState<any>(null);
  const [airportCode, setAirportCode] = useState<string>("");
  const [asicId, setAsicId] = useState<string>("");
  const [asicExpiry, setAsicExpiry] = useState<string>(
    new Date().toISOString().slice(0, -14)
  );
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [company, setCompany] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAsic(params.otherAsicId!).finally(() => setLoading(false));
  }, []);

  const fetchAsic = async (asicId: string) => {
    // Get asic from database
    try {
      apiV2
        .getAsicById(asicId)
        .then((asicRes) => {
          if (asicRes.status === 200 && asicRes.data) {
            const asicData = asicRes.data;
            setAsic(asicData);
            setAirportCode(asicData.airportCode);
            setFirstName(asicData.firstName);
            setLastName(asicData.lastName);
            setCompany(asicData.company);
            setAsicId(asicData.asicId);
            setAsicExpiry(asicData.asicExpiry.substring(0, 10));
          }
        })
        .catch((error) => {
          console.error("Error occurred while getting ASIC by id - ", error);
        });
    } catch (error) {
      console.error(
        `Error occurred while initializing ASIC with id ${asicId}`,
        error
      );
    }
  };

  const verifyAsic = async () => {
    setLoading(true);
    // Get asic from database

    try {
      await apiV2.verifyOtherAsic(
        asic.id,
        asicId,
        firstName,
        lastName,
        company,
        airportCode,
        asicExpiry,
        asic.active
      );
      setVerifySuccess(true);
      setTimeout(() => setVerifySuccess(false), 3000);
      navigate("/v2/other-asics/edit/" + asicId);
      await fetchAsic(asicId);
      setLoading(false);
    } catch (error) {
      setError(true);
      console.error(
        `Error occurred while verifying ASIC with id ${asic.id}`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const reviewAsic = async () => {
    setLoading(true);
    // Get asic from database

    try {
      await apiV2.reviewOtherAsic(
        asic.id,
        asicId,
        firstName,
        lastName,
        company,
        airportCode,
        asicExpiry,
        asic.active
      );
      setReviewSuccess(true);
      setTimeout(() => setReviewSuccess(false), 3000);
      navigate("/v2/other-asics/edit/" + asicId);
      await fetchAsic(asicId);
      setLoading(false);
    } catch (error) {
      setError(true);
      console.error(
        `Error occurred while reviewing ASIC with id ${asic.id}`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteOtherAsic = async () => {
    setLoading(true);
    setOpenDeleteOtherAsicModal(false);
    try {
      await apiV2.deleteOtherAsic(asic.id);
      setDeleteSuccess(true);
      setTimeout(() => navigate("/v2/other-asics"), 3000);
    } catch (error) {
      console.error("Error occurred while deleting other ASIC - ", error);
      setError(true);
      setLoading(false);
    }
  };

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    ASIC Details{" "}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    View the details of an ASIC
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div>
                      {/* <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Profile
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          This information will be displayed publicly
                        </p>
                      </div> */}
                      {asic && (
                        <div>
                          <div className="mt-1 text-sm text-gray-900">
                            <span className="font-semibold">Status:</span>{" "}
                            {asic.status === "Verified" && (
                              <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                Verified
                              </div>
                            )}
                            {asic.status === "Review" && (
                              <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800">
                                Review
                              </div>
                            )}
                            {asic.status === "Pending" && (
                              <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                Pending
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="asicId"
                            className="block text-sm font-medium text-gray-700"
                          >
                            ASIC ID{showAsteriskIfEmpty(asicId)}
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="asicId"
                              id="asicId"
                              autoComplete="asicId"
                              disabled={loading}
                              value={asicId ?? ""}
                              onChange={(e) => setAsicId(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="asicExpiry"
                            className="block text-sm font-medium text-gray-700"
                          >
                            ASIC Expiry{showAsteriskIfEmpty(asicExpiry)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="date"
                              name="asicExpiry"
                              id="asicExpiry"
                              autoComplete="asicExpiry"
                              disabled={loading}
                              value={asicExpiry ?? ""}
                              onChange={(e) => setAsicExpiry(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First name{showAsteriskIfEmpty(firstName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              disabled={loading}
                              value={firstName ?? ""}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last name{showAsteriskIfEmpty(lastName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              disabled={loading}
                              value={lastName ?? ""}
                              onChange={(e) => setLastName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="company"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Company{showAsteriskIfEmpty(company)}
                          </label>
                          <div className="mt-1">
                            <input
                              id="company"
                              name="company"
                              type="text"
                              autoComplete="company"
                              disabled={loading}
                              value={company ?? ""}
                              onChange={(e) => setCompany(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="airportCode"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Airport Code{showAsteriskIfEmpty(airportCode)}
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="airportCode"
                              id="airportCode"
                              autoComplete="airportCode"
                              disabled={loading}
                              value={airportCode ?? ""}
                              onChange={(e) => setAirportCode(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v2/other-asics")}
                        type="button"
                        className="bg-white inline-flex flex-row items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                        Back
                      </button>
                      <button
                        onClick={() => verifyAsic()}
                        disabled={
                          loading ||
                          !asicId ||
                          !firstName ||
                          !lastName ||
                          !company
                        }
                        className="ml-3 inline-flex flex-row items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 disabled:opacity-60"
                      >
                        <CheckIcon className="h-5 w-5 mr-1.5" />
                        Verify
                      </button>
                      <button
                        onClick={() => reviewAsic()}
                        disabled={
                          loading ||
                          !asicId ||
                          !firstName ||
                          !lastName ||
                          !company
                        }
                        className="ml-3 inline-flex flex-row items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        <ClipboardCheckIcon className="h-5 w-5 mr-1.5" />
                        Review
                      </button>
                      <button
                        onClick={() => setOpenDeleteOtherAsicModal(true)}
                        disabled={loading}
                        className="ml-3 inline-flex flex-row items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60"
                      >
                        <TrashIcon className="h-5 w-5 mr-1.5" />
                        Delete
                      </button>

                      {(!asicId || !firstName || !lastName || !company) && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to update user
                        </div>
                      )}
                      <DeleteModal
                        description="Deleting an other ASIC is permanent. You will not be able to recover data once deleted"
                        open={openDeleteOtherAsicModal}
                        setOpen={setOpenDeleteOtherAsicModal}
                        title={
                          "Are you sure you want to delete this other ASIC?"
                        }
                        action={() => deleteOtherAsic()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={verifySuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully verified ASIC
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setVerifySuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={reviewSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully reviewed ASIC
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setReviewSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
