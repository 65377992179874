import { Route, Routes } from "react-router-dom";
import EditOtherASICV2 from "../../pages/V2/OtherASICsV2/OtherASICV2/OtherASICV2";
import OtherASICsV2 from "../../pages/V2/OtherASICsV2/OtherASICsV2";
import { RequireAuth } from "../../utils/auth/auth";

export default function OtherASICsV2Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <OtherASICsV2 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:otherAsicId"
        element={
          <RequireAuth>
            <EditOtherASICV2 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
