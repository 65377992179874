import { Route, Routes } from "react-router-dom";
import ASICManagementV2 from "../../pages/V2/ASICManagementV2/ASICManagementV2";
import AddASICV2 from "../../pages/V2/ASICManagementV2/AddASICV2/AddASICV2";
import EditASICV2 from "../../pages/V2/ASICManagementV2/EditASICV2/EditASICV2";
import { RequireAuth } from "../../utils/auth/auth";

export default function ASICManagementV2Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <ASICManagementV2 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddASICV2 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:asicId"
        element={
          <RequireAuth>
            <EditASICV2 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
