// ToastContext.tsx
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, XIcon } from "@heroicons/react/outline";
import React, { createContext, useContext, useState, ReactNode, Fragment } from "react";

interface ToastContextProps {
  children: ReactNode;
}

interface ToastContextValue {
  showSuccessToast: (title: string, message?: string) => void;
  showErrorToast: (title?: string, message?: string) => void;
  hideToasts: () => void;
}

const ToastContext = createContext<ToastContextValue | undefined>(undefined);

const toastrProviderTimeouts: NodeJS.Timeout[] = [];
const ToastProvider: React.FC<ToastContextProps> = ({ children }) => {
  const [successToastTitle, setSuccessToastTitle] = useState<string | null>(null);
  const [successToastMessage, setSuccessToastMessage] = useState<string | null>(null);
  const [successToastVisible, setSuccessToastVisible] = useState<boolean>(false);
  const [errorToastTitle, setErrorToastTitle] = useState<string | null>(null);
  const [errorToastMessage, setErrorToastMessage] = useState<string | null>(null);
  const [errorToastVisible, setErrorToastVisible] = useState<boolean>(false);

  const showSuccessToast = (title: string, message?: string) => {
    clearTimeouts();
    setSuccessToastTitle(title);
    setSuccessToastMessage(message || null);
    setSuccessToastVisible(true);
    toastrProviderTimeouts.push(setTimeout(hideToasts, 3000));
  };

  const showErrorToast = (title?: string, message?: string) => {
    clearTimeouts();
    setErrorToastTitle(title || "An error occurred");
    setErrorToastMessage(message || "Please try again or contact support");
    setErrorToastVisible(true);
    toastrProviderTimeouts.push(setTimeout(hideToasts, 3000));
  };

  const clearTimeouts = () => {
    toastrProviderTimeouts.forEach((to) => clearTimeout(to));
  };

  const hideToasts = () => {
    //set all values to empty and hide them
    setSuccessToastTitle(null);
    setSuccessToastMessage(null);
    setSuccessToastVisible(false);
    setErrorToastTitle(null);
    setErrorToastMessage(null);
    setErrorToastVisible(false);
    //if any timeouts are still in the array, clear them
    while (toastrProviderTimeouts.length > 0) toastrProviderTimeouts.pop();
  };

  const contextValue: ToastContextValue = {
    showSuccessToast,
    showErrorToast,
    hideToasts,
  };

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={successToastVisible}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{successToastTitle}</p>
                    <p className="mt-1 text-sm text-gray-500">{successToastMessage}</p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        hideToasts();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={errorToastVisible}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{errorToastTitle}</p>
                    <p className="mt-1 text-sm text-gray-500">{errorToastMessage}</p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        hideToasts();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </ToastContext.Provider>
  );
};

const useToast = (): ToastContextValue => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export { ToastProvider, useToast };
