import { SearchIcon } from "@heroicons/react/outline";
import { useState } from "react";

interface SearchOption {
  value: string;
  name: string;
}

interface SearchFormV3Props {
  searchOptions: SearchOption[];
  disabled?: boolean;
  updateSearchQueryAndField: (searchQuery: string, searchField: string) => void;
}

export default function SearchFormV3(props: SearchFormV3Props) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>(
    props.searchOptions[0].value
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.updateSearchQueryAndField(searchQuery, searchField);
      }}
      className="grid grid-cols-1 gap-y-1 gap-x-1 sm:grid-cols-6 items-center rounded-lg overflow-hidden mb-4 justify-between max-w-2xl"
    >
      <input
        className="col-span-1 sm:col-span-2 text-gray-900 text-sm bg-white flex-grow b-none px-3 border-gray-300 focus:ring-amber-500 focus:border-amber-500 rounded-lg"
        type="text"
        placeholder="Search (by field)"
        onChange={(e) => setSearchQuery(e.target.value)}
        disabled={props.disabled}
      />
      <select
        onChange={(e) => setSearchField(e.target.value)}
        className="col-span-1 sm:col-span-2 text-sm text-gray-500 outline-none border-gray-300 px-4 pr-8 py-2 rounded-lg focus:ring-amber-500 focus:border-amber-500"
      >
        {props.searchOptions.map((o) => {
          return (
            <option key={o.value} value={o.value}>
              {o.name}
            </option>
          );
        })}
      </select>
      <button
        type="submit"
        disabled={props.disabled}
        className="col-span-1 sm:col-span-1 flex sm:inline-flex flex-row items-center bg-stone-600 hover:bg-stone-700 disabled:opacity-60 disabled:hover:bg-stone-600 text-white text-sm font-medium rounded-lg px-4 py-2"
      >
        <SearchIcon className="h-5 w-5 mr-1.5" />
        Search
      </button>
    </form>
  );
}
