import { Switch } from "@headlessui/react";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { classNames } from "../../../../utils/helpers/misc";
import { apiV3 } from "../../../../utils/helpers/api";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function AddAEVLocationV3() {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [fixedLocation, setFixedLocation] = useState<boolean>(false);
  const [fixedLocationReportingLatitude, setFixedLocationReportingLatitude] = useState<number>(0.0);
  const [fixedLocationReportingLongitude, setFixedLocationReportingLongitude] =
    useState<number>(0.0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addLocation = async () => {
    setLoading(true);
    try {
      await apiV3.createLocation({
        name,
        fixedLocation,
        fixedLocationReportingLatitude,
        fixedLocationReportingLongitude,
        active: true,
      });
      showSuccessToast("Successfully created location", "");
      navigate("/v3/aev-locations");
    } catch (error) {
      console.error("Error occurred while creating location - ", error);
      showErrorToast("An error occurred", "Please try again or contact support");
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <LocationMarkerIcon className="h-6 w-6 mr-1.5" />
                    Add AEV Location
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">Create a new AEV location</p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8">
                    <div>
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Name
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              disabled={loading}
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <Switch.Group as="div" className="flex items-center">
                            <Switch
                              checked={fixedLocation}
                              onChange={setFixedLocation}
                              className={classNames(
                                fixedLocation ? "bg-indigo-600" : "bg-gray-200",
                                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                              )}
                            >
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  fixedLocation ? "translate-x-5" : "translate-x-0",
                                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                )}
                              />
                            </Switch>
                            <Switch.Label as="span" className="ml-3 text-sm">
                              <span className="font-medium text-gray-900">Fixed Location</span>
                            </Switch.Label>
                          </Switch.Group>
                        </div>

                        {fixedLocation && (
                          <>
                            <div className="sm:col-span-4">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Fixed Location Reporting Latitude
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  type="number"
                                  min={-90}
                                  max={90}
                                  step={0.0000000000000001}
                                  name="fixedLocationReportingLatitude"
                                  id="fixedLocationReportingLatitude"
                                  autoComplete="fixedLocationReportingLatitude"
                                  disabled={loading}
                                  value={fixedLocationReportingLatitude}
                                  onChange={(e) =>
                                    setFixedLocationReportingLatitude(parseFloat(e.target.value))
                                  }
                                  className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-4">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Fixed Location Reporting Longitude
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  type="number"
                                  step={0.0000000000000001}
                                  min={-180}
                                  max={180}
                                  name="fixedLocationReportingLongitude"
                                  id="fixedLocationReportingLongitude"
                                  autoComplete="fixedLocationReportingLongitude"
                                  disabled={loading}
                                  value={fixedLocationReportingLongitude}
                                  onChange={(e) =>
                                    setFixedLocationReportingLongitude(parseFloat(e.target.value))
                                  }
                                  className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v3/aev-locations")}
                        type="button"
                        disabled={loading}
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={() => addLocation()}
                        disabled={loading || !name}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Create
                      </button>
                      {!name && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to create an location
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
