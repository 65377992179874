import { Route, Routes } from "react-router-dom";
import AddSelfServeLocationV3 from "../../pages/V3/SelfServeLocationsV3/AddSelfServeLocationV3/AddSelfServeLocationV3";
import EditSelfServeLocationV3 from "../../pages/V3/SelfServeLocationsV3/EditSelfServeLocationV3/EditSelfServeLocationV3";
import SelfServeLocationsV3 from "../../pages/V3/SelfServeLocationsV3/SelfServeLocationsV3";
import { RequireAuth } from "../../utils/auth/auth";

export default function SelfServeLocationsV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <SelfServeLocationsV3 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddSelfServeLocationV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:selfServeLocationId"
        element={
          <RequireAuth>
            <EditSelfServeLocationV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
