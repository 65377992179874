import { Route, Routes } from "react-router-dom";
import AddASICV3 from "../../pages/V3/ASICManagementV3/AddASICV3/AddASICV3";
import EditASICV3 from "../../pages/V3/ASICManagementV3/EditASICV3/EditASICV3";
import { RequireAuth } from "../../utils/auth/auth";
import NewASICManagementV3 from "../../pages/V3/NewASICManagementV3/NewASICManagementV3";

export default function NewASICManagementV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <NewASICManagementV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
