import { addDays, sub, subMonths, subYears } from "date-fns";
import { useEffect, useState } from "react";

interface DateRangePickerProps {
  disabled?: boolean;
  updateDates: (newStartDate: string, newEndDate: string) => void;
  defaultStartDate?: string;
  defaultEndDate?: string;
}

export default function DateRangePicker(props: DateRangePickerProps) {
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(true);

  const [startDate, setStartDate] = useState<string>(
    props.defaultStartDate ?? subMonths(new Date(), 1).toISOString().slice(0, -8)
  );
  const [endDate, setEndDate] = useState<string>(
    props.defaultEndDate ?? addDays(new Date(), 1).toISOString().slice(0, -8)
  );

  useEffect(() => {
    if (!shouldSubmit) return;
    props.updateDates(startDate, endDate);
    setShouldSubmit(false);
  }, [startDate, endDate, shouldSubmit]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.updateDates(startDate, endDate);
      }}
      className="grid items-center justify-between max-w-2xl grid-cols-1 mb-2 overflow-hidden rounded-lg gap-y-1 gap-x-1 sm:grid-cols-6"
    >
      <div className="col-span-2">
        <label htmlFor="start-date" className="block text-sm font-medium text-left text-gray-700">
          Start Date
        </label>
        <div className="mt-1">
          <input
            type="datetime-local"
            name="start-date"
            id="start-date"
            autoComplete="start-date"
            disabled={props.disabled}
            value={startDate ?? ""}
            onChange={(e) => setStartDate(e.target.value)}
            onBlur={() => setShouldSubmit(true)}
            className="block w-full text-gray-600 border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
          />
        </div>
      </div>

      <div className="col-span-2">
        <label htmlFor="end-date" className="block text-sm font-medium text-left text-gray-700">
          End Date
        </label>
        <div className="mt-1">
          <input
            type="datetime-local"
            name="end-date"
            id="end-date"
            autoComplete="end-date"
            disabled={props.disabled}
            value={endDate ?? ""}
            onChange={(e) => setEndDate(e.target.value)}
            onBlur={() => setShouldSubmit(true)}
            className="block w-full text-gray-600 border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
          />
        </div>
      </div>
    </form>
  );
}
