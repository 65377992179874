import { addHours, addSeconds } from "date-fns";
import React, { useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { apiV2, apiV3 } from "../helpers/api";

interface ErrorObject {
  code: string;
  message: string;
}
interface AuthContextType {
  user: any;
  v2Token: string;
  v2TokenExpiry: Date | null;
  v3Token: string;
  v3TokenExpiry: Date | null;
  loading: boolean;
  signin: (username: string, password: string) => Promise<ErrorObject | void>;
  signout: (callback?: VoidFunction) => void;
  updateV2Token: (newToken: string) => void;
  updateV3Token: (newToken: string) => void;
  updateUser: (newUser?: any) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [v2Token, setV2Token] = React.useState<string>(
    localStorage.getItem("v2Token") ?? ""
  );
  const [v2TokenExpiry, setV2TokenExpiry] = React.useState<Date | null>(
    localStorage.getItem("v2TokenExpiry")
      ? new Date(localStorage.getItem("v2TokenExpiry")!)
      : null
  );
  const [v3Token, setV3Token] = React.useState<string>(
    localStorage.getItem("v3Token") ?? ""
  );
  const [v3TokenExpiry, setV3TokenExpiry] = React.useState<Date | null>(
    localStorage.getItem("v3TokenExpiry")
      ? new Date(localStorage.getItem("v3TokenExpiry")!)
      : null
  );
  const [user, setUser] = React.useState<any>(
    JSON.parse(localStorage.getItem("currentUser") || "null")
  );
  const [loading, setLoading] = useState<boolean>(false);

  const updateV2Token = (newToken: string) => {
    setV2Token(newToken);
    localStorage.setItem("v2Token", newToken);
  };

  const updateV2TokenExpiry = (newTokenExpiry: Date) => {
    setV2TokenExpiry(newTokenExpiry);
    localStorage.setItem("v2TokenExpiry", newTokenExpiry.toISOString());
  };

  const updateV3Token = (newToken: string) => {
    setV3Token(newToken);
    localStorage.setItem("v3Token", newToken);
  };

  const updateV3TokenExpiry = (newTokenExpiry: Date) => {
    setV3TokenExpiry(newTokenExpiry);
    localStorage.setItem("v3TokenExpiry", newTokenExpiry.toISOString());
  };

  const updateUser = (newUser?: any) => {
    setLoading(true);
    if (newUser) {
      setUser(newUser);
      localStorage.setItem("currentUser", JSON.stringify(newUser));
    }
    setLoading(false);
  };

  const signin = async (
    username: string,
    password: string
  ): Promise<void | ErrorObject> => {
    setLoading(true);
    try {
      // Try logging in to V2 API
      const v2LoginRes = await signInToV2(username, password);

      // If successful, check if user in V3 API (via log in)
      try {
        await signInToV3(username, password);
      } catch (error) {
        // If user doesn't exist in V3 API, create user in V3 API
        await apiV3.createUser(
          {
            username: username,
            email: v2LoginRes.user.email,
            password: password,
            firstName: v2LoginRes.user.firstName,
            lastName: v2LoginRes.user.lastName,
            active: true,
          },
          v2LoginRes.token
        );

        await signInToV3(username, password);
      }

      return;
    } catch (error) {
      // Try logging in to V3 API
      return await signInToV3(username, password);
    } finally {
      setLoading(false);
    }
  };

  const signInToV2 = async (
    username: string,
    password: string
  ): Promise<any> => {
    try {
      // Try logging in to V2 API
      const res = await apiV2.login(username, password);
      if (res.data.accessToken && res.data.expiresIn) {
        // Update v2 tokens
        const newTokenExpiry = addSeconds(new Date(), res.data.expiresIn);
        updateV2Token(res.data.accessToken);
        updateV2TokenExpiry(newTokenExpiry);
        apiV2.updateClient(res.data.accessToken, newTokenExpiry);

        // Fetch user info
        const userPrelimInfo = await apiV2.getMyAdminUserInfo();
        const userInfo = await apiV2.getAdminUserById(userPrelimInfo.data.id);
        updateUser(userInfo.data);

        return {
          token: res.data.accessToken,
          expiry: newTokenExpiry,
          user: userInfo.data,
        };
      } else {
        throw new Error("Invalid login user response from V2 API");
      }
    } catch (error) {
      throw error;
    }
  };

  const signInToV3 = async (
    username: string,
    password: string
  ): Promise<void | ErrorObject> => {
    try {
      // Try logging in to V3 API
      const res = await apiV3.loginUser(username, password);
      if (res.data.token && res.data.user) {
        // Update v3 tokens
        const newTokenExpiry = addHours(new Date(), 24);
        updateV3Token(res.data.token);
        updateV3TokenExpiry(newTokenExpiry);
        apiV3.updateClient(res.data.token, newTokenExpiry);

        // Update user
        updateUser(res.data.user);
      } else {
        throw new Error("Invalid login user response from V3 API");
      }
    } catch (error) {
      console.error("Error logging in to V3 API: ", error);
      throw error;
    }
  };

  const signout = (callback?: VoidFunction) => {
    setUser(null);

    // Remove V2 Tokens
    setV2Token("");
    setV2TokenExpiry(null);
    localStorage.removeItem("v2Token");
    localStorage.removeItem("v2TokenExpiry");

    // Remove V3 Tokens
    setV3Token("");
    setV3TokenExpiry(null);
    localStorage.removeItem("v3Token");
    localStorage.removeItem("v3TokenExpiry");

    localStorage.removeItem("currentUser");

    if (callback) {
      callback();
    }
  };

  const memoedValue = useMemo(
    () => ({
      user,
      v2Token,
      v2TokenExpiry,
      v3Token,
      v3TokenExpiry,
      loading,
      signin,
      signout,
      updateV2Token,
      updateV3Token,
      updateUser,
    }),
    [v2Token, v3Token, loading]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const auth = useAuth();

  if (!auth.v2Token && !auth.v3Token) {
    return (
      <Navigate to="/signin" replace state={{ from: location.pathname }} />
    );
  }

  return children;
}
