import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../utils/auth/auth";
import AddSecurityTierV3 from "../../pages/V3/SecurityTiersV3/AddSecurityTierV3/AddSecurityTierV3";
import EditSecurityTierV3 from "../../pages/V3/SecurityTiersV3/EditSecurityTierV3/EditSecurityTierV3";
import SecurityTiersV3 from "../../pages/V3/SecurityTiersV3/SecurityTiersV3";

export default function SecurityTiersV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <SecurityTiersV3 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddSecurityTierV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:securityTierId"
        element={
          <RequireAuth>
            <EditSecurityTierV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
