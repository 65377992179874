import { Route, Routes } from "react-router-dom";
import SecuritySubmissionV2 from "../../pages/V2/SecuritySubmissionsV2/SecuritySubmissionV2/SecuritySubmissionV2";
import SecuritySubmissionsV2 from "../../pages/V2/SecuritySubmissionsV2/SecuritySubmissionsV2";
import { RequireAuth } from "../../utils/auth/auth";

export default function SecuritySubmissionsV2Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <SecuritySubmissionsV2 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:submissionId"
        element={
          <RequireAuth>
            <SecuritySubmissionV2 />
          </RequireAuth>
        }
      />{" "}
    </Routes>
  );
}
