import { useEffect, useState } from "react";

interface PageInitializationProps {
  isInitiated: boolean;
}

export const usePageInitialization = (): PageInitializationProps => {
  const [isInitiated, setIsInitiated] = useState(false);

  useEffect(() => {
    if (!isInitiated) {
      setIsInitiated(true);
    }
  }, [isInitiated]);

  return { isInitiated };
};
