import { useEffect } from "react";
import { useAuth } from "../utils/auth/auth";
import { apiV3 } from "../utils/helpers/api";

export const useAuthToken = () => {
  const auth = useAuth();
  useEffect(() => {
    if (!auth.loading && auth.user) {
      apiV3.updateClient(auth.v3Token, auth.v3TokenExpiry);
    }
  }, [auth.user]);
};
