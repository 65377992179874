import { Route, Routes } from "react-router-dom";
import VehicleAuditV2 from "../../pages/V2/VehicleAuditsV2/VehicleAuditV2/VehicleAuditV2";
import VehicleAuditsV2 from "../../pages/V2/VehicleAuditsV2/VehicleAuditsV2";
import { RequireAuth } from "../../utils/auth/auth";

export default function VehicleAuditsV2Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <VehicleAuditsV2 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:vehicleAuditId"
        element={
          <RequireAuth>
            <VehicleAuditV2 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
