import { Route, Routes } from "react-router-dom";
import AddAuditPolicyV3 from "../../pages/V3/AuditPoliciesV3/AddAuditPolicyV3/AddAuditPolicyV3";
import AuditPoliciesV3 from "../../pages/V3/AuditPoliciesV3/AuditPoliciesV3";
import EditAuditPolicyV3 from "../../pages/V3/AuditPoliciesV3/EditAuditPolicyV3/EditAuditPolicyV3";
import { RequireAuth } from "../../utils/auth/auth";

export default function AuditPoliciesV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <AuditPoliciesV3 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddAuditPolicyV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:auditPolicyId"
        element={
          <RequireAuth>
            <EditAuditPolicyV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
