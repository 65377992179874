import { Route, Routes } from "react-router-dom";
import ASICManagementV3 from "../../pages/V3/ASICManagementV3/ASICManagementV3";
import AddASICV3 from "../../pages/V3/ASICManagementV3/AddASICV3/AddASICV3";
import EditASICV3 from "../../pages/V3/ASICManagementV3/EditASICV3/EditASICV3";
import { RequireAuth } from "../../utils/auth/auth";

export default function ASICManagementV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <ASICManagementV3 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddASICV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:asicId"
        element={
          <RequireAuth>
            <EditASICV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
