import {
  ArrowLeftIcon,
  ArrowSmRightIcon,
  BanIcon,
  CheckIcon,
  LocationMarkerIcon,
  LockClosedIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function EditAEVLocationV3() {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [loadingRandomAuditPolicy, setLoadingRandomAuditPolicy] = useState<boolean>(false);
  const [loadingRandomVehicleSecurityChecks, setLoadingRandomVehicleSecurityChecks] =
    useState<boolean>(false);
  const [randomAuditPolicy, setRandomAuditPolicy] = useState<any>(null);
  const [randomVehicleSecurityChecks, setRandomVehicleSecurityChecks] = useState<any[]>([]);
  const [location, setLocation] = useState<any>(null);
  const [name, setName] = useState<string>("");
  const [hasVehicles, setHasVehicles] = useState<string>("false");
  const [openDisableLocationModal, setOpenDisableLocationModal] = useState<boolean>(false);
  const [openDeleteLocationModal, setOpenDeleteLocationModal] = useState<boolean>(false);
  const [openDeleteAuditConfigurationModal, setOpenDeleteAuditConfigurationModal] =
    useState<boolean>(false);
  const [openDeleteLaneModal, setOpenDeleteLaneModal] = useState<boolean>(false);
  const [locationDeletable, setLocationDeletable] = useState<boolean>(false);
  const [laneIdToDelete, setLaneIdToDelete] = useState<string>("");
  const [newLaneName, setNewLaneName] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    // Get location from database
    const locationId = params.locationId;
    if (locationId) {
      fetchLocation(locationId);
    } else {
      showErrorToast();
    }
  }, []);

  const fetchLocation = async (locationId: string) => {
    apiV3
      .getLocationById(locationId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          const location = res.data;
          setLocation(location);
          setName(location.name);
          setHasVehicles(location.hasVehicles ? "true" : "false");
          setLocationDeletable(location.locationLanes.length === 0);
        }
      })
      .catch((error) => {
        console.error(`Error occurred while fetching location with id ${locationId}`, error);
      })
      .finally(() => setLoading(false));
  };

  const updateLocation = async () => {
    setIsBusy(true);
    try {
      if (params.locationId) {
        await apiV3.updateLocationById(params.locationId, {
          name,
          active: location.active,
          hasVehicles: hasVehicles === "true",
          fixedLocation: location.fixedLocation,
          fixedLocationReportingLatitude: location.fixedLocationReportingLatitude,
          fixedLocationReportingLongitude: location.fixedLocationReportingLongitude,
        });
        showSuccessToast("Successfully updated location");
      } else {
        console.error(
          "Error occurred while updating location - couldn't find the location's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while updating location - ", error);
      showErrorToast();
    }
    setIsBusy(false);
  };

  const enableLocation = async () => {
    setIsBusy(true);
    try {
      if (params.locationId) {
        await apiV3.updateLocationById(params.locationId, {
          name: location.name,
          hasVehicles: location.hasVehicles,
          active: true,
          fixedLocation: location.fixedLocation,
          fixedLocationReportingLatitude: location.fixedLocationReportingLatitude,
          fixedLocationReportingLongitude: location.fixedLocationReportingLongitude,
        });
        showSuccessToast("Successfully enabled location");
        await fetchLocation(params.locationId);
      } else {
        console.error(
          "Error occurred while enabling location - couldn't find the location's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while enabling location - ", error);
      showErrorToast();
    } finally {
      setIsBusy(false);
    }
  };

  const disableLocation = async () => {
    setIsBusy(true);
    setOpenDisableLocationModal(false);
    try {
      if (params.locationId) {
        await apiV3.updateLocationById(params.locationId, {
          name: location.name,
          hasVehicles: location.hasVehicles,
          active: false,
          fixedLocation: location.fixedLocation,
          fixedLocationReportingLatitude: location.fixedLocationReportingLatitude,
          fixedLocationReportingLongitude: location.fixedLocationReportingLongitude,
        });
        showSuccessToast("Successfully disabled location");
        await fetchLocation(params.locationId);
      } else {
        console.error(
          "Error occurred while disabling location - couldn't find the location's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while disabling location - ", error);
      showErrorToast();
    } finally {
      setIsBusy(false);
    }
  };

  const deleteLocation = async () => {
    setIsBusy(true);
    setOpenDeleteLocationModal(false);
    try {
      if (params.locationId) {
        await apiV3.deleteLocationById(params.locationId);
        showSuccessToast("Successfully deleted location");
        navigate("/v3/aev-locations");
      } else {
        console.error(
          "Error occurred while deleting location - couldn't find the location's id from the URL parameters"
        );
        showErrorToast();
        setIsBusy(false);
      }
    } catch (error) {
      console.error("Error occurred while deleting location - ", error);
      showErrorToast();
      setIsBusy(false);
    }
  };

  const deleteLocationAuditConfiguration = async () => {
    setIsBusy(true);
    setOpenDeleteAuditConfigurationModal(false);
    try {
      if (
        location &&
        location.hasOwnProperty("locationAuditConfiguration") &&
        location.locationAuditConfiguration
      ) {
        await apiV3.deleteLocationAuditConfigurationById(location.locationAuditConfiguration.id);
        showSuccessToast("Successfully delete location audit configuration");
        fetchLocation(location.id);
      } else {
        console.error("Error occurred while deleting location audit configuration - null value");
        showErrorToast();
      }
      setIsBusy(false);
    } catch (error) {
      console.error("Error occurred while deleting location audit configuration - ", error);
      showErrorToast();
      setIsBusy(false);
    }
  };

  const fetchRandomAuditPolicy = async () => {
    setLoadingRandomAuditPolicy(true);
    if (
      location &&
      location.hasOwnProperty("locationAuditConfiguration") &&
      location.locationAuditConfiguration
    ) {
      const res = await apiV3.getRandomLocationAuditConfigurationAuditPolicyByLocationId(
        location.id
      );
      setRandomAuditPolicy(res.data);
    } else {
      console.error("Error occurred while fetching random audit policy - null value");
      showErrorToast();
    }
    setLoadingRandomAuditPolicy(false);
  };

  const fetchRandomVehicleSecurityChecks = async () => {
    setLoadingRandomVehicleSecurityChecks(true);
    try {
      const res = await apiV3.getRandomVehicleSecurityChecks();
      setRandomVehicleSecurityChecks(res.data.data);
    } catch (error) {
      console.error("Error occurred while fetching random vehicle security checks - ", error);
      showErrorToast();
    }

    setLoadingRandomVehicleSecurityChecks(false);
  };

  const addLane = async () => {
    try {
      setIsBusy(true);
      if (params.locationId) {
        await apiV3.createLocationLane(params.locationId, newLaneName);
        showSuccessToast("Successfully added new lane");
        setNewLaneName("");
        await fetchLocation(params.locationId);
      } else {
        console.error(
          "Error occurred while adding lane - couldn't find the location's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while adding lane - ", error);
      showErrorToast();
    } finally {
      setIsBusy(false);
    }
  };

  const deleteLane = async (laneId: string) => {
    setIsBusy(true);
    setOpenDeleteLaneModal(false);
    try {
      if (params.locationId) {
        await apiV3.deleteLocationLaneById(params.locationId!, laneId);
        showSuccessToast("Successfully deleted lane");
        setLaneIdToDelete("");
        await fetchLocation(params.locationId);
      } else {
        console.error(
          "Error occurred while deleting lane - couldn't find the location's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while deleting lane - ", error);
      showErrorToast();
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    Edit AEV Location {name && <em className="ml-1">({name})</em>}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Edit the name, status and lanes of an AEV location or delete it from the system
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              {!loading && location ? (
                <div className="mt-8 flex flex-col max-w-screen-md">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200">
                      <div className="text-sm">
                        <div>
                          <span className="font-semibold">Status: </span>
                          {location && location.hasOwnProperty("active") && (
                            <span>
                              {location.active ? (
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                  Active
                                </div>
                              ) : (
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                  Inactive
                                </div>
                              )}
                            </span>
                          )}
                        </div>
                        <div>
                          <span className="font-semibold">Created At: </span>
                          <span className="">{new Date(location.createdAt).toDateString()}</span>
                        </div>
                      </div>
                      <div className="pt-4">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-4">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Name
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                autoComplete="name"
                                disabled={loading || isBusy}
                                value={name ?? ""}
                                onChange={(e) => setName(e.target.value)}
                                className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-4">
                            <label
                              htmlFor="default-logout-time"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Has Vehicles
                            </label>
                            <select
                              id="default-logout-time"
                              name="default-logout-time"
                              disabled={loading}
                              onChange={(e) => setHasVehicles(e.target.value)}
                              value={hasVehicles}
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-500 sm:text-sm sm:leading-6"
                            >
                              {[
                                { value: "true", name: "Yes" },
                                { value: "false", name: "No" },
                              ].map((logoutTime) => (
                                <option key={logoutTime.value} value={logoutTime.value}>
                                  {logoutTime.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="flex justify-start items-center">
                            <button
                              onClick={() => navigate("/v3/aev-locations")}
                              type="button"
                              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => updateLocation()}
                              disabled={loading || !name || isBusy}
                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                            >
                              Save
                            </button>
                            {!name && (
                              <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                                Please enter all the required fields to update location
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Related Information
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            View information associated with this location
                          </p>
                        </div>
                        <div className="mt-6">
                          <h4 className="text-md leading-6 font-medium text-gray-900">Lanes</h4>
                          {location &&
                          location.hasOwnProperty("locationLanes") &&
                          location.locationLanes.length === 0 ? (
                            <div className="mt-1 text-sm text-gray-500">
                              No lanes for this location.
                            </div>
                          ) : (
                            <div className="my-3 grid grid-cols-1 gap-4 sm:grid-cols-4">
                              <table className="min-w-full divide-y divide-gray-300">
                                <tbody className="divide-y divide-gray-200">
                                  {location.locationLanes.map((lane: any) => (
                                    <tr key={lane.id}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                        {lane.name}
                                      </td>
                                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                        <a
                                          onClick={() => {
                                            setOpenDeleteLaneModal(true);
                                            setLaneIdToDelete(lane.id);
                                          }}
                                          className="text-red-600 hover:text-red-900 cursor-pointer"
                                        >
                                          Delete
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                          <div className="mt-6">
                            <div>
                              <label
                                htmlFor="lane-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Add Lane
                              </label>
                              <div className="mt-2 flex rounded-md shadow-sm">
                                <div className="relative flex focus-within:z-10">
                                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <LocationMarkerIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <input
                                    type="text"
                                    name="lane-name"
                                    id="lane-name"
                                    value={newLaneName}
                                    onChange={(e) => setNewLaneName(e.target.value)}
                                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                    placeholder="Lane Name"
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-green-600 bg-green-600 hover:bg-green-700 disabled:opacity-60"
                                  onClick={() => addLane()}
                                  disabled={!newLaneName || isBusy}
                                >
                                  <PlusIcon
                                    className="-ml-0.5 h-5 w-5 text-white"
                                    aria-hidden="true"
                                  />
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-6">
                          <h4 className="text-md leading-6 font-medium text-gray-900">
                            Location Audit Configuration
                          </h4>
                          {location &&
                          location.hasOwnProperty("locationAuditConfiguration") &&
                          !location.locationAuditConfiguration ? (
                            <>
                              <div className="mt-1 text-sm text-gray-500">
                                No audit configuration for this location.
                              </div>
                              <div className="mt-6">
                                <button
                                  type="button"
                                  disabled={loading}
                                  onClick={() => navigate("create-audit-configuration")}
                                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                                >
                                  <PlusIcon className="h-4 w-4 mr-1" />
                                  Create Audit Configuration
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="text-sm text-gray-600 mb-4 italic">
                                To modify this audit configuration, please delete it and create a
                                new one.
                              </div>
                              <div className="my-3 space-y-2 text-sm bg-gray-800 rounded-md p-3 text-white">
                                <div className="">
                                  <span className="font-semibold">
                                    Required Scan{" "}
                                    <span className="text-gray-500">
                                      (initial scans estimated per day){": "}
                                    </span>
                                  </span>
                                  <span>{location.locationAuditConfiguration.requiredScan}</span>
                                </div>
                                <div className="">
                                  <span className="font-semibold">
                                    Extension Amount{" "}
                                    <span className="text-gray-500">
                                      (the number of scans to extend by or add to the daily count)
                                      {": "}
                                    </span>
                                  </span>
                                  <span>{location.locationAuditConfiguration.extensionAmount}</span>
                                </div>
                                <div className="">
                                  <span className="font-semibold">
                                    Extension Threshold{" "}
                                    <span className="text-gray-500">
                                      (the remaining amount of scans prior to extending amount)
                                      {": "}
                                    </span>
                                  </span>
                                  <span>
                                    {location.locationAuditConfiguration.extensionThreshold}
                                  </span>
                                </div>
                                <div>
                                  <div className="font-semibold mb-2">Location Audit Policies</div>
                                  <div className="space-y-2 divide-y divide-gray-200 text-xs">
                                    {location.locationAuditConfiguration.locationAuditConfigurationAuditPolicies.map(
                                      (lap: any) => {
                                        return (
                                          <div className="py-2">
                                            <div className="font-semibold">
                                              {lap.auditPolicy.title}
                                            </div>
                                            <div className="text-amber-500">{lap.percentage}%</div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="mt-6">
                                <button
                                  type="button"
                                  disabled={isBusy}
                                  onClick={() => setOpenDeleteAuditConfigurationModal(true)}
                                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                                >
                                  <TrashIcon className="h-4 w-4 mr-1" />
                                  Delete Audit Configuration
                                </button>
                              </div>
                              <div className="mt-3">
                                <button
                                  type="button"
                                  disabled={loading || loadingRandomAuditPolicy || isBusy}
                                  onClick={() => fetchRandomAuditPolicy()}
                                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50"
                                >
                                  {loadingRandomAuditPolicy ? (
                                    <div role="status">
                                      <svg
                                        aria-hidden="true"
                                        className="w-4 h-4 mr-2 text-gray-200 animate-spin fill-amber-600"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                          fill="currentFill"
                                        />
                                      </svg>
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  ) : (
                                    <ArrowSmRightIcon className="h-4 w-4 mr-1" />
                                  )}
                                  Fetch Random Audit Policy
                                </button>
                              </div>
                              {randomAuditPolicy && (
                                <div className="p-3 mt-6 rounded-md bg-gray-800 text-white">
                                  <div className="font-semibold">Random Audit Policy</div>
                                  <div className="space-y-2 divide-y divide-gray-200 text-xs">
                                    <div className="py-2">
                                      <div className="my-0.5 font-semibold">
                                        Title -{" "}
                                        <span className="font-normal">
                                          {randomAuditPolicy.title}
                                        </span>
                                      </div>
                                      <div className="my-0.5 font-semibold">
                                        Security Tier Level -{" "}
                                        <span className="font-normal">
                                          Tier {randomAuditPolicy.securityTier.level}
                                        </span>
                                      </div>
                                      <div className="my-0.5 font-semibold">
                                        Type -{" "}
                                        <span className="font-normal">
                                          {randomAuditPolicy.type === 0 ? "Person" : "Vehicle"}
                                        </span>
                                      </div>
                                      <fieldset className="sm:col-span-4 mt-2">
                                        <legend className="text-md font-semibold leading-6 text-gray-100 flex flex-row items-center">
                                          <LockClosedIcon className="h-4 w-4 mr-1.5" />
                                          Security Checks
                                        </legend>
                                        <div className="mt-4 max-h-64 overflow-y-auto divide-y divide-gray-200 border-b border-t border-gray-200">
                                          {randomAuditPolicy.auditPolicySecurityChecks.map(
                                            (auditPolicySecurityCheck: any) => (
                                              <div
                                                key={auditPolicySecurityCheck.securityCheck.id}
                                                className="relative flex items-start py-4"
                                              >
                                                <div className="min-w-0 flex-1 text-sm leading-6">
                                                  <label
                                                    htmlFor={`securityCheck-${auditPolicySecurityCheck.securityCheck.id}`}
                                                    className="select-none font-base text-gray-100"
                                                  >
                                                    {auditPolicySecurityCheck.securityCheck.name}
                                                  </label>
                                                </div>
                                                <div className="ml-3 mr-6 flex h-6 items-center">
                                                  <input
                                                    id={`securityCheck-${auditPolicySecurityCheck.securityCheck.id}`}
                                                    name={`securityCheck-${auditPolicySecurityCheck.securityCheck.id}`}
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-amber-500 focus:ring-amber-500"
                                                    checked
                                                  />
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </fieldset>
                                      {/* <div>{randomAuditPolicy.percentage}%</div> */}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {hasVehicles === "true" && (
                                <>
                                  <div className="mt-3">
                                    <button
                                      type="button"
                                      disabled={loading || loadingRandomVehicleSecurityChecks}
                                      onClick={() => fetchRandomVehicleSecurityChecks()}
                                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50"
                                    >
                                      {loadingRandomVehicleSecurityChecks ? (
                                        <div role="status">
                                          <svg
                                            aria-hidden="true"
                                            className="w-4 h-4 mr-2 text-gray-200 animate-spin fill-amber-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                              fill="currentFill"
                                            />
                                          </svg>
                                          <span className="sr-only">Loading...</span>
                                        </div>
                                      ) : (
                                        <ArrowSmRightIcon className="h-4 w-4 mr-1" />
                                      )}
                                      Fetch Random Vehicle Security Checks
                                    </button>
                                  </div>
                                  {randomVehicleSecurityChecks &&
                                    randomVehicleSecurityChecks.length > 0 && (
                                      <div className="p-3 mt-6 rounded-md bg-gray-800 text-white">
                                        <div className="font-semibold">
                                          Random Vehicle Security Checks
                                        </div>
                                        <div className="space-y-2 divide-y divide-gray-200 text-xs">
                                          <div className="py-2">
                                            <fieldset className="sm:col-span-4 mt-2">
                                              <legend className="text-md font-semibold leading-6 text-gray-100 flex flex-row items-center">
                                                <LockClosedIcon className="h-4 w-4 mr-1.5" />
                                                Security Checks
                                              </legend>
                                              <div className="mt-4 max-h-64 overflow-y-auto divide-y divide-gray-200 border-b border-t border-gray-200">
                                                {randomVehicleSecurityChecks.map(
                                                  (securityCheck: any) => (
                                                    <div
                                                      key={securityCheck.id}
                                                      className="relative flex items-start py-4"
                                                    >
                                                      <div className="min-w-0 flex-1 text-sm leading-6">
                                                        <label
                                                          htmlFor={`securityCheck-${securityCheck.id}`}
                                                          className="select-none font-base text-gray-100"
                                                        >
                                                          {securityCheck.name}
                                                        </label>
                                                      </div>
                                                      <div className="ml-3 mr-6 flex h-6 items-center">
                                                        <input
                                                          id={`securityCheck-${securityCheck.id}`}
                                                          name={`securityCheck-${securityCheck.id}`}
                                                          type="checkbox"
                                                          className="h-4 w-4 rounded border-gray-300 text-amber-500 focus:ring-amber-500"
                                                          checked
                                                        />
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </fieldset>
                                            {/* <div>{randomAuditPolicy.percentage}%</div> */}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      {location && location.hasOwnProperty("active") && location.active ? (
                        <div className="pt-8">
                          <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Disable Location
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                              Disable a location so it cannot be used in AEV
                            </p>
                          </div>
                          <div className="mt-6">
                            <button
                              type="button"
                              disabled={loading || isBusy}
                              onClick={() => setOpenDisableLocationModal(true)}
                              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                            >
                              <BanIcon className="h-4 w-4 mr-1" />
                              Disable Location
                            </button>
                          </div>
                          <DeleteModal
                            description="Disabling an location will prevent any future visitor passes from being created for the location"
                            open={openDisableLocationModal}
                            setOpen={setOpenDisableLocationModal}
                            title={"Are you sure you want to disable this location?"}
                            action={() => disableLocation()}
                            actionButtonText={"Disable"}
                          />
                        </div>
                      ) : (
                        <div className="pt-8">
                          <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Enable Location
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                              Enable a location so it can be used in AEV
                            </p>
                          </div>
                          <div className="mt-6">
                            <button
                              type="button"
                              disabled={loading || isBusy}
                              onClick={() => enableLocation()}
                              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                            >
                              <CheckIcon className="h-4 w-4 mr-1" />
                              Enable Location
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="py-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Delete Location
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Permanently delete a location from the AEV database
                          </p>
                        </div>
                        <div className="mt-6">
                          <button
                            type="button"
                            disabled={loading || !locationDeletable}
                            onClick={() => setOpenDeleteLocationModal(true)}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800 disabled:opacity-50"
                          >
                            <TrashIcon className="h-4 w-4 mr-1" />
                            Delete Location
                          </button>
                          {!locationDeletable && (
                            <div className="mt-4 text-red-500 text-sm font-semibold border-none">
                              Please delete all lanes before deleting a location
                            </div>
                          )}
                        </div>
                        <DeleteModal
                          description="Deleting a location is permanent. It will be erased from AEV databases."
                          open={openDeleteLocationModal}
                          setOpen={setOpenDeleteLocationModal}
                          title={"Are you sure you want to delete this location?"}
                          action={() => deleteLocation()}
                          actionButtonText={"Delete"}
                        />
                        <DeleteModal
                          description="Deleting a lane is permanent. This lane will no longer belong to this location and will be erased from AEV databases."
                          open={openDeleteLaneModal}
                          setOpen={setOpenDeleteLaneModal}
                          title={"Are you sure you want to delete this lane?"}
                          action={() => deleteLane(laneIdToDelete)}
                          actionButtonText={"Delete"}
                        />
                        <DeleteModal
                          description="Deleting a location audit configuration is permanent. This audit configuration will no longer belong to this location and will be erased from AEV databases."
                          open={openDeleteAuditConfigurationModal}
                          setOpen={setOpenDeleteAuditConfigurationModal}
                          title={"Are you sure you want to delete this configuration?"}
                          action={() => deleteLocationAuditConfiguration()}
                          actionButtonText={"Delete"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
