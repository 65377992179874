import { Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV2 } from "../../../../utils/helpers/api";

export default function EditASICV2() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [asic, setAsic] = useState<any>(null);
  const [asicId, setAsicId] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [adminBlocked, setAdminBlocked] = useState<boolean>(true);
  const [adminRole, setAdminRole] = useState<boolean>(false);
  const [airportAdminRole, setAirportAdminRole] = useState<boolean>(false);
  const [airportOfficerRole, setAirportOfficerRole] = useState<boolean>(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] =
    useState<boolean>(false);
  const [airportId, setAirportId] = useState<string>("");
  const [airportCode, setAirportCode] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    initializeAsic().finally(() => setLoading(false));
  }, []);

  const initializeAsic = async () => {
    // Get asic from database
    const asicId = params.asicId;

    try {
      if (asicId) {
        apiV2.getAsicById(asicId).then((asicRes) => {
          if (asicRes.status === 200 && asicRes.data) {
            const asicData = asicRes.data;
            setAsic(asicData);
            setFirstName(asicData.firstName);
            setLastName(asicData.lastName);
            setCompany(asicData.company);
            setAsicId(asicData.asicId);
          }
        });
      } else {
        setError(true);
      }
    } catch (error) {
      console.error(
        `Error occurred while initializing ASIC with id ${asicId}`,
        error
      );
    }
  };

  const updateUser = async () => {
    setLoading(true);
    try {
      if (params.userId) {
        // const updatedUser = {
        //   username,
        //   email,
        //   firstName,
        //   lastName,
        //   id: params.userId,
        //   roles: createRolesList(
        //     adminRole,
        //     airportAdminRole,
        //     airportOfficerRole
        //   ),
        //   airportId: airportId,
        // } as UpdateUserRequest;

        // if (newPassword) updatedUser.password = newPassword;

        // await apiV2.updateUserById(params.userId, updatedUser);
        setSuccess(true);
      } else {
        console.error(
          "Error occurred while updating user - couldn't find the user's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while updating user - ", error);
      setError(true);
    }
    setLoading(false);
  };

  const deleteUser = async () => {
    setLoading(true);
    setOpenDeleteUserModal(false);
    try {
      if (params.userId) {
        // await apiV2.deleteUser(params.userId);
        setDeleteSuccess(true);
        setTimeout(() => navigate("/v2/user-management"), 3000);
      } else {
        console.error(
          "Error occurred while deleting user - couldn't find the user's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while deleting user - ", error);
      setError(true);
      setLoading(false);
    }
  };

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    ASIC Details{" "}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    View the details of an ASIC
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div>
                      {/* <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Profile
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          This information will be displayed publicly
                        </p>
                      </div> */}
                      {asic && (
                        <div>
                          <p className="mt-1 text-sm text-gray-900">
                            <span className="font-semibold">Status:</span>{" "}
                            {asic.status === "ACTIVE" && (
                              <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                Active
                              </div>
                            )}
                            {asic.status === "WITHDRAWN" && (
                              <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                Withdrawn
                              </div>
                            )}
                            {asic.status === "DELETED" && (
                              <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                Deleted
                              </div>
                            )}
                            {asic.status === "SUSPENDED" && (
                              <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800">
                                Suspended
                              </div>
                            )}
                            {asic.status === "LOST" && (
                              <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                Lost
                              </div>
                            )}
                          </p>
                          <p className="mt-1 text-sm">
                            <span className="font-semibold">ASIC Expiry:</span>{" "}
                            {new Date(asic.expires).toDateString()}
                          </p>
                          <p className="mt-1 text-sm">
                            <span className="font-semibold">Airport Code:</span>{" "}
                            {asic.airportCode}
                          </p>
                        </div>
                      )}

                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="asicId"
                            className="block text-sm font-medium text-gray-700"
                          >
                            ASIC ID{showAsteriskIfEmpty(asicId)}
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="asicId"
                              id="asicId"
                              autoComplete="asicId"
                              disabled
                              value={asicId ?? ""}
                              onChange={(e) => setAsicId(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First name{showAsteriskIfEmpty(firstName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              disabled
                              value={firstName ?? ""}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last name{showAsteriskIfEmpty(lastName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              disabled
                              value={lastName ?? ""}
                              onChange={(e) => setLastName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-4">
                          <label
                            htmlFor="company"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Company{showAsteriskIfEmpty(company)}
                          </label>
                          <div className="mt-1">
                            <input
                              id="company"
                              name="company"
                              type="text"
                              autoComplete="company"
                              disabled
                              value={company ?? ""}
                              onChange={(e) => setCompany(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="pt-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Roles
                          {showAsteriskIfEmpty(
                            adminRole || airportAdminRole || airportOfficerRole
                              ? "not empty"
                              : ""
                          )}
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Control user permissions
                        </p>
                      </div>
                      <div className="pt-2">
                        <fieldset>
                          <div className="mt-4 space-y-4">
                            {!adminBlocked && (
                              <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                  <input
                                    id="admin"
                                    name="admin"
                                    type="checkbox"
                                    disabled={loading || deleteSuccess}
                                    checked={adminRole}
                                    onChange={(e) =>
                                      setAdminRole(e.target.checked)
                                    }
                                    className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded"
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="admin"
                                    className="font-medium text-gray-700"
                                  >
                                    Admin
                                  </label>
                                </div>
                              </div>
                            )}

                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="airportAdmin"
                                  name="airportAdmin"
                                  type="checkbox"
                                  disabled={
                                    loading ||
                                    deleteSuccess ||
                                    airportOfficerRole
                                  }
                                  checked={airportAdminRole}
                                  onChange={(e) =>
                                    setAirportAdminRole(e.target.checked)
                                  }
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="airportAdmin"
                                  className="font-medium text-gray-700"
                                >
                                  Airport Admin
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="airportOfficer"
                                  name="airportOfficer"
                                  type="checkbox"
                                  disabled={
                                    loading || deleteSuccess || airportAdminRole
                                  }
                                  checked={airportOfficerRole}
                                  onChange={(e) =>
                                    setAirportOfficerRole(e.target.checked)
                                  }
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded disabled:opacity-50"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="airportOfficer"
                                  className="font-medium text-gray-700"
                                >
                                  Airport Officer
                                </label>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div> */}

                    {/* <div className="pt-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Delete User
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Delete a user and all associated data permanently
                        </p>
                      </div>
                      <div className="mt-6">
                        <button
                          type="button"
                          onClick={() => setOpenDeleteUserModal(true)}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <TrashIcon className="h-4 w-4 mr-1" />
                          Delete User
                        </button>
                      </div>
                      <DeleteModal
                        description="Deleting a user is permanent. You will not be able to recover data once deleted"
                        open={openDeleteUserModal}
                        setOpen={setOpenDeleteUserModal}
                        title={"Are you sure you want to delete this user?"}
                        action={() => deleteUser()}
                      />
                    </div> */}
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v2/asic-management")}
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Back
                      </button>
                      {/* <button
                        onClick={() => updateUser()}
                        disabled={
                          loading ||
                          !username ||
                          !firstName ||
                          !lastName ||
                          !email ||
                          (!adminRole &&
                            !airportAdminRole &&
                            !airportOfficerRole)
                        }
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Save
                      </button>
                      {(!username ||
                        !firstName ||
                        !lastName ||
                        !email ||
                        (!adminRole &&
                          !airportAdminRole &&
                          !airportOfficerRole)) && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to update user
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={success}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully updated user
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={deleteSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully deleted user
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Returning to user management portal...
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setDeleteSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
