import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";

interface PageNumbersProps {
  disabled?: boolean;
  totalCount: number;
  rowsPerPage: number;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  newPageNumber: number;
  setNewPageNumber: (newPageNumber: number) => void;
}

export default function PageNumbers(props: PageNumbersProps) {
  const createPageNumbers = () => {
    const pageNumbers = [];
    let pageCountAbbreviated = false;
    let dotsPushed = false;
    if (Math.ceil(props.totalCount / props.rowsPerPage) > 5) {
      pageCountAbbreviated = true;
    }
    for (let i = 1; i <= Math.ceil(props.totalCount / props.rowsPerPage); i++) {
      if (
        pageCountAbbreviated &&
        ((i > props.pageNumber + 1 &&
          i <= Math.ceil(props.totalCount / props.rowsPerPage) - 2) ||
          (props.pageNumber >=
            Math.ceil(props.totalCount / props.rowsPerPage) - 3 &&
            i <= Math.ceil(props.totalCount / props.rowsPerPage) - 2))
      ) {
        if (!dotsPushed) {
          pageNumbers.push(
            <div key="dots">
              <input
                type="number"
                name="newPageNumber"
                className="block w-10 h-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6 disabled:opacity-60"
                placeholder="..."
                value={props.newPageNumber === 0 ? "" : props.newPageNumber}
                onChange={(e) =>
                  props.setNewPageNumber(parseInt(e.target.value))
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (props.newPageNumber) {
                      if (
                        props.newPageNumber >
                        Math.ceil(props.totalCount / props.rowsPerPage)
                      ) {
                        props.setPageNumber(
                          Math.ceil(props.totalCount / props.rowsPerPage)
                        );
                      } else {
                        props.setPageNumber(props.newPageNumber);
                      }
                    } else {
                      props.setPageNumber(1);
                    }
                    props.setNewPageNumber(0);
                  }
                }}
              />
            </div>
          );
          dotsPushed = true;
        } else if (
          (props.pageNumber ===
            Math.ceil(props.totalCount / props.rowsPerPage) - 3 ||
            props.pageNumber ===
              Math.ceil(props.totalCount / props.rowsPerPage) - 2) &&
          (i === Math.ceil(props.totalCount / props.rowsPerPage) - 3 ||
            i === Math.ceil(props.totalCount / props.rowsPerPage) - 2)
        ) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => props.setPageNumber(i)}
              aria-current="page"
              disabled={props.disabled}
              className={
                props.pageNumber === i
                  ? "z-10 bg-amber-50 border-amber-500 text-amber-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer disabled:opacity-60"
                  : "border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer disabled:opacity-60"
              }
            >
              {i}
            </button>
          );
        }
      } else if (i >= props.pageNumber - 1) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => props.setPageNumber(i)}
            aria-current="page"
            disabled={props.disabled}
            className={
              props.pageNumber === i
                ? "z-10 bg-amber-50 border-amber-500 text-amber-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer disabled:opacity-60"
                : "border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer disabled:opacity-60"
            }
          >
            {i}
          </button>
        );
      }
    }
    return pageNumbers;
  };

  return (
    <div>
      <nav
        className="bg-white relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
        aria-label="Pagination"
      >
        <button
          onClick={() => props.setPageNumber(1)}
          disabled={props.pageNumber === 1 || props.disabled}
          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white"
          key="double-left"
        >
          <span className="sr-only">Previous</span>
          <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          key="left"
          onClick={() => props.setPageNumber(props.pageNumber - 1)}
          disabled={props.pageNumber === 1 || props.disabled}
          className="relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white"
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        {createPageNumbers()}
        <button
          key="right"
          onClick={() => props.setPageNumber(props.pageNumber + 1)}
          disabled={
            props.rowsPerPage * props.pageNumber > props.totalCount ||
            props.disabled
          }
          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white"
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          key="double-right"
          onClick={() =>
            props.setPageNumber(Math.ceil(props.totalCount / props.rowsPerPage))
          }
          disabled={
            props.rowsPerPage * props.pageNumber > props.totalCount ||
            props.disabled
          }
          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white"
        >
          <span className="sr-only">Next</span>
          <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </nav>
    </div>
  );
}
