import { Route, Routes } from "react-router-dom";
import AEVLocationsV2 from "../../pages/V2/AEVLocationsV2/AEVLocationsV2";
import AddAEVLocationV2 from "../../pages/V2/AEVLocationsV2/AddAEVLocationV2/AddAEVLocationV2";
import EditAEVLocationV2 from "../../pages/V2/AEVLocationsV2/EditAEVLocationV2/EditAEVLocationV2";
import { RequireAuth } from "../../utils/auth/auth";

export default function AEVLocationsV2Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <AEVLocationsV2 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddAEVLocationV2 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:locationId"
        element={
          <RequireAuth>
            <EditAEVLocationV2 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
