import { Route, Routes } from "react-router-dom";
import AddUserV3 from "../../pages/V3/UserManagementV3/AddUserV3/AddUserV3";
import EditUserV3 from "../../pages/V3/UserManagementV3/EditUserV3/EditUserV3";
import UserManagementV3 from "../../pages/V3/UserManagementV3/UserManagementV3";
import { RequireAuth } from "../../utils/auth/auth";

export default function UserManagementV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <UserManagementV3 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddUserV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:userId"
        element={
          <RequireAuth>
            <EditUserV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
