import { Route, Routes } from "react-router-dom";
import ASICImportV3 from "../../pages/V3/ASICImportV3/ASICImportV3";
import ImportASICV3 from "../../pages/V3/ASICImportV3/ImportASICV3/ImportASICV3";
import ImportedASICV3 from "../../pages/V3/ASICImportV3/ImportedASICV3/ImportedASICV3";
import { RequireAuth } from "../../utils/auth/auth";

export default function ASICImportV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <ASICImportV3 />
          </RequireAuth>
        }
      />
      <Route
        path="import"
        element={
          <RequireAuth>
            <ImportASICV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:asicImportId"
        element={
          <RequireAuth>
            <ImportedASICV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
