import { Route, Routes } from "react-router-dom";
import ReportV2 from "../../pages/V2/ReportsV2/ReportV2/ReportV2";
import ReportsV2 from "../../pages/V2/ReportsV2/ReportsV2";
import { RequireAuth } from "../../utils/auth/auth";

export default function ReportsV2Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <ReportsV2 />
          </RequireAuth>
        }
      />
      <Route
        path=":reportId"
        element={
          <RequireAuth>
            <ReportV2 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
