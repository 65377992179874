import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../utils/auth/auth";
import APIKeys from "../../pages/V3/Security/APIKeys/APIKeys";
import AddAPIKey from "../../pages/V3/Security/APIKeys/AddAPIKey/AddAPIKey";
import EditAPIKey from "../../pages/V3/Security/APIKeys/EditAPIKey/EditAPIKey";

export default function APIKeysV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <APIKeys />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddAPIKey />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:apiKeyId"
        element={
          <RequireAuth>
            <EditAPIKey />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
