import { ArrowLeftIcon, CheckIcon, ClipboardCheckIcon, TrashIcon } from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function EditOtherASICV3() {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [openDeleteOtherAsicModal, setOpenDeleteOtherAsicModal] = useState<boolean>(false);
  const [asic, setAsic] = useState<any>(null);
  const [airportCode, setAirportCode] = useState<string>("");
  const [asicId, setAsicId] = useState<string>("");
  const [asicExpiry, setAsicExpiry] = useState<string>(new Date().toISOString().slice(0, -14));
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [company, setCompany] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAsic(params.asicId!).finally(() => setLoading(false));
  }, []);

  const fetchAsic = async (asicId: string) => {
    // Get asic from database
    try {
      apiV3
        .getAsicByAsicId(asicId)
        .then((asicRes) => {
          if (asicRes.status === 200 && asicRes.data) {
            const asicData = asicRes.data;
            setAsic(asicData);
            setAirportCode(asicData.airportCode);
            setFirstName(asicData.firstName);
            setLastName(asicData.lastName);
            setCompany(asicData.company);
            setAsicId(asicData.asicId);
            setAsicExpiry(asicData.asicExpiryDate.substring(0, 10));
          }
        })
        .catch((error) => {
          console.error("Error occurred while getting ASIC by id - ", error);
          showErrorToast();
        });
    } catch (error) {
      console.error(`Error occurred while initializing ASIC with id ${asicId}`, error);
      showErrorToast();
    }
  };

  const verifyAsic = async () => {
    // Get asic from database

    try {
      setIsBusy(true);
      await apiV3.verifyManualAsic(
        asic.id,
        asicId,
        firstName,
        lastName,
        company,
        airportCode,
        asicExpiry,
        asic.active
      );
      showSuccessToast("Successfully verified ASIC");
      await fetchAsic(asicId);
      setLoading(false);
    } catch (error) {
      showErrorToast();
      console.error(`Error occurred while verifying ASIC with id ${asic.id}`, error);
    } finally {
      setIsBusy(false);
    }
  };

  const reviewAsic = async () => {
    try {
      setIsBusy(true);
      await apiV3.reviewManualAsic(
        asic.id,
        asicId,
        firstName,
        lastName,
        company,
        airportCode,
        asicExpiry,
        asic.active
      );
      showSuccessToast("Successfully reviewed ASIC");
      await fetchAsic(asicId);
    } catch (error) {
      showErrorToast();
      console.error(`Error occurred while reviewing ASIC with id ${asic.id}`, error);
    } finally {
      setIsBusy(false);
    }
  };

  const deleteOtherAsic = async () => {
    setIsBusy(true);
    setOpenDeleteOtherAsicModal(false);
    try {
      await apiV3.deleteManualAsic(asic.id);
      showSuccessToast("Successfully deleted ASIC");
      setIsBusy(false);
      navigate("/v3/asic-management");
    } catch (error) {
      console.error("Error occurred while deleting other ASIC - ", error);
      showErrorToast();
      setIsBusy(false);
    }
  };

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    ASIC Details{" "}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">View the details of an ASIC</p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div>
                      {/* <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Profile
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          This information will be displayed publicly
                        </p>
                      </div> */}
                      {asic ? (
                        <>
                          <div>
                            <div className="mt-1 text-sm text-gray-900">
                              <span className="font-semibold">Status:</span>{" "}
                              {asic.source === 3 && asic.manualEntryStatus ? (
                                <>
                                  {asic.manualEntryStatus === 1 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                      Pending
                                    </div>
                                  )}
                                  {asic.manualEntryStatus === 2 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                      Verified
                                    </div>
                                  )}
                                  {asic.manualEntryStatus === 3 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800">
                                      Review
                                    </div>
                                  )}
                                  {asic.manualEntryStatus === 0 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                      Unknown
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {asic.status === 1 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                      Active
                                    </div>
                                  )}
                                  {asic.status === 2 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                      Withdrawn
                                    </div>
                                  )}
                                  {asic.status === 3 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                      Deleted
                                    </div>
                                  )}
                                  {asic.status === 4 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800">
                                      Suspended
                                    </div>
                                  )}
                                  {asic.status === 5 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                      Lost
                                    </div>
                                  )}
                                  {asic.status === 0 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                      Unknown
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                            <div className="mt-1 text-sm text-gray-900">
                              <span className="font-semibold">Source:</span>{" "}
                              {(asic.source === 1 || asic.source === 2) && (
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                  Imported
                                </div>
                              )}
                              {asic.source === 3 && (
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                  Manual Entry
                                </div>
                              )}
                              {asic.source === 4 && (
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                                  Security Submission
                                </div>
                              )}
                              {asic.source === 0 && (
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                  Unknown
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="asicId"
                                className="block text-sm font-medium text-gray-700"
                              >
                                ASIC ID{showAsteriskIfEmpty(asicId)}
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  type="text"
                                  name="asicId"
                                  id="asicId"
                                  autoComplete="asicId"
                                  disabled={loading || asic?.source !== 3}
                                  value={asicId ?? ""}
                                  onChange={(e) => setAsicId(e.target.value)}
                                  className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="asicExpiry"
                                className="block text-sm font-medium text-gray-700"
                              >
                                ASIC Expiry{showAsteriskIfEmpty(asicExpiry)}
                              </label>
                              <div className="mt-1">
                                <input
                                  type="date"
                                  name="asicExpiry"
                                  id="asicExpiry"
                                  autoComplete="asicExpiry"
                                  disabled={loading || asic?.source !== 3}
                                  value={asicExpiry ?? ""}
                                  onChange={(e) => setAsicExpiry(e.target.value)}
                                  className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="first-name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                First name{showAsteriskIfEmpty(firstName)}
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="first-name"
                                  id="first-name"
                                  autoComplete="given-name"
                                  disabled={loading || asic?.source !== 3}
                                  value={firstName ?? ""}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="last-name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Last name{showAsteriskIfEmpty(lastName)}
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="last-name"
                                  id="last-name"
                                  autoComplete="family-name"
                                  disabled={loading || asic?.source !== 3}
                                  value={lastName ?? ""}
                                  onChange={(e) => setLastName(e.target.value)}
                                  className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="company"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Company{showAsteriskIfEmpty(company)}
                              </label>
                              <div className="mt-1">
                                <input
                                  id="company"
                                  name="company"
                                  type="text"
                                  autoComplete="company"
                                  disabled={loading || asic?.source !== 3}
                                  value={company ?? ""}
                                  onChange={(e) => setCompany(e.target.value)}
                                  className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="airportCode"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Airport Code{showAsteriskIfEmpty(airportCode)}
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  type="text"
                                  name="airportCode"
                                  id="airportCode"
                                  autoComplete="airportCode"
                                  disabled={loading || asic?.source !== 3}
                                  value={airportCode ?? ""}
                                  onChange={(e) => setAirportCode(e.target.value)}
                                  className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="pt-5">
                            <div className="flex justify-start items-center">
                              <button
                                onClick={() => navigate("/v3/asic-management")}
                                type="button"
                                className="bg-white inline-flex flex-row items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                              >
                                <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                                Back
                              </button>
                              {asic.source === 3 && (
                                <>
                                  <button
                                    onClick={() => verifyAsic()}
                                    disabled={
                                      loading ||
                                      !asicId ||
                                      !firstName ||
                                      !lastName ||
                                      !company ||
                                      isBusy
                                    }
                                    className="ml-3 inline-flex flex-row items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 disabled:opacity-60"
                                  >
                                    <CheckIcon className="h-5 w-5 mr-1.5" />
                                    Verify
                                  </button>
                                  <button
                                    onClick={() => reviewAsic()}
                                    disabled={
                                      loading ||
                                      !asicId ||
                                      !firstName ||
                                      !lastName ||
                                      !company ||
                                      isBusy
                                    }
                                    className="ml-3 inline-flex flex-row items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                                  >
                                    <ClipboardCheckIcon className="h-5 w-5 mr-1.5" />
                                    Review
                                  </button>
                                  <button
                                    onClick={() => setOpenDeleteOtherAsicModal(true)}
                                    disabled={loading || isBusy}
                                    className="ml-3 inline-flex flex-row items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60"
                                  >
                                    <TrashIcon className="h-5 w-5 mr-1.5" />
                                    Delete
                                  </button>

                                  {(!asicId || !firstName || !lastName || !company) && (
                                    <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                                      Please enter all the required fields to update ASIC
                                    </div>
                                  )}
                                  <DeleteModal
                                    description="Deleting an ASIC is permanent. You will not be able to recover data once deleted"
                                    open={openDeleteOtherAsicModal}
                                    setOpen={setOpenDeleteOtherAsicModal}
                                    title={"Are you sure you want to delete this ASIC?"}
                                    action={() => deleteOtherAsic()}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
