import {
  ChevronLeftIcon,
  ChevronRightIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import { DocumentReportIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import SortButton from "../../../components/SortButton/SortButton";
import { useAuth } from "../../../utils/auth/auth";
import { apiV2 } from "../../../utils/helpers/api";

export default function ReportsV2() {
  const navigate = useNavigate();
  const params = useParams();
  const auth = useAuth();
  const [visitorPassRequests, setVisitorPassRequests] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("DESC");
  const [sortField, setSortField] = useState<string>("createdAt");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("");

  useEffect(() => {
    if (!auth.loading && auth.user) {
      apiV2.updateClient(auth.v2Token, auth.v2TokenExpiry);
      fetchVisitorPassRequests();
    }
  }, [auth.user]);

  useEffect(() => {
    fetchVisitorPassRequests();
  }, [pageNumber, searchQuery, searchField, sortOrder, sortField]);

  const fetchVisitorPassRequests = async () => {
    // setLoading(true);
    // try {
    //   let vpRequests: AxiosResponse<any, any>;
    //   if (params.visitorId) {
    //     vpRequests = await apiV2.getVisitorPassRequestsForVisitor(
    //       pageNumber,
    //       rowsPerPage,
    //       searchQuery,
    //       searchField,
    //       sortOrder,
    //       sortField,
    //       params.visitorId
    //     );
    //   } else if (params.airportId) {
    //     // vpRequests = await apiV2.getVisitorPassRequestsForAirport(
    //     //   pageNumber,
    //     //   rowsPerPage,
    //     //   searchQuery,
    //     //   searchField,
    //     //   sortOrder,
    //     //   sortField,
    //     //   params.airportId
    //     // );
    //   } else {
    //     // vpRequests = await apiV2.getVisitorPassRequests(
    //     //   pageNumber,
    //     //   rowsPerPage,
    //     //   searchQuery,
    //     //   searchField,
    //     //   sortOrder,
    //     //   sortField
    //     // );
    //   }
    //   // if (vpRequests.status === 200 && vpRequests.data.data) {
    //   //   setTotalCount(vpRequests.data.totalCount);
    //   //   setVisitorPassRequests(vpRequests.data.data);
    //   // }
    // } catch (error) {
    //   console.error(
    //     `Error occurred while fetching ${rowsPerPage} visitorPassRequests of page ${pageNumber} - `,
    //     error
    //   );
    // }
    // setLoading(false);
  };

  const createPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalCount / rowsPerPage); i++) {
      pageNumbers.push(
        <button
          onClick={() => setPageNumber(i)}
          aria-current="page"
          disabled={loading}
          className={
            pageNumber === i
              ? "z-10 bg-amber-50 border-amber-500 text-amber-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
              : "border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
          }
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const updateSortCriteria = (newSortField: string) => {
    setSortField(newSortField);
    if (newSortField === sortField) {
      if (sortOrder === "DESC") setSortOrder("ASC");
      else setSortField("");
    } else {
      setSortOrder("DESC");
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <DocumentReportIcon className="h-6 w-6 mr-1.5" />
                    Reports
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the reports in AEV including their details.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none items-center flex flex-row">
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => fetchVisitorPassRequests()}
                    className="sm:ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-stone-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 sm:w-auto disabled:hover:bg-stone-600"
                  >
                    <RefreshIcon className="h-5 w-5 mr-1.5" />
                    Refresh
                  </button>
                </div>
              </div>
              <div className="mt-4 sm:mt-8 flex flex-col">
                {/* <SearchForm
                  searchOptions={[
                    { name: "Email", value: "email" },
                    { name: "Company", value: "company" },
                    { name: "First Name", value: "firstName" },
                    { name: "Last Name", value: "lastName" },
                  ]}
                  updateSearchQueryAndField={(
                    newSearchQuery,
                    newSearchField
                  ) => {
                    setSearchQuery(newSearchQuery);
                    setSearchField(newSearchField);
                  }}
                /> */}
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Created At
                                <SortButton
                                  active={sortField === "createdAt"}
                                  sortOrder={sortOrder}
                                  sortField={"createdAt"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Email
                                <SortButton
                                  active={sortField === "email"}
                                  sortOrder={sortOrder}
                                  sortField={"email"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Company
                                <SortButton
                                  active={sortField === "company"}
                                  sortOrder={sortOrder}
                                  sortField={"company"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                First Name
                                <SortButton
                                  active={sortField === "firstName"}
                                  sortOrder={sortOrder}
                                  sortField={"firstName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Last Name
                                <SortButton
                                  active={sortField === "lastName"}
                                  sortOrder={sortOrder}
                                  sortField={"lastName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>

                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Status
                                <SortButton
                                  active={sortField === "status"}
                                  sortOrder={sortOrder}
                                  sortField={"status"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {visitorPassRequests.map((vpRequest) => (
                            <tr
                              key={vpRequest.id}
                              className="hover:bg-slate-50 cursor-pointer"
                              onClick={() =>
                                navigate(
                                  "/v2/visitor-pass-requests/" + vpRequest.id
                                )
                              }
                            >
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {new Date(vpRequest.createdAt).toDateString()}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {vpRequest.email}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {vpRequest.company}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {vpRequest.firstName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {vpRequest.lastName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {vpRequest.status === 1 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                    Requested
                                  </div>
                                )}
                                {vpRequest.status === 2 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Approved
                                  </div>
                                )}
                                {vpRequest.status === 3 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Rejected
                                  </div>
                                )}
                                {vpRequest.status === 4 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Expired
                                  </div>
                                )}
                                {vpRequest.status === 5 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-200 text-red-900">
                                    Cancelled
                                  </div>
                                )}
                                {vpRequest.status === 6 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                    Printed
                                  </div>
                                )}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <div className="text-amber-600 hover:text-amber-900">
                                  Manage
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="py-3 flex items-center justify-between border-t border-gray-200">
                      <div className="flex-1 flex justify-between sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50"
                        >
                          Previous
                        </a>
                        <a
                          href="#"
                          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50"
                        >
                          Next
                        </a>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="flex flex-row items-center">
                          {loading ? (
                            <p className="text-sm text-gray-700">Loading...</p>
                          ) : totalCount > 0 ? (
                            <>
                              {/* <p className="text-sm text-gray-700">
                                Showing{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber - rowsPerPage + 1}
                                </span>{" "}
                                to{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber > totalCount
                                    ? totalCount
                                    : rowsPerPage * pageNumber}
                                </span>{" "}
                                of{" "}
                                <span className="font-medium">
                                  {totalCount}
                                </span>{" "}
                                results
                              </p> */}
                              <p className="text-sm text-gray-700">
                                Showing {totalCount} results.
                              </p>
                            </>
                          ) : (
                            <p className="text-sm text-gray-700">
                              Showing 0 results.
                            </p>
                          )}
                          <div className="text-sm text-gray-700 inline-flex flex-row items-center ml-1.5">
                            <div>Results per page: </div>
                            <div>
                              <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                className="h-8 ml-1.5 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-600 text-sm sm:leading-6"
                                value={rowsPerPage}
                                onChange={(e) =>
                                  setRowsPerPage(parseInt(e.target.value))
                                }
                                disabled={loading}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div>
                          <nav
                            className="bg-white relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                            aria-label="Pagination"
                          >
                            <button
                              onClick={() => setPageNumber(pageNumber - 1)}
                              disabled={pageNumber === 1 || loading}
                              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white"
                            >
                              <span className="sr-only">Previous</span>
                              <ChevronLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                            {/* {createPageNumbers()} */}
                            <button
                              onClick={() => setPageNumber(pageNumber + 1)}
                              disabled={totalCount < rowsPerPage || loading}
                              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white"
                            >
                              <span className="sr-only">Next</span>
                              <ChevronRightIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
