import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../utils/auth/auth";
import NewASICImportV3 from "../../pages/V3/NewASICImportV3/NewASICImportV3";
import ImportNewASICV3 from "../../pages/V3/NewASICImportV3/ImportNewASICV3/ImportNewASICV3";
import ImportedNewASICV3 from "../../pages/V3/NewASICImportV3/ImportedNewASICV3/ImportedNewASICV3";

export default function NewASICImportV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <NewASICImportV3 />
          </RequireAuth>
        }
      />
      <Route
        path="import"
        element={
          <RequireAuth>
            <ImportNewASICV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:asicImportId"
        element={
          <RequireAuth>
            <ImportedNewASICV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
