import { MinusIcon, PlusIcon, SearchIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { SearchFilter } from "../../utils/types";

interface SearchOption {
  value: string;
  name: string;
}

interface SearchFormV2Props {
  searchOptions: SearchOption[];
  disabled?: boolean;
  updateSearchFilters: (searchFilters: any[]) => void;
}

export default function SearchFormV2(props: SearchFormV2Props) {
  const [filterCount, setFilterCount] = useState<number>(1);
  const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([
    {
      id: 1,
      searchField: props.searchOptions[0].value,
      searchQuery: "",
    },
  ]);

  const addFilter = () => {
    let newSearchField = props.searchOptions[0].value;
    for (const field of props.searchOptions) {
      if (!searchFieldInUse(field.value)) {
        newSearchField = field.value;
        break;
      }
    }
    setSearchFilters([
      ...searchFilters,
      {
        id: filterCount + 1,
        searchField: newSearchField,
        searchQuery: "",
      },
    ]);
    setFilterCount(filterCount + 1);
  };

  const removeFilter = (id: number) => {
    const filteredSearchFilters = searchFilters.filter((element) => {
      return element.id !== id;
    });
    setSearchFilters(filteredSearchFilters);
    setFilterCount(filterCount - 1);
  };

  const updateFilterSearchQuery = (id: number, newSearchQuery: string) => {
    const searchFilterIndex = searchFilters.findIndex(
      (filter) => filter.id === id
    );

    searchFilters[searchFilterIndex].searchQuery = newSearchQuery;
    setSearchFilters([...searchFilters]);
  };

  const updateFilterSearchField = (id: number, newSearchField: string) => {
    const searchFilterIndex = searchFilters.findIndex(
      (filter) => filter.id === id
    );

    searchFilters[searchFilterIndex].searchField = newSearchField;
    setSearchFilters([...searchFilters]);
  };

  const searchFieldInUse = (searchField: string) => {
    for (const filter of searchFilters) {
      if (filter.searchField === searchField) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="max-w-lg mb-4">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          props.updateSearchFilters(searchFilters);
        }}
      >
        {searchFilters.map((filter) => {
          return (
            <div
              key={filter.id}
              className="sm:flex flex-col sm:flex-row items-left sm:items-center rounded-lg overflow-hidden mb-2 justify-between max-w-lg space-y-1 sm:space-x-2"
            >
              <input
                className="text-gray-900 text-sm bg-white flex-grow b-none px-3 border-gray-300 focus:ring-stone-600 focus:border-stone-600 rounded-lg"
                type="text"
                placeholder="Search (by field)"
                onChange={(e) =>
                  updateFilterSearchQuery(filter.id, e.target.value)
                }
                value={filter.searchQuery}
                disabled={props.disabled}
              />
              <select
                onChange={(e) =>
                  updateFilterSearchField(filter.id, e.target.value)
                }
                value={filter.searchField}
                className="text-sm text-gray-500 outline-none border-gray-300 px-4 pr-8 py-2 rounded-lg focus:ring-stone-600 focus:border-stone-600"
              >
                {props.searchOptions.map((o) => {
                  return (
                    <option
                      disabled={searchFieldInUse(o.value)}
                      value={o.value}
                    >
                      {o.name}
                    </option>
                  );
                })}
              </select>
              {filter.id !== 1 && (
                <button
                  disabled={props.disabled}
                  onClick={() => removeFilter(filter.id)}
                  className="flex sm:inline-flex flex-row items-center bg-red-700 hover:bg-red-800 disabled:opacity-60 disabled:hover:bg-red-700 text-white text-sm font-medium rounded-lg px-4 py-2"
                >
                  <MinusIcon className="h-5 w-5 mr-1.5" />
                  Remove
                </button>
              )}
            </div>
          );
        })}

        <div className="space-y-1 sm:space-x-2">
          <button
            type="submit"
            disabled={props.disabled}
            className="flex sm:inline-flex flex-row items-center bg-stone-600 hover:bg-stone-700 disabled:opacity-60 disabled:hover:bg-stone-600 text-white text-sm font-medium rounded-lg px-4 py-2"
          >
            <SearchIcon className="h-5 w-5 mr-1.5" />
            Search
          </button>
          <button
            disabled={
              props.disabled ||
              searchFilters.length === props.searchOptions.length
            }
            onClick={() => addFilter()}
            className="flex sm:inline-flex flex-row items-center bg-yellow-500 hover:bg-yellow-600 disabled:opacity-60 disabled:hover:bg-yellow-700 text-white text-sm font-medium rounded-lg px-4 py-2"
          >
            <PlusIcon className="h-5 w-5 mr-1.5" />
            Add Filter
          </button>
        </div>
      </form>
    </div>
  );
}
