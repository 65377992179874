import { Route, Routes } from "react-router-dom";
import AEVLocationsV3Router from "./AEVLocationsV3Router";
import APIKeysV3Router from "./APIKeysV3Router";
import ASICImportV3Router from "./ASICImportV3Router";
import ASICManagementV3Router from "./ASICManagementV3Router";
import AirportManagementV3Router from "./AirportManagementV3Router";
import AuditPoliciesV3Router from "./AuditPoliciesV3Router";
import NotesV3Router from "./NotesV3Router";
import PersonAuditsV3Router from "./PersonAuditsV3Router";
import OperationalNeedsV3Router from "./OperationalNeedsV3Router";
import SecurityChecksV3Router from "./SecurityChecksV3Router";
import SecurityTiersV3Router from "./SecurityTiersV3Router";
import SelfServeAuditsV3Router from "./SelfServeAuditsV3Router";
import SelfServeLocationsV3Router from "./SelfServeLocationsV3Router";
import UserManagementV3Router from "./UserManagementV3Router";
import ReportsV3Router from "./ReportsV3Router";
import NewASICImportV3Router from "./NewASICImportV3Router";
import NewASICManagementV3Router from "./NewASICManagementV3Router";
import VehicleCheckinsV3Router from "./VehicleCheckinsV3Router";

export default function V3Router() {
  return (
    <Routes>
      <Route path="airport-management/*" element={<AirportManagementV3Router />} />
      <Route path="api-keys/*" element={<APIKeysV3Router />} />
      <Route path="aev-locations/*" element={<AEVLocationsV3Router />} />
      <Route path="asic-management/*" element={<ASICManagementV3Router />} />
      <Route path="new-asic-management/*" element={<NewASICManagementV3Router />} />
      <Route path="asic-import/*" element={<ASICImportV3Router />} />
      <Route path="new-asic-import/*" element={<NewASICImportV3Router />} />
      <Route path="audit-policies/*" element={<AuditPoliciesV3Router />} />
      <Route path="notes/*" element={<NotesV3Router />} />
      <Route path="person-audits/*" element={<PersonAuditsV3Router />} />
      <Route path="operational-needs/*" element={<OperationalNeedsV3Router />} />
      <Route path="reports/*" element={<ReportsV3Router />} />
      <Route path="security-checks/*" element={<SecurityChecksV3Router />} />
      <Route path="security-tiers/*" element={<SecurityTiersV3Router />} />
      <Route path="self-serve-audits/*" element={<SelfServeAuditsV3Router />} />
      <Route path="self-serve-locations/*" element={<SelfServeLocationsV3Router />} />
      <Route path="user-management/*" element={<UserManagementV3Router />} />
      <Route path="vehicle-checkins/*" element={<VehicleCheckinsV3Router />} />
    </Routes>
  );
}
