import { Dialog, Transition } from "@headlessui/react";
import { TableIcon } from "@heroicons/react/outline";
import { addDays, format, subDays } from "date-fns";
import { Fragment, useEffect, useRef, useState } from "react";
import { apiV3 } from "../../utils/helpers/api";
import { useToast } from "../../components/Toastr/Toastr";

import SearchFormV3 from "../SearchFormV3/SearchForm";
import DateRangePicker from "../DateRangePicker/DateRangePicker";

interface ExportPersonAuditCSVModalProps {
  open: boolean;
  setOpen: (newOpenValue: boolean) => void;
  selectedPersonAudits?: any[];
}

export default function ExportPersonAuditCSVModal(props: ExportPersonAuditCSVModalProps) {
  const { selectedPersonAudits = [], open } = props;
  const defaultStartDate = () => subDays(new Date(), 1).toISOString().slice(0, -8);
  const defaultEndDate = () => addDays(new Date(), 1).toISOString().slice(0, -8);
  const { showErrorToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(defaultStartDate);
  const [endDate, setEndDate] = useState<string>(defaultEndDate);
  const [numberOfAudits, setNumberOfAudits] = useState(0);
  const cancelButtonRef = useRef(null);

  const download = (data: any) => {
    const url = window.URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `person-security-submissions-${format(new Date(), "yyyy-MM-dd")}.csv`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const exportToCSVNew = async () => {
    setLoading(true);
    setSpinner(true);
    try {
      if (selectedPersonAudits.length > 0) {
        const personAuditIds = selectedPersonAudits.map((audit) => audit.id);
        const res = await apiV3.exportPersonSecuritySubmissionsToCsv(personAuditIds);
        download(res.data);
      } else {
        const res = await apiV3.exportPersonSecuritySubmissions(
          searchQuery,
          searchField,
          startDate,
          endDate
        );
        download(res.data);
      }
    } catch (error) {
      console.error("Error occurred while exporting audits to CSV - ", error);
      showErrorToast();
    } finally {
      setLoading(false);
      setSpinner(false);
      props.setOpen(false);
    }
  };

  const fetchPersonAuditsCount = async () => {
    setLoading(true);
    try {
      if (selectedPersonAudits.length === 0) {
        const count = await apiV3.getPersonSecuritySubmissionsCount(
          searchQuery,
          searchField,
          startDate,
          endDate
        );

        setNumberOfAudits(count.data.count);
      }
    } catch (err) {
      console.error(`Error occurred while fetching person audits count - `, err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedPersonAudits.length > 0) {
      setNumberOfAudits(selectedPersonAudits.length);
    }
  }, [selectedPersonAudits]);

  useEffect(() => {
    if (open) {
      fetchPersonAuditsCount();
    }
  }, [open, searchQuery, searchField, startDate, endDate]);

  useEffect(() => {
    if (!open) {
      setStartDate(defaultStartDate());
      setEndDate(defaultEndDate());
      setNumberOfAudits(0);
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-y-auto text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div>
                  <div className="flex items-center justify-center w-12 h-12 mx-auto bg-blue-100 rounded-full">
                    <TableIcon className="w-6 h-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                      Export to CSV
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6">
                        {selectedPersonAudits.length > 0 ? null : (
                          <>
                            <div className="sm:col-span-6">
                              <div className="font-semibold text-left text-md">Filters</div>
                            </div>
                            <div className="flex flex-col sm:col-span-6">
                              <DateRangePicker
                                updateDates={(newStartDate, newEndDate) => {
                                  setStartDate(newStartDate);
                                  setEndDate(newEndDate);
                                }}
                                defaultStartDate={subDays(new Date(), 1).toISOString().slice(0, -8)}
                                defaultEndDate={addDays(new Date(), 1).toISOString().slice(0, -8)}
                                disabled={loading}
                              />
                              <SearchFormV3
                                searchOptions={[
                                  { name: "ASIC ID", value: "asicId" },
                                  { name: "First Name", value: "firstName" },
                                  { name: "Last Name", value: "lastName" },
                                  { name: "Company", value: "company" },
                                  {
                                    name: "Location Name",
                                    value: "locationName",
                                  },
                                ]}
                                disabled={loading}
                                updateSearchQueryAndField={(newSearchQuery, newSearchField) => {
                                  setSearchQuery(newSearchQuery);
                                  setSearchField(newSearchField);
                                }}
                              />
                            </div>
                          </>
                        )}

                        <div className="mt-2 sm:col-span-6">
                          <div className="font-semibold text-left text-md">Exported Audits</div>
                        </div>
                        <div className="mt-2 text-sm text-left sm:col-span-6">
                          The exported CSV will contain{" "}
                          <p className="inline-flex font-semibold text-indigo-500">
                            {numberOfAudits} {numberOfAudits === 1 ? "audit" : "audits"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    disabled={loading || numberOfAudits === 0}
                    className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:opacity-50"
                    onClick={exportToCSVNew}
                  >
                    {spinner ? (
                      <svg
                        className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : null}
                    Export
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
