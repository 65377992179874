import { ArrowLeftIcon, BanIcon, CheckIcon } from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function EditSecurityCheckV3() {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [securityCheck, setSecurityCheck] = useState<any>(null);
  const [name, setName] = useState<string>("");
  const [type, setType] = useState<string>("Unknown");
  const [key, setKey] = useState<string>("");
  const [defaultResult, setDefaultResult] = useState<string>("");
  const [openDisableSecurityCheckModal, setOpenDisableSecurityCheckModal] =
    useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Get securityCheck from database
    const securityCheckId = params.securityCheckId;
    if (securityCheckId) {
      fetchSecurityCheck(securityCheckId);
    } else {
      showErrorToast();
    }
  }, []);

  const fetchSecurityCheck = async (securityCheckId: string) => {
    apiV3
      .getSecurityCheckById(securityCheckId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          const securityCheck = res.data;
          setSecurityCheck(securityCheck);
          setName(securityCheck.name);
          setType(securityCheck.type);
          setKey(securityCheck.key);
          setDefaultResult(securityCheck.defaultResult);
        }
      })
      .catch((error) => {
        console.error(
          `Error occurred while fetching securityCheck with id ${securityCheckId}`,
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const updateSecurityCheck = async () => {
    setLoading(true);
    try {
      if (params.securityCheckId) {
        await apiV3.updateSecurityCheckById(params.securityCheckId, {
          name,
          type,
          key,
          defaultResult,
          active: securityCheck.active,
        });
        showSuccessToast("Successfully updated security check");
      } else {
        console.error(
          "Error occurred while updating securityCheck - couldn't find the securityCheck's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while updating securityCheck - ", error);
      showErrorToast();
    }
    setLoading(false);
  };

  const enableSecurityCheck = async () => {
    setLoading(true);
    try {
      if (params.securityCheckId) {
        await apiV3.enableSecurityCheckById(params.securityCheckId);
        showSuccessToast("Successfully enabled security check");
        await fetchSecurityCheck(params.securityCheckId);
        setLoading(false);
      } else {
        console.error(
          "Error occurred while enabling securityCheck - couldn't find the securityCheck's id from the URL parameters"
        );
        showErrorToast();
        setLoading(false);
      }
    } catch (error) {
      console.error("Error occurred while enabling securityCheck - ", error);
      showErrorToast();
      setLoading(false);
    }
  };

  const disableSecurityCheck = async () => {
    setLoading(true);
    setOpenDisableSecurityCheckModal(false);
    try {
      if (params.securityCheckId) {
        await apiV3.disableSecurityCheckById(params.securityCheckId);
        showSuccessToast("Successfully disabled security check");
        await fetchSecurityCheck(params.securityCheckId);
        setLoading(false);
      } else {
        console.error(
          "Error occurred while disabling securityCheck - couldn't find the securityCheck's id from the URL parameters"
        );
        showErrorToast();
        setLoading(false);
      }
    } catch (error) {
      console.error("Error occurred while disabling securityCheck - ", error);
      showErrorToast();
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    Edit SecurityCheck {name && <em className="ml-1">({name})</em>}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Edit the name and timezone of an securityCheck or delete it from the system
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="text-sm">
                      <span className="font-semibold">Status: </span>
                      {securityCheck && securityCheck.hasOwnProperty("active") && (
                        <span>
                          {securityCheck.active ? (
                            <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              Active
                            </div>
                          ) : (
                            <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                              Inactive
                            </div>
                          )}
                        </span>
                      )}
                    </div>
                    <div className="pt-4">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Name
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              disabled={loading}
                              value={name ?? ""}
                              onChange={(e) => setName(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="type"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Type
                          </label>
                          <select
                            id="type"
                            name="type"
                            disabled={loading}
                            onChange={(e) => setType(e.target.value)}
                            value={type}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-500 sm:text-sm sm:leading-6"
                          >
                            <option value="Unknown">Unknown</option>
                            <option value="Person">Person</option>
                            <option value="Vehicle">Vehicle</option>
                          </select>
                        </div>
                        <div className="sm:col-span-4">
                          <label htmlFor="key" className="block text-sm font-medium text-gray-700">
                            Key
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="key"
                              id="key"
                              autoComplete="key"
                              disabled={loading}
                              value={key ?? ""}
                              onChange={(e) => setKey(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="defaultResult"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Default Result
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="defaultResult"
                              id="defaultResult"
                              autoComplete="defaultResult"
                              disabled={loading}
                              value={defaultResult ?? ""}
                              onChange={(e) => setDefaultResult(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {securityCheck &&
                    securityCheck.hasOwnProperty("active") &&
                    securityCheck.active ? (
                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Disable Security Check
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Disable a security check so it cannot be used in future audits/requests
                          </p>
                        </div>
                        <div className="mt-6">
                          <button
                            type="button"
                            disabled={loading}
                            onClick={() => setOpenDisableSecurityCheckModal(true)}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                          >
                            <BanIcon className="h-4 w-4 mr-1" />
                            Disable Security Check
                          </button>
                        </div>
                        <DeleteModal
                          description="Disabling a security check will prevent any future audits/requests from using it"
                          open={openDisableSecurityCheckModal}
                          setOpen={setOpenDisableSecurityCheckModal}
                          title={"Are you sure you want to disable this security check?"}
                          action={() => disableSecurityCheck()}
                          actionButtonText={"Disable"}
                        />
                      </div>
                    ) : (
                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Enable Security Check
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Enable a security check so it can be used in future audits/requests
                          </p>
                        </div>
                        <div className="mt-6">
                          <button
                            type="button"
                            disabled={loading}
                            onClick={() => enableSecurityCheck()}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                          >
                            <CheckIcon className="h-4 w-4 mr-1" />
                            Enable Security Check
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v3/security-checks")}
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => updateSecurityCheck()}
                        disabled={loading || !name || !type || !defaultResult}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Save
                      </button>
                      {(!name || !type || !defaultResult) && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to update the security check
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
