import { Route, Routes } from "react-router-dom";
import ASICImportV2 from "../../pages/V2/ASICImportV2/ASICImportV2";
import ImportASICV2 from "../../pages/V2/ASICImportV2/ImportASICV2/ImportASICV2";
import ImportedASICV2 from "../../pages/V2/ASICImportV2/ImportedASICV2/ImportedASICV2";
import { RequireAuth } from "../../utils/auth/auth";

export default function ASICImportV2Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <ASICImportV2 />
          </RequireAuth>
        }
      />
      <Route
        path="import"
        element={
          <RequireAuth>
            <ImportASICV2 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:asicImportId"
        element={
          <RequireAuth>
            <ImportedASICV2 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
