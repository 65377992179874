import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../utils/auth/auth";
import AddSecurityCheckV3 from "../../pages/V3/SecurityChecksV3/AddSecurityCheckV3/AddSecurityCheckV3";
import EditSecurityCheckV3 from "../../pages/V3/SecurityChecksV3/EditSecurityCheckV3/EditSecurityCheckV3";
import SecurityChecksV3 from "../../pages/V3/SecurityChecksV3/SecurityChecksV3";

export default function SecurityChecksV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <SecurityChecksV3 />
          </RequireAuth>
        }
      />
      <Route
        path="add"
        element={
          <RequireAuth>
            <AddSecurityCheckV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:securityCheckId"
        element={
          <RequireAuth>
            <EditSecurityCheckV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
