import { Route, Routes } from "react-router-dom";
import SelfServeAuditV2 from "../../pages/V2/SelfServeAuditsV2/SelfServeAuditV2/SelfServeAuditV2";
import SelfServeAuditsV2 from "../../pages/V2/SelfServeAuditsV2/SelfServeAuditsV2";
import { RequireAuth } from "../../utils/auth/auth";

export default function SelfServeAuditsV2Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <SelfServeAuditsV2 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:selfServeAuditId"
        element={
          <RequireAuth>
            <SelfServeAuditV2 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
