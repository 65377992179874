import { ArrowLeftIcon, MailIcon, PlusIcon } from "@heroicons/react/outline";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function ConfigureReportV3() {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [openDeleteReportConfigurationModal, setOpenDeleteReportConfigurationModal] =
    useState<boolean>(false);

  const [reportConfiguration, setReportConfiguration] = useState<any>(null);
  const [sendTime, setSendTime] = useState<number>(8);
  const [sendFrequency, setSendFrequency] = useState<string>("Unknown");
  const [reportFrequency, setReportFrequency] = useState<string>("Unknown");
  const [recipientEmailAddresses, setRecipientEmailAddresses] = useState<
    { id: number; email: string }[]
  >([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchReportConfiguration();
  }, []);

  const fetchReportConfiguration = async () => {
    setLoading(true);

    try {
      if (params.reportType) {
        const reportConfigRes = await apiV3.getReportConfigurationByReportType(params.reportType);

        const reportConfiguration = reportConfigRes.data;
        setReportConfiguration(reportConfiguration);

        if (reportConfiguration) {
          setSendFrequency(reportConfiguration.sendFrequency);
          setSendTime(reportConfiguration.sendTime);
          setReportFrequency(reportConfiguration.reportFrequency);
          setRecipientEmailAddresses(
            reportConfiguration.recipientEmailAddresses.map((email: string, index: number) => {
              return {
                id: index + 1,
                email: email,
              };
            })
          );
        }
      } else {
        console.error(
          "Error occurred while fetching report configuration - couldn't find the report type from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching report configuration with type ${params.reportType}`,
        error
      );
      showErrorToast();
    }

    setLoading(false);
  };

  const createOrUpdateReportConfiguration = async () => {
    setLoading(true);
    try {
      if (params.reportType) {
        if (reportConfiguration) {
          await apiV3.updateReportConfigurationForReportType(params.reportType, {
            sendFrequency,
            sendTime,
            reportFrequency,
            recipientEmailAddresses: recipientEmailAddresses.map((recipient) => recipient.email),
          });
        } else {
          await apiV3.createReportConfigurationForReportType(params.reportType, {
            sendFrequency,
            sendTime,
            reportFrequency,
            recipientEmailAddresses: recipientEmailAddresses.map((recipient) => recipient.email),
          });
        }
        showSuccessToast("Successfully saved report configuration");
      } else {
        console.error(
          "Error occurred while updating report configuration - couldn't find the report type from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while updating report configuration - ", error);
      showErrorToast();
    }
    setLoading(false);
  };

  const deleteReportConfiguration = async () => {
    setLoading(true);
    setOpenDeleteReportConfigurationModal(false);
    try {
      if (params.reportType) {
        await apiV3.deleteReportConfigurationForReportType(params.reportType);
        showSuccessToast("Successfully deleted report configuration");
        setLoading(false);
        navigate("/v3/reports");
      } else {
        console.error(
          "Error occurred while deleting report configuration - couldn't find the report type from the URL parameters"
        );
        showErrorToast();
        setLoading(false);
      }
    } catch (error) {
      console.error("Error occurred while deleting report configuration - ", error);
      showErrorToast();
      setLoading(false);
    }
  };

  const addRecipient = () => {
    setRecipientEmailAddresses([
      ...recipientEmailAddresses,
      {
        id: recipientEmailAddresses.length + 1,
        email: "",
      },
    ]);
  };

  const removeRecipient = (id: number) => {
    const newRecipients = recipientEmailAddresses.filter((e) => e.id !== id);

    for (let i = 0; i < newRecipients.length; i++) {
      newRecipients[i].id = i + 1;
    }

    setRecipientEmailAddresses(newRecipients);
  };

  const editRecipient = (id: number, newEmailAddress: string) => {
    setRecipientEmailAddresses(
      recipientEmailAddresses.map((recipient) =>
        recipient.id === id ? { id: id, email: newEmailAddress } : recipient
      )
    );
  };

  const getSendTimeOptions = () => {
    const hours: number[] = [];
    for (let hour = 0; hour < 24; hour++) {
      hours.push(hour);
    }

    return hours.map((hour) => (
      <option key={hour} value={hour}>
        {hour < 10 ? `0${hour}` : hour}:00
      </option>
    ));
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    <span>
                      Configure Report
                      {params.reportType && (
                        <span>
                          <em className="ml-1">({params.reportType})</em>
                        </span>
                      )}
                    </span>
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Configure a report or delete the configuration from the system
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="send-frequency"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Send Frequency
                          </label>
                          <select
                            id="send-frequency"
                            name="send-frequency"
                            disabled={loading}
                            onChange={(e) => setSendFrequency(e.target.value)}
                            value={sendFrequency}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-500 sm:text-sm sm:leading-6"
                          >
                            <option value={"Unknown"}>Unknown</option>
                            <option value={"Daily"}>Daily</option>
                            <option value={"Weekly"}>Weekly</option>
                            <option value={"Fortnightly"}>Fortnightly</option>
                            <option value={"Monthly"}>Monthly</option>
                          </select>
                        </div>
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="send-time"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Send Time
                          </label>
                          <select
                            id="send-time"
                            name="send-time"
                            disabled={loading}
                            onChange={(e) => setSendTime(parseInt(e.target.value, 10))}
                            value={sendTime}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-500 sm:text-sm sm:leading-6"
                          >
                            {getSendTimeOptions()}
                          </select>
                        </div>
                        <div className="sm:col-span-4">
                          <div>
                            <label
                              htmlFor="report-frequency"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Report Frequency
                            </label>
                            <select
                              id="report-frequency"
                              name="report-frequency"
                              disabled={loading}
                              onChange={(e) => setReportFrequency(e.target.value)}
                              value={reportFrequency}
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-500 sm:text-sm sm:leading-6"
                            >
                              <option value={"Unknown"}>Unknown</option>
                              <option value={"Daily"}>Daily</option>
                              <option value={"Weekly"}>Weekly</option>
                              <option value={"Fortnightly"}>Fortnightly</option>
                              <option value={"Monthly"}>Monthly</option>
                            </select>
                          </div>
                        </div>
                        <div className="sm:col-span-4 mt-2">
                          <div className="text-base font-semibold leading-6 text-gray-900 flex flex-row items-center">
                            <MailIcon className="h-6 w-6 mr-1.5" />
                            Recipients
                          </div>
                          <div className="text-sm py-1 text-gray-500">
                            <span>Total Recipients: </span>
                            <span>{recipientEmailAddresses.length}</span>
                          </div>
                          {recipientEmailAddresses.length === 0 && (
                            <div className="my-0.5 text-sm text-red-500 font-semibold">
                              There must be at least one recipient
                            </div>
                          )}
                          {recipientEmailAddresses.filter((r) => r.email === "").length > 0 && (
                            <div className="my-0.5 text-sm text-red-500 font-semibold">
                              Email addresses cannot be blank
                            </div>
                          )}
                          <div className="space-y-2">
                            {recipientEmailAddresses.map((recipient) => {
                              return (
                                <div className="">
                                  <div>
                                    <div className="mt-2 flex rounded-md shadow-sm">
                                      <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                          <MailIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </div>
                                        <input
                                          type="email"
                                          name="email"
                                          id="email"
                                          autoComplete="email"
                                          disabled={loading}
                                          value={recipient.email}
                                          onChange={(e) =>
                                            editRecipient(recipient.id, e.target.value)
                                          }
                                          className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-500 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                      <button
                                        type="button"
                                        disabled={loading}
                                        onClick={() => removeRecipient(recipient.id)}
                                        className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm text-white bg-red-800 hover:bg-red-900"
                                      >
                                        <TrashIcon
                                          className="-ml-0.5 h-5 w-5 text-white"
                                          aria-hidden="true"
                                        />
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="mt-4">
                            <button
                              type="button"
                              disabled={
                                loading ||
                                recipientEmailAddresses.filter((r) => r.email === "").length > 0
                              }
                              onClick={() => addRecipient()}
                              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                            >
                              <PlusIcon className="h-4 w-4 mr-1" />
                              Add Recipient
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {reportConfiguration && (
                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Delete Report Configuration
                          </h3>
                        </div>
                        <div className="mt-6">
                          <button
                            type="button"
                            disabled={loading}
                            onClick={() => setOpenDeleteReportConfigurationModal(true)}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800 disabled:opacity-50"
                          >
                            <TrashIcon className="h-4 w-4 mr-1" />
                            Delete Report Configuration
                          </button>
                        </div>
                        <DeleteModal
                          description="Deleting a report configuration is permanent. Any reports scheduled to send will be unscheduled immediately and this configuration will be erased from AEV databases."
                          open={openDeleteReportConfigurationModal}
                          setOpen={setOpenDeleteReportConfigurationModal}
                          title={"Are you sure you want to delete?"}
                          action={() => deleteReportConfiguration()}
                          actionButtonText={"Delete"}
                        />
                      </div>
                    )}
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v3/reports")}
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => createOrUpdateReportConfiguration()}
                        disabled={
                          loading ||
                          !sendFrequency ||
                          !reportFrequency ||
                          recipientEmailAddresses.length === 0 ||
                          recipientEmailAddresses.filter((r) => r.email === "").length > 0
                        }
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Save
                      </button>
                      {(!sendFrequency ||
                        !reportFrequency ||
                        recipientEmailAddresses.length === 0) && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to save a report configuration
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
