import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/styles/output.css";
import { ToastProvider } from "./components/Toastr/Toastr";

ReactDOM.render(
  <ToastProvider>
    <App />
  </ToastProvider>,
  document.getElementById("root")
);
