import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  ExclamationIcon,
  PrinterIcon,
  SaveIcon,
  ThumbDownIcon,
  ThumbUpIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import { UserAddIcon } from "@heroicons/react/solid";
import { addDays, addMonths } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import VisitorPassLogs from "../../../../components/VisitorPassLogs/VisitorPassLogs";
import { useAuth } from "../../../../utils/auth/auth";
import { apiV2 } from "../../../../utils/helpers/api";
import { UserRole } from "../../../../utils/types";

export default function ReportV2() {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [openDeleteVisitorPassModal, setOpenDeleteVisitorPassModal] =
    useState<boolean>(false);
  const [invalidForm, setInvalidForm] = useState<boolean>(true);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [approveSuccess, setApproveSuccess] = useState<boolean>(false);
  const [rejectSuccess, setRejectSuccess] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState<string>("");
  const [printSuccess, setPrintSuccess] = useState<boolean>(false);
  const [cancelSuccess, setCancelSuccess] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState<string>("");
  const [vpNumber, setVPNumber] = useState<string>("VPUNKNOWN");
  const [facePhoto, setFacePhoto] = useState<string>("");
  const [idPhotoFront, setIdPhotoFront] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [idType, setIdType] = useState<string>("");
  const [idNumber, setIdNumber] = useState<string>("");
  const [sponsorName, setSponsorName] = useState<string>("");
  const [sponsorAsicId, setSponsorAsicId] = useState<string>("");
  const [sponsorCompany, setSponsorCompany] = useState<string>("");
  const [sponsorAsicExpiryMonth, setSponsorAsicExpiryMonth] =
    useState<string>("1");
  const [sponsorAsicExpiryYear, setSponsorAsicExpiryYear] = useState<string>(
    new Date().getFullYear().toString()
  );
  const [operationalNeed, setOperationalNeed] = useState<string>("");
  const [airport, setAirport] = useState<any>();
  const [printers, setPrinters] = useState<any[]>([]);
  const [printerId, setPrinterId] = useState<string>("");
  const [rangeType, setRangeType] = useState<string>("24 Hours");
  const [startTime, setStartTime] = useState<string>(
    new Date().toISOString().slice(0, -8)
  );
  const [endTime, setEndTime] = useState<string>(
    new Date().toISOString().slice(0, -8)
  );
  const [status, setStatus] = useState<number>(0);
  const [visitorPassLogs, setVisitorPassLogs] = useState<any[]>([]);
  const [openRejectPassModal, setOpenRejectPassModal] =
    useState<boolean>(false);
  const [openCancelPassModal, setOpenCancelPassModal] =
    useState<boolean>(false);
  const [openPrintPassModal, setOpenPrintPassModal] = useState<boolean>(false);
  const [airportOfficerRole, setAirportOfficerRole] = useState<boolean>(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Get vpRequest from database
    const visitorPassRequestId = params.visitorPassRequestId;
    if (visitorPassRequestId) {
      fetchVisitorPass(visitorPassRequestId);
    } else {
      setError(true);
    }
  }, []);

  useEffect(() => {
    if (
      auth.user &&
      !auth.loading &&
      auth.user.roles.includes(UserRole.AirportOfficer)
    ) {
      setAirportOfficerRole(true);
    } else {
      setAirportOfficerRole(false);
    }
  }, [auth.loading]);

  useEffect(() => {
    const startTimeDate = new Date(
      new Date(startTime).getTime() -
        new Date(startTime).getTimezoneOffset() * 60000
    );
    if (rangeType === "24 Hours") {
      setEndTime(addDays(startTimeDate, 1).toISOString().slice(0, -1));
    } else if (rangeType === "1 Week") {
      setEndTime(addDays(startTimeDate, 7).toISOString().slice(0, -1));
    } else if (rangeType === "1 Month") {
      setEndTime(addMonths(startTimeDate, 1).toISOString().slice(0, -1));
    }
  }, [startTime, rangeType]);

  useEffect(() => {
    setInvalidForm(
      !facePhoto ||
        !idPhotoFront ||
        !firstName ||
        !lastName ||
        !email ||
        !mobileNumber ||
        !company ||
        !operationalNeed ||
        !sponsorName ||
        !sponsorAsicId ||
        !sponsorAsicExpiryMonth ||
        !sponsorAsicExpiryYear ||
        !sponsorCompany ||
        !idType ||
        !idNumber ||
        !rangeType ||
        !startTime ||
        !endTime
    );
  }, [
    facePhoto,
    idPhotoFront,
    firstName,
    lastName,
    email,
    mobileNumber,
    company,
    operationalNeed,
    sponsorName,
    sponsorAsicId,
    sponsorAsicExpiryMonth,
    sponsorAsicExpiryYear,
    sponsorCompany,
    idType,
    idNumber,
    rangeType,
    startTime,
    endTime,
  ]);

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };

  const fetchVisitorPass = async (visitorPassRequestId: string) => {
    // api
    //   .getVisitorPassById(visitorPassRequestId)
    //   .then((res) => {
    //     if (res.status === 200 && res.data) {
    //       const vpRequest = res.data;
    //       setVPNumber(vpRequest.vpNumber ?? "VPUNKNOWN");
    //       setFacePhoto(vpRequest.facePhoto ?? "");
    //       setIdPhotoFront(vpRequest.idPhotoFront ?? "");
    //       setEmail(vpRequest.email ?? "");
    //       setFirstName(vpRequest.firstName ?? "");
    //       setLastName(vpRequest.lastName ?? "");
    //       setCompany(vpRequest.company ?? "");
    //       setMobileNumber(vpRequest.mobileNumber ?? "");
    //       setIdType(vpRequest.idType ?? "");
    //       setIdNumber(vpRequest.idNumber ?? "");
    //       setSponsorName(vpRequest.sponsorName ?? "");
    //       setSponsorAsicId(vpRequest.sponsorAsicId ?? "");
    //       setSponsorCompany(vpRequest.sponsorCompany ?? "");
    //       setSponsorAsicExpiryMonth(vpRequest.sponsorAsicExpiryMonth ?? "");
    //       setSponsorAsicExpiryYear(vpRequest.sponsorAsicExpiryYear ?? "");
    //       setOperationalNeed(vpRequest.operationalNeed ?? "");
    //       setAirport(vpRequest.airport);
    //       setRejectReason(vpRequest.rejectReason ?? "");
    //       setCancelReason(vpRequest.cancelReason ?? "");
    //       setStartTime(vpRequest.startTime.substring(0, 16) ?? "");
    //       setRangeType(vpRequest.rangeType ?? "");
    //       setEndTime(vpRequest.endTime.substring(0, 16) ?? "");
    //       setStatus(vpRequest.status ?? 0);
    //       setVisitorPassLogs(vpRequest.logs ?? []);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(
    //       `Error occurred while fetching visitor pass request with id ${visitorPassRequestId}`,
    //       error
    //     );
    //   })
    //   .finally(() => setLoading(false));
  };

  const updateVisitorPass = async () => {
    setLoading(true);
    try {
      await apiV2.updateVisitorPass(params.visitorPassRequestId, {
        firstName,
        lastName,
        email,
        mobileNumber,
        company,
        idType,
        idNumber,
        sponsorAsicId,
        sponsorName,
        sponsorAsicExpiryMonth,
        sponsorAsicExpiryYear,
        sponsorCompany,
        operationalNeed,
        rangeType,
        startTime,
        endTime,
      });
      setUpdateSuccess(true);
      await fetchVisitorPass(params.visitorPassRequestId ?? "");
      setTimeout(() => setUpdateSuccess(false), 3000);
    } catch (error) {
      console.error("Error occurred while updating visitor pass - ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteVisitorPass = async () => {
    setLoading(true);
    try {
      await apiV2.deleteVisitorPassById(params.visitorPassRequestId);
      navigate("/v2/visitor-pass-requests");
    } catch (error) {
      console.error("Error occurred while deleting visitor pass - ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const approveVpRequest = async () => {
    setLoading(true);
    try {
      await apiV2.approveVisitorPassById(params.visitorPassRequestId ?? "");
      setApproveSuccess(true);
      setTimeout(() => setApproveSuccess(false), 3000);
      await fetchVisitorPass(params.visitorPassRequestId ?? "");
    } catch (error) {
      console.error(
        `Error occurred while approving visitor pass request with id ${params.visitorPassRequestId}`,
        error
      );
      setError(true);
    }
    setLoading(false);
  };

  const rejectVpRequest = async () => {
    setLoading(true);
    setOpenRejectPassModal(false);
    try {
      await apiV2.rejectVisitorPassById(
        params.visitorPassRequestId ?? "",
        rejectReason
      );
      setRejectSuccess(true);
      await fetchVisitorPass(params.visitorPassRequestId ?? "");
      setTimeout(() => setRejectSuccess(false), 3000);
    } catch (error) {
      console.error(
        `Error occurred while rejecting visitor pass request with id ${params.visitorPassRequestId}`,
        error
      );
      setError(true);
    }
    setLoading(false);
  };

  const cancelVpRequest = async () => {
    setLoading(true);
    setOpenCancelPassModal(false);
    try {
      await apiV2.cancelVisitorPassById(
        params.visitorPassRequestId ?? "",
        cancelReason
      );
      setCancelSuccess(true);
      await fetchVisitorPass(params.visitorPassRequestId ?? "");
      setTimeout(() => setCancelSuccess(false), 3000);
    } catch (error) {
      console.error(
        `Error occurred while cancelling visitor pass request with id ${params.visitorPassRequestId}`,
        error
      );
      setError(true);
    }
    setLoading(false);
  };

  const printVpRequest = async () => {
    setLoading(true);
    setOpenPrintPassModal(false);
    try {
      await apiV2.printVisitorPassById(params.visitorPassRequestId ?? "");
      setPrintSuccess(true);
      await fetchVisitorPass(params.visitorPassRequestId ?? "");
      setTimeout(() => setPrintSuccess(false), 3000);
    } catch (error) {
      console.error(
        `Error occurred while printing visitor pass request with id ${params.visitorPassRequestId}`,
        error
      );
      setError(true);
    }
    setLoading(false);
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <UserAddIcon className="h-7 w-7 mr-1.5" />
                    Visitor Pass Request
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Approve or reject this visitor pass request
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    disabled={loading}
                    onClick={() => navigate("/v2/visitor-pass-requests")}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8">
                    <div className="grid grid-cols-2 gap-y-6 gap-x-4">
                      <div>
                        <h3 className="font-semibold mb-2">Face Photo</h3>
                        <img className="rounded-lg shadow" src={facePhoto} />
                      </div>
                      <div>
                        <h3 className="font-semibold mb-2">ID Front Photo</h3>
                        <img className="rounded-lg shadow" src={idPhotoFront} />
                      </div>
                    </div>
                    <div>
                      <div className="text-sm flex flex-row items-center">
                        <div>
                          <strong>Status:</strong>
                        </div>
                        {status === 1 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                            Requested
                          </div>
                        )}
                        {status === 2 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Approved
                          </div>
                        )}
                        {status === 3 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                            Rejected
                          </div>
                        )}
                        {status === 4 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                            Expired
                          </div>
                        )}
                        {status === 5 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-200 text-red-900">
                            Cancelled
                          </div>
                        )}
                        {status === 6 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-200 text-blue-900">
                            Printed
                          </div>
                        )}
                      </div>
                      <div className="text-sm flex flex-row items-center">
                        <div>
                          <strong>Airport:</strong>{" "}
                          <span>{airport?.name ?? "No Airport Specified"}</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email Address{showAsteriskIfEmpty(email)}
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              disabled={loading || status === 6}
                              value={email ?? ""}
                              onChange={(e) => setEmail(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First Name{showAsteriskIfEmpty(firstName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              disabled={loading || status === 6}
                              value={firstName ?? ""}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last Name{showAsteriskIfEmpty(lastName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              disabled={loading || status === 6}
                              value={lastName ?? ""}
                              onChange={(e) => setLastName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="company"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Company{showAsteriskIfEmpty(company)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="company"
                              id="company"
                              autoComplete="company"
                              disabled={loading || status === 6}
                              value={company ?? ""}
                              onChange={(e) => setCompany(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="mobile-number"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Mobile Number{showAsteriskIfEmpty(mobileNumber)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="mobile-number"
                              id="mobile-number"
                              autoComplete="mobile-number"
                              disabled={loading || status === 6}
                              value={mobileNumber ?? ""}
                              onChange={(e) => setMobileNumber(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="id-type"
                            className="block text-sm font-medium text-gray-700"
                          >
                            ID Type{showAsteriskIfEmpty(idType)}
                          </label>
                          <select
                            id="id-type"
                            name="id-type"
                            autoComplete="id-type"
                            value={idType}
                            disabled={loading || status === 6}
                            onChange={(e) => setIdType(e.target.value)}
                            className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                          >
                            <option>Driver's License</option>
                            <option>Passport</option>
                            <option>National ID</option>
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="id-number"
                            className="block text-sm font-medium text-gray-700"
                          >
                            ID Number{showAsteriskIfEmpty(idNumber)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="id-number"
                              id="id-number"
                              autoComplete="id-number"
                              disabled={loading || status === 6}
                              value={idNumber ?? ""}
                              onChange={(e) => setIdNumber(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="sponsor-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Name{showAsteriskIfEmpty(sponsorName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="sponsor-name"
                              id="sponsor-name"
                              autoComplete="sponsor-name"
                              disabled={loading || status === 6}
                              value={sponsorName ?? ""}
                              onChange={(e) => setSponsorName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="sponsor-asic-id"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor ASIC ID{showAsteriskIfEmpty(sponsorAsicId)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="sponsor-asic-id"
                              id="sponsor-asic-id"
                              autoComplete="sponsor-asic-id"
                              disabled={loading || status === 6}
                              value={sponsorAsicId ?? ""}
                              onChange={(e) =>
                                setSponsorAsicId(e.target.value.toUpperCase())
                              }
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="col-span-3">
                          <label
                            htmlFor="sponsor-asic-expiry-month"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor ASIC Expiry Month
                            {showAsteriskIfEmpty(sponsorAsicExpiryMonth)}
                          </label>
                          <div className="mt-1">
                            <select
                              id="sponsor-asic-expiry-month"
                              name="sponsor-asic-expiry-month"
                              autoComplete="new-sponsor-asic-expiry-month"
                              value={sponsorAsicExpiryMonth}
                              onChange={(e) =>
                                setSponsorAsicExpiryMonth(e.target.value)
                              }
                              disabled={loading || status === 6}
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            >
                              <option value={1}>Jan</option>
                              <option value={2}>Feb</option>
                              <option value={3}>Mar</option>
                              <option value={4}>Apr</option>
                              <option value={5}>May</option>
                              <option value={6}>Jun</option>
                              <option value={7}>Jul</option>
                              <option value={8}>Aug</option>
                              <option value={9}>Sep</option>
                              <option value={10}>Oct</option>
                              <option value={11}>Nov</option>
                              <option value={12}>Dec</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-span-3">
                          <label
                            htmlFor="sponsor-asic-expiry-year"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor ASIC Expiry Year
                            {showAsteriskIfEmpty(sponsorAsicExpiryYear)}
                          </label>
                          <div className="mt-1">
                            <select
                              id="sponsor-asic-expiry-year"
                              name="sponsor-asic-expiry-year"
                              autoComplete="new-sponsor-asic-expiry-year"
                              value={sponsorAsicExpiryYear}
                              onChange={(e) =>
                                setSponsorAsicExpiryYear(e.target.value)
                              }
                              disabled={loading || status === 6}
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            >
                              <option>{new Date().getFullYear()}</option>
                              <option>{new Date().getFullYear() + 1}</option>
                              <option>{new Date().getFullYear() + 2}</option>
                            </select>
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="sponsor-company"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Company{showAsteriskIfEmpty(sponsorCompany)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="sponsor-company"
                              id="sponsor-company"
                              autoComplete="sponsor-company"
                              disabled={loading || status === 6}
                              value={sponsorCompany ?? ""}
                              onChange={(e) =>
                                setSponsorCompany(e.target.value)
                              }
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="operational-need"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Operational Need
                            {showAsteriskIfEmpty(operationalNeed)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="operational-need"
                              id="operational-need"
                              autoComplete="operational-need"
                              disabled={loading || status === 6}
                              value={operationalNeed ?? ""}
                              onChange={(e) =>
                                setOperationalNeed(e.target.value)
                              }
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        {rejectReason && (
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="rejectReason"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Reject Reason
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="rejectReason"
                                id="rejectReason"
                                autoComplete="rejectReason"
                                disabled={loading || status === 6}
                                value={rejectReason ?? ""}
                                onChange={(e) =>
                                  setRejectReason(e.target.value)
                                }
                                className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                              />
                            </div>
                          </div>
                        )}

                        {cancelReason && (
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="cancelReason"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Cancel Reason
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="cancelReason"
                                id="cancelReason"
                                autoComplete="cancelReason"
                                disabled={loading || status === 6}
                                value={cancelReason ?? ""}
                                onChange={(e) =>
                                  setCancelReason(e.target.value)
                                }
                                className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                              />
                            </div>
                          </div>
                        )}

                        <div className="col-span-6 sm:col-span-4">
                          <label
                            htmlFor="pass-duration"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Pass Duration{showAsteriskIfEmpty(rangeType)}
                          </label>
                          <select
                            id="pass-duration"
                            name="pass-duration"
                            autoComplete="pass-duration"
                            value={rangeType}
                            disabled
                            onChange={(e) => setRangeType(e.target.value)}
                            className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                          >
                            <option>24 Hours</option>
                            <option>1 Week</option>
                            <option>1 Month</option>
                            <option>Custom</option>
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="start-date"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Start Date{showAsteriskIfEmpty(startTime)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="datetime-local"
                              name="start-date"
                              id="start-date"
                              autoComplete="start-date"
                              disabled={loading || status === 6}
                              value={startTime ?? ""}
                              onChange={(e) => setStartTime(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="end-date"
                            className="block text-sm font-medium text-gray-700"
                          >
                            End Date{showAsteriskIfEmpty(endTime)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="datetime-local"
                              name="end-date"
                              id="end-date"
                              autoComplete="end-date"
                              disabled={
                                loading ||
                                status === 6 ||
                                rangeType !== "Custom"
                              }
                              value={endTime ?? ""}
                              onChange={(e) => setEndTime(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {invalidForm && (
                      <div className="block text-sm font-medium text-red-500 mb-2">
                        <div className="my-1">
                          Please complete all required information (*) to update
                          your visitor pass request
                        </div>
                      </div>
                    )}
                  </div>

                  <VisitorPassLogs logs={visitorPassLogs} />

                  <div className="pt-5">
                    <div className="flex sm:items-center sm:flex-row sm:space-x-2 sm:space-y-0 space-y-2 flex-col">
                      <button
                        onClick={() => navigate("/v2/visitor-pass-requests")}
                        disabled={loading}
                        className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                        Back
                      </button>
                      {!airportOfficerRole && (
                        <>
                          <button
                            onClick={() => updateVisitorPass()}
                            disabled={loading || status === 6}
                            className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 mr-2"
                          >
                            <SaveIcon className="h-5 w-5 mr-1.5" />
                            <span>Update</span>
                          </button>
                          <button
                            onClick={() => setOpenDeleteVisitorPassModal(true)}
                            disabled={loading || status === 6}
                            className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                          >
                            <TrashIcon className="h-5 w-5 mr-1.5" />
                            <span>Delete</span>
                          </button>
                          <button
                            disabled={loading || status === 6}
                            onClick={() => setOpenCancelPassModal(true)}
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60"
                          >
                            <XIcon className="h-5 w-5 mr-1.5" />
                            Cancel
                          </button>
                        </>
                      )}

                      <button
                        disabled={loading || status === 6}
                        onClick={() => approveVpRequest()}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-60"
                      >
                        <ThumbUpIcon className="h-5 w-5 mr-1.5" />
                        Approve
                      </button>
                      <button
                        disabled={loading || status === 6}
                        onClick={() => setOpenRejectPassModal(true)}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-900 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black disabled:opacity-60"
                      >
                        <ThumbDownIcon className="h-5 w-5 mr-1.5" />
                        Reject
                      </button>

                      <button
                        disabled={loading || (status !== 2 && status !== 6)}
                        onClick={() => setOpenPrintPassModal(true)}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-60"
                      >
                        <PrinterIcon className="h-5 w-5 mr-1.5" />
                        {status === 6 ? "Reprint" : "Print"}
                      </button>
                      <Transition.Root show={openRejectPassModal} as={Fragment}>
                        <Dialog
                          as="div"
                          className="relative z-10"
                          onClose={setOpenRejectPassModal}
                        >
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                          </Transition.Child>

                          <div className="fixed z-10 inset-0 overflow-y-auto">
                            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                              >
                                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                      type="button"
                                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                                      onClick={() =>
                                        setOpenRejectPassModal(false)
                                      }
                                    >
                                      <span className="sr-only">Close</span>
                                      <XIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                  <div className="">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                      <ExclamationIcon
                                        className="h-6 w-6 text-red-600"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className="mt-3 text-center sm:text-left">
                                      <Dialog.Title
                                        as="h3"
                                        className="text-lg leading-6 font-medium text-gray-900"
                                      >
                                        <span>Reject Visitor Pass</span>
                                      </Dialog.Title>
                                      <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                          Are you sure you want to reject this
                                          visitor pass request?
                                        </p>
                                      </div>
                                      <div className="mt-2">
                                        <label
                                          htmlFor="reason"
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          Provide a reason
                                        </label>
                                        <div className="mt-1">
                                          <textarea
                                            rows={4}
                                            name="reason"
                                            id="reason"
                                            onChange={(e) =>
                                              setRejectReason(e.target.value)
                                            }
                                            value={rejectReason}
                                            placeholder={"Reason"}
                                            className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {!rejectReason && (
                                    <div className="mt-2 text-red-600 text-sm font-semibold">
                                      Please provide a reason for rejecting the
                                      visitor pass
                                    </div>
                                  )}

                                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                      type="button"
                                      disabled={loading || !rejectReason}
                                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-60"
                                      onClick={() => rejectVpRequest()}
                                    >
                                      Reject
                                    </button>
                                    <button
                                      type="button"
                                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mt-0 sm:w-auto sm:text-sm"
                                      onClick={() =>
                                        setOpenRejectPassModal(false)
                                      }
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition.Root>
                      <Transition.Root show={openCancelPassModal} as={Fragment}>
                        <Dialog
                          as="div"
                          className="relative z-10"
                          onClose={setOpenCancelPassModal}
                        >
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                          </Transition.Child>

                          <div className="fixed z-10 inset-0 overflow-y-auto">
                            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                              >
                                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                      type="button"
                                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                                      onClick={() =>
                                        setOpenCancelPassModal(false)
                                      }
                                    >
                                      <span className="sr-only">Close</span>
                                      <XIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                  <div className="">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                      <ExclamationIcon
                                        className="h-6 w-6 text-red-600"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className="mt-3 text-center sm:text-left">
                                      <Dialog.Title
                                        as="h3"
                                        className="text-lg leading-6 font-medium text-gray-900"
                                      >
                                        <span>Cancel Visitor Pass</span>
                                      </Dialog.Title>
                                      <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                          Are you sure you want to cancel this
                                          visitor pass request?
                                        </p>
                                      </div>
                                      <div className="mt-2">
                                        <label
                                          htmlFor="reason"
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          Provide a reason
                                        </label>
                                        <div className="mt-1">
                                          <textarea
                                            rows={4}
                                            name="reason"
                                            id="reason"
                                            onChange={(e) =>
                                              setCancelReason(e.target.value)
                                            }
                                            value={cancelReason}
                                            placeholder={"Reason"}
                                            className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {!cancelReason && (
                                    <div className="mt-2 text-red-600 text-sm font-semibold">
                                      Please provide a reason for cancelling the
                                      visitor pass
                                    </div>
                                  )}

                                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                      type="button"
                                      disabled={loading || !cancelReason}
                                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-60"
                                      onClick={() => cancelVpRequest()}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mt-0 sm:w-auto sm:text-sm"
                                      onClick={() =>
                                        setOpenCancelPassModal(false)
                                      }
                                    >
                                      Back
                                    </button>
                                  </div>
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition.Root>
                      <Transition.Root show={openPrintPassModal} as={Fragment}>
                        <Dialog
                          as="div"
                          className="relative z-10"
                          onClose={setOpenPrintPassModal}
                        >
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                          </Transition.Child>

                          <div className="fixed z-10 inset-0 overflow-y-auto">
                            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                              >
                                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                      type="button"
                                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                                      onClick={() =>
                                        setOpenPrintPassModal(false)
                                      }
                                    >
                                      <span className="sr-only">Close</span>
                                      <XIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                  <div className="">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                                      <ExclamationIcon
                                        className="h-6 w-6 text-yellow-600"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className="mt-3 text-center sm:text-left">
                                      <Dialog.Title
                                        as="h3"
                                        className="text-lg leading-6 font-medium text-gray-900"
                                      >
                                        <span>Print Visitor Pass</span>
                                      </Dialog.Title>
                                      <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                          Are you sure you want to print this
                                          visitor pass request? Once printed, it
                                          cannot be modified.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                      type="button"
                                      disabled={
                                        loading ||
                                        (status !== 2 && status !== 6)
                                      }
                                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-60"
                                      onClick={() => printVpRequest()}
                                    >
                                      Print
                                    </button>
                                    <button
                                      type="button"
                                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mt-0 sm:w-auto sm:text-sm"
                                      onClick={() =>
                                        setOpenPrintPassModal(false)
                                      }
                                    >
                                      Back
                                    </button>
                                  </div>
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition.Root>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={updateSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully updated visitor pass request
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please update the status accordingly
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setUpdateSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={approveSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <ThumbUpIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully approved pass request
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setApproveSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={rejectSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <ThumbDownIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully rejected pass request
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setRejectSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={cancelSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully cancelled pass request
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setCancelSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={printSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <PrinterIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully printed pass request
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setPrintSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
      <DeleteModal
        description="Deleting a visitor pass is permanent. You will not be able to recover it once deleted"
        open={openDeleteVisitorPassModal}
        setOpen={setOpenDeleteVisitorPassModal}
        title={"Are you sure you want to delete this pass?"}
        action={() => deleteVisitorPass()}
      />
    </>
  );
}
