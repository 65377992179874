import {
  CalendarIcon,
  DocumentIcon,
  PrinterIcon,
  QuestionMarkCircleIcon,
  ThumbDownIcon,
  ThumbUpIcon,
  XIcon,
} from "@heroicons/react/outline";
import { classNames } from "../../utils/helpers/misc";
import { VisitorPassStatus } from "../../utils/types";

interface VisitorPassLogsProps {
  logs: any[];
}

export default function VisitorPassLogs(props: VisitorPassLogsProps) {
  const getTextContentFromStatus = (status: VisitorPassStatus) => {
    let textContent = "Visitor Pass Status changed to ";
    switch (status) {
      case VisitorPassStatus.Approved:
        textContent += "Approved";
        break;
      case VisitorPassStatus.Cancelled:
        textContent += "Cancelled";
        break;
      case VisitorPassStatus.Declined:
        textContent += "Declined";
        break;
      case VisitorPassStatus.Printed:
        textContent += "Printed";
        break;
      case VisitorPassStatus.Expired:
        textContent += "Expired";
        break;
      case VisitorPassStatus.Requested:
        textContent += "Requested";
        break;
      case VisitorPassStatus.Unknown:
        textContent += "Unknown";
        break;
      default:
        break;
    }

    return textContent;
  };

  const getIconBackgroundFromStatus = (status: VisitorPassStatus) => {
    switch (status) {
      case VisitorPassStatus.Approved:
        return "bg-green-600";
      case VisitorPassStatus.Cancelled:
        return "bg-red-600";
      case VisitorPassStatus.Declined:
        return "bg-gray-900";
      case VisitorPassStatus.Printed:
        return "bg-blue-600";
      case VisitorPassStatus.Expired:
        return "bg-red-800";
      case VisitorPassStatus.Requested:
        return "bg-yellow-600";
      case VisitorPassStatus.Unknown:
        return "bg-emerald-600";
      default:
        return "";
    }
  };

  const getIconFromStatus = (status: VisitorPassStatus) => {
    switch (status) {
      case VisitorPassStatus.Approved:
        return <ThumbUpIcon className="h-5 w-5 text-white" />;
      case VisitorPassStatus.Cancelled:
        return <XIcon className="h-5 w-5 text-white" />;
      case VisitorPassStatus.Declined:
        return <ThumbDownIcon className="h-5 w-5 text-white" />;
      case VisitorPassStatus.Printed:
        return <PrinterIcon className="h-5 w-5 text-white" />;
      case VisitorPassStatus.Expired:
        return <CalendarIcon className="h-5 w-5 text-white" />;
      case VisitorPassStatus.Requested:
        return <DocumentIcon className="h-5 w-5 text-white" />;
      case VisitorPassStatus.Unknown:
        return <QuestionMarkCircleIcon className="h-5 w-5 text-white" />;
      default:
        return null;
    }
  };

  return (
    <div className="my-4">
      <h3 className="py-2 mb-4 font-bold text-xl">History/Logs</h3>
      <div className="flow-root">
        <ul role="list" className="-mb-8">
          {props.logs.map((log, logIdx) => (
            <li key={log.id}>
              <div className="relative pb-8">
                {logIdx !== props.logs.length - 1 ? (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        getIconBackgroundFromStatus(log.status),
                        "h-8 w-8 rounded-full flex items-center justify-center ring-4 ring-amber-300"
                      )}
                    >
                      {getIconFromStatus(log.status)}
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p className="text-sm text-gray-500">
                        {getTextContentFromStatus(log.status)}
                      </p>
                    </div>
                    <div className="whitespace-nowrap text-right text-sm text-gray-500">
                      <span>{new Date(log.createdAt).toLocaleString()}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
