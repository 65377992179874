import { Route, Routes } from "react-router-dom";
import VehicleCheckinsV3 from "../../pages/V3/VehicleCheckinsV3/VehicleCheckinsV3";
import { RequireAuth } from "../../utils/auth/auth";

export default function NotesV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <VehicleCheckinsV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
