import {
  BriefcaseIcon,
  DeviceMobileIcon,
  PlusIcon,
  RefreshIcon,
  UserAddIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { UsersIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchFormV3 from "../../../components/SearchFormV3/SearchForm";
import Sidebar from "../../../components/Sidebar/Sidebar";
import SortButton from "../../../components/SortButton/SortButton";
import { apiV3 } from "../../../utils/helpers/api";
import { classNames } from "../../../utils/helpers/misc";
import PageNumbers from "../../../components/PageNumbers/PageNumbers";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { usePageInitialization } from "../../../hooks/usePageInitialization";

export default function UserManagementV3() {
  const navigate = useNavigate();
  const [users, setUsers] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [newPageNumber, setNewPageNumber] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("DESC");
  const [sortField, setSortField] = useState<string>("createdAt");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("");
  const [role, setRole] = useState<string>("");

  useAuthToken();
  const { isInitiated } = usePageInitialization();

  useEffect(() => {
    if (isInitiated) fetchUsers();
  }, [
    pageNumber,
    rowsPerPage,
    searchQuery,
    rowsPerPage,
    searchField,
    sortOrder,
    sortField,
    role,
    isInitiated,
  ]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const users = await apiV3.getUsers(
        pageNumber,
        rowsPerPage,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        role
      );
      if (users.status === 200 && users.data.data) {
        setTotalCount(users.data.totalCount);
        setUsers(users.data.data);
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching ${rowsPerPage} users of page ${pageNumber} - `,
        error
      );
    }
    setLoading(false);
  };

  const updateSortCriteria = (newSortField: string) => {
    setSortField(newSortField);
    if (newSortField === sortField) {
      if (sortOrder === "DESC") setSortOrder("ASC");
      else setSortField("");
    } else {
      setSortOrder("DESC");
    }
  };

  const tabs = [
    {
      name: "All",
      onClick: () => setRole(""),
      icon: UsersIcon,
      current: role === "",
    },
    {
      name: "Admins",
      onClick: () => setRole("1"),
      icon: UserAddIcon,
      current: role === "1",
    },
    {
      name: "Airport Admins",
      onClick: () => setRole("2"),
      icon: UserIcon,
      current: role === "2",
    },
    {
      name: "Airport Officers",
      onClick: () => setRole("3"),
      icon: BriefcaseIcon,
      current: role === "3",
    },
    {
      name: "Self Serve Admins",
      onClick: () => setRole("4"),
      icon: DeviceMobileIcon,
      current: role === "4",
    },
  ];

  const updateRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };

  return (
    <>
      <Sidebar />
      <div className="flex flex-col flex-1 md:pl-64">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="flex flex-row items-center text-2xl font-bold text-gray-900">
                    <UsersIcon className="h-6 w-6 mr-1.5" />
                    User Management
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the users in AEV V3 including their details.
                  </p>
                </div>
                <div className="flex flex-row items-center mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate("/v3/user-management/add")}
                    type="button"
                    className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <PlusIcon className="h-5 w-5 mr-1.5" />
                    Add user
                  </button>
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => fetchUsers()}
                    className="inline-flex items-center justify-center px-4 py-2 ml-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-stone-600 hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 sm:w-auto disabled:hover:bg-stone-600"
                  >
                    <RefreshIcon className="h-5 w-5 mr-1.5" />
                    Refresh
                  </button>
                </div>
              </div>
              <div className="flex flex-col mt-4">
                <div className="mb-4">
                  <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                      id="tabs"
                      name="tabs"
                      className="block w-full border-gray-300 rounded-md focus:border-amber-500 focus:ring-amber-500"
                      defaultValue={tabs.find((tab) => tab.current)!.name}
                    >
                      {tabs.map((tab) => (
                        <option onClick={tab.onClick} key={tab.name}>
                          {tab.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                      <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <a
                            key={tab.name}
                            onClick={tab.onClick}
                            className={classNames(
                              tab.current
                                ? "border-amber-500 text-amber-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            <tab.icon
                              className={classNames(
                                tab.current
                                  ? "text-amber-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "-ml-0.5 mr-2 h-5 w-5"
                              )}
                              aria-hidden="true"
                            />
                            <span>{tab.name}</span>
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
                <SearchFormV3
                  searchOptions={[
                    { name: "Username", value: "username" },
                    { name: "Email", value: "email" },
                    { name: "First Name", value: "firstName" },
                    { name: "Last Name", value: "lastName" },
                    { name: "Phone Number", value: "phone" },
                  ]}
                  updateSearchQueryAndField={(newSearchQuery, newSearchField) => {
                    setSearchQuery(newSearchQuery);
                    setSearchField(newSearchField);
                  }}
                />
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Created
                                <SortButton
                                  active={sortField === "createdAt"}
                                  sortOrder={sortOrder}
                                  sortField={"createdAt"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Username
                                <SortButton
                                  active={sortField === "username"}
                                  sortOrder={sortOrder}
                                  sortField={"username"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Email
                                <SortButton
                                  active={sortField === "email"}
                                  sortOrder={sortOrder}
                                  sortField={"email"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                First Name
                                <SortButton
                                  active={sortField === "firstName"}
                                  sortOrder={sortOrder}
                                  sortField={"firstName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Last Name
                                <SortButton
                                  active={sortField === "lastName"}
                                  sortOrder={sortOrder}
                                  sortField={"lastName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Active
                                <SortButton
                                  active={sortField === "active"}
                                  sortOrder={sortOrder}
                                  sortField={"active"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Role
                                <SortButton
                                  active={sortField === "role"}
                                  sortOrder={sortOrder}
                                  sortField={"role"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span className="sr-only">View</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {users.map((user) => (
                            <tr
                              key={user.id}
                              className="cursor-pointer hover:bg-slate-50"
                              onClick={() => navigate("/v3/user-management/edit/" + user.id)}
                            >
                              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                {new Date(user.createdAt).toDateString()}
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                {user.username ?? ""}
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                {user.email}
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                {user.firstName}
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                {user.lastName}
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                {user.active ? (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Active
                                  </div>
                                ) : (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Inactive
                                  </div>
                                )}
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                {user.role === 1 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                                    Admin
                                  </div>
                                )}
                                {user.role === 2 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800">
                                    Airport Admin
                                  </div>
                                )}
                                {user.role === 3 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                    Airport Officer
                                  </div>
                                )}
                                {user.role === 4 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800">
                                    Self Serve Admin
                                  </div>
                                )}
                              </td>
                              <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                                <Link to={"/v3/user-management/edit/" + user.id}>
                                  <div className="text-amber-600 hover:text-amber-900">View</div>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex items-center justify-between py-3 border-t border-gray-200">
                      <div className="flex justify-between flex-1 sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
                        >
                          Previous
                        </a>
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
                        >
                          Next
                        </a>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="flex flex-row items-center">
                          {loading ? (
                            <p className="text-sm text-gray-700">Loading...</p>
                          ) : totalCount > 0 ? (
                            <>
                              <p className="text-sm text-gray-700">
                                Showing{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber - rowsPerPage + 1}
                                </span>{" "}
                                to{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber > totalCount
                                    ? totalCount
                                    : rowsPerPage * pageNumber}
                                </span>{" "}
                                of <span className="font-medium">{totalCount}</span> results
                              </p>
                            </>
                          ) : (
                            <p className="text-sm text-gray-700">Showing 0 results.</p>
                          )}
                          <div className="text-sm text-gray-700 inline-flex flex-row items-center ml-1.5">
                            <div>Results per page: </div>
                            <div>
                              <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                className="h-8 ml-1.5 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-600 text-sm sm:leading-6"
                                value={rowsPerPage}
                                onChange={(e) => updateRowsPerPage(parseInt(e.target.value))}
                                disabled={loading}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <PageNumbers
                          rowsPerPage={rowsPerPage}
                          totalCount={totalCount}
                          pageNumber={pageNumber}
                          setPageNumber={setPageNumber}
                          newPageNumber={newPageNumber}
                          setNewPageNumber={setNewPageNumber}
                          disabled={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
