import { ArrowLeftIcon, CloudDownloadIcon } from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { classNames } from "../../../../utils/helpers/misc";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function ImportedNewASICV3() {
  const navigate = useNavigate();
  const { showErrorToast } = useToast();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [importedAsic, setImportedAsic] = useState<any>(null);
  const [importedAsicFileUrl, setImportedAsicFileUrl] = useState<string>("");
  const [importedAsicReportFileUrl, setImportedAsicReportFileUrl] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchImportedAsic().finally(() => setLoading(false));
  }, []);

  const fetchImportedAsic = async () => {
    // Get imported ASIC details from database
    const asicImportId = params.asicImportId;

    try {
      if (asicImportId) {
        apiV3.getNewASICImportById(asicImportId).then((asicImportRes) => {
          setImportedAsic(asicImportRes.data);
          fetchImportedAsicFile(asicImportRes.data);
          fetchImportedAsicReportFile(asicImportRes.data);
        });
      } else {
        showErrorToast();
      }
    } catch (error) {
      console.error(
        `Error occurred while initializing imported asic with id ${asicImportId}`,
        error
      );
      showErrorToast();
    }
  };

  const fetchImportedAsicFile = async (importedAsic: any) => {
    try {
      setIsBusy(true);
      // Get file details from database
      apiV3.downloadNewAsicImportFile(importedAsic.id).then((asicImportFileRes) => {
        const blob = new Blob([asicImportFileRes.data], {
          type: "text/csv;charset=utf-8;",
        });
        const url = URL.createObjectURL(blob);
        setImportedAsicFileUrl(url);
      });
    } catch (error) {
      showErrorToast();
      console.error(`Error occurred while fetching imported ASIC file`, error);
    } finally {
      setIsBusy(false);
    }
  };

  const fetchImportedAsicReportFile = async (importedAsic: any) => {
    setIsBusy(true);
    // Get file details from database
    try {
      apiV3.downloadNewAsicImportReportFile(importedAsic.id).then((asicImportReportFileRes) => {
        const blob = new Blob([asicImportReportFileRes.data], {
          type: "text/csv;charset=utf-8;",
        });
        const url = URL.createObjectURL(blob);
        setImportedAsicReportFileUrl(url);
      });
    } catch (error) {
      showErrorToast();
      console.error(`Error occurred while fetching imported ASIC report file`, error);
    }
    setIsBusy(false);
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    ASIC Import File
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    View the details of an imported ASIC file
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              {!loading && importedAsic ? (
                <div className="mt-8 flex flex-col max-w-screen-md">
                  <div className="space-y-6 divide-y divide-gray-200">
                    <div className="space-y-6 divide-y divide-gray-200">
                      <div className="">
                        <div className="flex flex-row items-center text-sm">
                          <div className="mr-1">Status:</div>{" "}
                          {importedAsic.status === 1 && (
                            <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              Pending
                            </div>
                          )}
                          {importedAsic.status === 2 && (
                            <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                              Processing
                            </div>
                          )}
                          {importedAsic.status === 3 && (
                            <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              Imported
                            </div>
                          )}
                          {importedAsic.status === 4 && (
                            <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                              Failed
                            </div>
                          )}
                          {importedAsic.status === 0 && (
                            <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-stone-100 text-stone-800">
                              Unknown
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            File information
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            This information will be displayed publicly
                          </p>
                        </div>

                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-4">
                            <label
                              htmlFor="username"
                              className="block text-sm font-medium text-gray-700"
                            >
                              File name
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              {importedAsicFileUrl ? (
                                <a
                                  href={importedAsicFileUrl}
                                  download={importedAsic.originalFileName}
                                  className={classNames(
                                    "inline-flex flex-row items-center flex-1 font-semibold hover:underline cursor-pointer p-2 block w-full min-w-0 rounded-md sm:text-sm border border-gray-300 bg-gray-100",
                                    importedAsicFileUrl
                                      ? "text-indigo-500"
                                      : "cursor-not-allowed opacity-60"
                                  )}
                                >
                                  <CloudDownloadIcon className="h-4 w-4 mr-1.5" />
                                  {importedAsic.originalFileName ?? ""}
                                </a>
                              ) : (
                                <div className="inline-flex flex-row items-center flex-1 font-semibold hover:underline cursor-pointer p-2 block w-full min-w-0 rounded-md sm:text-sm border border-gray-300 bg-gray-100 cursor-not-allowed opacity-60">
                                  {importedAsic.originalFileName ?? ""}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Imported At
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                disabled
                                value={new Date(importedAsic.importedOn ?? "").toString() ?? ""}
                                className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-4">
                            <label
                              htmlFor="processingStartTime"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Processing Started At
                            </label>
                            <div className="mt-1">
                              <input
                                id="processingStartTime"
                                name="processingStartTime"
                                type="text"
                                autoComplete="processingStartTime"
                                disabled
                                value={
                                  importedAsic.processingStartTime
                                    ? new Date(importedAsic.processingStartTime ?? "").toString()
                                    : "Not Started"
                                }
                                className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-4">
                            <label
                              htmlFor="processingEndTime"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Processing Ended At
                            </label>
                            <div className="mt-1">
                              <input
                                id="processingEndTime"
                                name="processingEndTime"
                                type="text"
                                autoComplete="processingEndTime"
                                disabled
                                value={
                                  importedAsic.processingEndTime
                                    ? new Date(importedAsic.processingEndTime ?? "").toString()
                                    : "Not Finished"
                                }
                                className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Import Stats
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Statistics of the import process.
                          </p>
                        </div>
                        <div className="pt-2">
                          <div>
                            <span className="font-semibold mr-1.5">ASICS before Import</span>
                            <span>{importedAsic.databaseRecordsBefore}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">Valid records in file</span>
                            <span>{importedAsic.incomingRecords}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">Malformed records in file</span>
                            <span>{importedAsic.malformedRecords}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">Records selected in file</span>
                            <span>{importedAsic.incomingRecordsSelected}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">ASICs added</span>
                            <span>{importedAsic.newAsicsAdded}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">ASICs updated</span>
                            <span>{importedAsic.asicsUpdated}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">ASICs withdrawn</span>
                            <span>{importedAsic.asicsDeactivated}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">Identical ASICs</span>
                            <span>{importedAsic.identicalDetails}</span>
                          </div>
                          <div>
                            <span className="font-semibold mr-1.5">ASICs after import</span>
                            <span>{importedAsic.databaseRecordsAfter}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-5">
                      <div className="flex space-x-2 justify-start items-center">
                        <button
                          onClick={() => navigate("/v3/new-asic-import")}
                          type="button"
                          className="inline-flex flex-row items-center bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        >
                          <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                          Back
                        </button>
                        {importedAsicFileUrl ? (
                          <a
                            href={importedAsicFileUrl}
                            download={importedAsic.originalFileName}
                            className="inline-flex flex-row items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                          >
                            <CloudDownloadIcon className="h-4 w-4 mr-1.5" />
                            Original File
                          </a>
                        ) : (
                          <a className="inline-flex flex-row items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 cursor-not-allowed opacity-60">
                            <CloudDownloadIcon className="h-4 w-4 mr-1.5" />
                            Original File
                          </a>
                        )}

                        <a
                          href={importedAsicReportFileUrl}
                          download={
                            importedAsic.reportFileName ?? `Report-${importedAsic.originalFileName}`
                          }
                          className={classNames(
                            "inline-flex flex-row items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60",
                            importedAsicReportFileUrl ? "" : "cursor-not-allowed opacity-60"
                          )}
                        >
                          <CloudDownloadIcon className="h-4 w-4 mr-1.5" />
                          Report File
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
