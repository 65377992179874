import { ArrowLeftIcon } from "@heroicons/react/outline";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function EditOperationalNeed() {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const id = params.id;
    if (id) {
      fetchOperationalNeed(id);
    } else {
      showErrorToast();
    }
  }, []);

  const fetchOperationalNeed = async (id: string) => {
    apiV3
      .getOperationalNeedById(id)
      .then((res) => {
        if (res.status === 200 && res.data) {
          const data = res.data;
          setName(data.name);
        }
      })
      .catch((error) => {
        console.error(`Error occurred while fetching operational need with id ${id}`, error);
        showErrorToast();
      })
      .finally(() => setLoading(false));
  };

  const updateOperationalNeed = async () => {
    setLoading(true);
    try {
      if (params.id) {
        await apiV3.updateOperationalNeedById(params.id, name);
        showSuccessToast("Successfully updated operational need");
        navigate("/v3/operational-needs");
      } else {
        console.error(
          "Error occurred while updating operational need - couldn't find the operational need's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while updating operational need - ", error);
      showErrorToast();
    }
    setLoading(false);
  };

  const deleteOperationalNeed = async () => {
    setLoading(true);
    setModal(false);
    try {
      if (params.id) {
        await apiV3.deleteOperationalNeedById(params.id);
        showSuccessToast("Successfully deleted operational need");
        navigate("/v3/operational-needs");
      } else {
        console.error(
          "Error occurred while deleting operational need - couldn't find the operational need's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while deleting operational need - ", error);
      showErrorToast();
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    Edit Operational Need
                  </h1>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="pt-4">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="description"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="description"
                              id="description"
                              autoComplete="description"
                              disabled={loading}
                              value={name ?? ""}
                              onChange={(e) => setName(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="pt-5">
                        <div className="flex justify-start items-center">
                          <button
                            onClick={() => navigate("/v3/operational-needs")}
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => updateOperationalNeed()}
                            disabled={loading || !name}
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="pt-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Delete Operational Need
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Permanently delete operational need from the database
                        </p>
                      </div>
                      <div className="mt-6">
                        <button
                          type="button"
                          disabled={loading}
                          onClick={() => setModal(true)}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800 disabled:opacity-50"
                        >
                          <TrashIcon className="h-4 w-4 mr-1" />
                          Delete Operational Need
                        </button>
                      </div>
                      <DeleteModal
                        description="This operational need will be deleted from the database"
                        open={modal}
                        setOpen={setModal}
                        title={"Are you sure you want to delete this operational need?"}
                        action={() => deleteOperationalNeed()}
                        actionButtonText={"Delete"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
