import { Route, Routes } from "react-router-dom";
import SelfServeAuditV3 from "../../pages/V3/SelfServeAuditsV3/SelfServeAuditV3/SelfServeAuditV3";
import SelfServeAuditsV3 from "../../pages/V3/SelfServeAuditsV3/SelfServeAuditsV3";
import { RequireAuth } from "../../utils/auth/auth";

export default function SelfServeAuditsV3Router() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <RequireAuth>
            <SelfServeAuditsV3 />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:selfServeAuditId"
        element={
          <RequireAuth>
            <SelfServeAuditV3 />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
