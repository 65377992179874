import { ArrowLeftIcon, TrashIcon } from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { apiV3 } from "../../../../utils/helpers/api";
import { UpdateUserRequest } from "../../../../utils/types";
import { useToast } from "../../../../components/Toastr/Toastr";

export default function EditUserV3() {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<any>(null);
  const [userType, setUserType] = useState<string>("Admin");
  const [username, setUsername] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [active, setActive] = useState<boolean>(true);
  const [adminRole, setAdminRole] = useState<boolean>(false);
  const [airportAdminRole, setAirportAdminRole] = useState<boolean>(false);
  const [airportOfficerRole, setAirportOfficerRole] = useState<boolean>(false);
  const [selfServeAdminRole, setSelfServeAdminRole] = useState<boolean>(false);
  const [defaultLogoutTime, setDefaultLogoutTime] = useState<string>("");
  const [selfServeLocations, setSelfServeLocations] = useState<any[]>([]);
  const [selfServeLocationId, setSelfServeLocationId] = useState<string>("");
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    initializeUser().finally(() => setLoading(false));
    fetchSelfServeLocations();
  }, []);

  const initializeUser = async () => {
    // Get user from database
    const userId = params.userId;

    try {
      if (userId) {
        // Try getting admin user
        const userRes = await apiV3.getUserById(userId);
        const userData = userRes.data;
        setUser(userData);
        setUsername(userData.username);
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setEmail(userData.email);
        setActive(userData.active);
        setDefaultLogoutTime(
          userData.defaultLogoutTime ? userData.defaultLogoutTime : `${12 * 60 * 60}`
        );
        setSelfServeLocationId(userData.selfServeLocation ? userData.selfServeLocation.id : "");

        if (userData.role === 1) {
          setAdminRole(true);
          setUserType("Admin");
        } else if (userData.role === 2) {
          setAirportAdminRole(true);
        } else if (userData.role === 3) {
          setAirportOfficerRole(true);
        } else if (userData.role === 4) {
          setSelfServeAdminRole(true);
        }
      } else {
        showErrorToast();
      }
    } catch (error) {
      console.error(`Error occurred while initializing user with user id ${userId}`, error);
    }
  };

  const fetchSelfServeLocations = async () => {
    try {
      const locations = await apiV3.getSelfServeLocations(1, 50, "", "", "DESC", "");
      setSelfServeLocations(locations.data.data);
    } catch (error) {
      console.error(`Error occurred while fetching self serve locations`, error);
    }
  };

  const updateUser = async () => {
    setLoading(true);
    try {
      if (params.userId) {
        const updatedUser = {
          username,
          email,
          firstName,
          lastName,
          active,
        } as UpdateUserRequest;

        if (userType === "Admin" && adminRole) updatedUser.role = 1;
        if (airportAdminRole) updatedUser.role = 2;
        if (airportOfficerRole) updatedUser.role = 3;
        if (selfServeAdminRole) updatedUser.role = 4;
        if (newPassword) updatedUser.password = newPassword;

        if (airportOfficerRole) {
          updatedUser.defaultLogoutTime = defaultLogoutTime;
        }

        if (airportOfficerRole || selfServeAdminRole) {
          updatedUser.selfServeLocationId = selfServeLocationId;
        }

        await apiV3.updateUserById(params.userId, updatedUser);
        showSuccessToast("Successfully updated user");
      } else {
        console.error(
          "Error occurred while updating user - couldn't find the user's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while updating user - ", error);
      showErrorToast();
    }
    setLoading(false);
  };

  const deleteUser = async () => {
    setLoading(true);
    setOpenDeleteUserModal(false);
    try {
      if (params.userId) {
        await apiV3.deleteUserById(params.userId);
        showSuccessToast("Successfully deleted user");
        setTimeout(() => navigate("/v3/user-management"), 3000);
      } else {
        console.error(
          "Error occurred while deleting user - couldn't find the user's id from the URL parameters"
        );
        showErrorToast();
      }
    } catch (error) {
      console.error("Error occurred while deleting user - ", error);
      showErrorToast();
      setLoading(false);
    }
  };

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    Edit User {username && <em className="ml-1">({username})</em>}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Edit the details and role of a user or delete them from the system
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div>
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Profile</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          This information will be displayed publicly
                        </p>
                      </div>

                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="username"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Username{showAsteriskIfEmpty(username)}
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="username"
                              id="username"
                              autoComplete="username"
                              disabled={loading}
                              value={username ?? ""}
                              onChange={(e) => setUsername(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First name{showAsteriskIfEmpty(firstName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              disabled={loading}
                              value={firstName ?? ""}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last name{showAsteriskIfEmpty(lastName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              disabled={loading}
                              value={lastName ?? ""}
                              onChange={(e) => setLastName(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-4">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address{showAsteriskIfEmpty(email)}
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              disabled={loading}
                              value={email ?? ""}
                              onChange={(e) => setEmail(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="new-password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            New password <em>(optional)</em>
                          </label>
                          <div className="mt-1">
                            <input
                              id="new-password"
                              name="new-password"
                              type="password"
                              autoComplete="new-password"
                              disabled={loading}
                              value={newPassword ?? ""}
                              onChange={(e) => setNewPassword(e.target.value)}
                              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Role
                          {showAsteriskIfEmpty(
                            adminRole ||
                              airportAdminRole ||
                              airportOfficerRole ||
                              selfServeAdminRole
                              ? "not empty"
                              : ""
                          )}
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">Control user permissions</p>
                      </div>
                      <div className="pt-2">
                        <fieldset>
                          <div className="mt-4 space-y-4">
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="admin"
                                  name="admin"
                                  type="checkbox"
                                  disabled={
                                    loading ||
                                    userType !== "Admin" ||
                                    airportAdminRole ||
                                    airportOfficerRole ||
                                    selfServeAdminRole
                                  }
                                  checked={adminRole}
                                  onChange={(e) => setAdminRole(e.target.checked)}
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded disabled:opacity-50"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="admin" className="font-medium text-gray-700">
                                  Admin
                                </label>
                              </div>
                            </div>

                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="airportAdmin"
                                  name="airportAdmin"
                                  type="checkbox"
                                  disabled={
                                    loading || airportOfficerRole || adminRole || selfServeAdminRole
                                  }
                                  checked={airportAdminRole}
                                  onChange={(e) => setAirportAdminRole(e.target.checked)}
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded disabled:opacity-50"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="airportAdmin" className="font-medium text-gray-700">
                                  Airport Admin
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="airportOfficer"
                                  name="airportOfficer"
                                  type="checkbox"
                                  disabled={
                                    loading || airportAdminRole || adminRole || selfServeAdminRole
                                  }
                                  checked={airportOfficerRole}
                                  onChange={(e) => setAirportOfficerRole(e.target.checked)}
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded disabled:opacity-50"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="airportOfficer"
                                  className="font-medium text-gray-700"
                                >
                                  Airport Officer
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="selfServeAdmin"
                                  name="selfServeAdmin"
                                  type="checkbox"
                                  disabled={
                                    loading || airportAdminRole || adminRole || airportOfficerRole
                                  }
                                  checked={selfServeAdminRole}
                                  onChange={(e) => setSelfServeAdminRole(e.target.checked)}
                                  className="focus:ring-amber-500 h-4 w-4 text-amber-500 border-gray-300 rounded disabled:opacity-50"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="selfServeAdmin"
                                  className="font-medium text-gray-700"
                                >
                                  Self Serve Admin
                                </label>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    {airportOfficerRole && (
                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Default Logout Time
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Choose an optional time to automatically log out the user
                          </p>
                        </div>
                        <div className="pt-2">
                          <div>
                            <label
                              htmlFor="default-logout-time"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Default Logout Time
                            </label>
                            <select
                              id="default-logout-time"
                              name="default-logout-time"
                              disabled={loading}
                              onChange={(e) => setDefaultLogoutTime(e.target.value)}
                              value={defaultLogoutTime}
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-500 sm:text-sm sm:leading-6"
                            >
                              <option key="all" value="">
                                None
                              </option>
                              {[
                                { value: `${24 * 60 * 60}`, name: "24 Hours" },
                                { value: `${12 * 60 * 60}`, name: "12 Hours" },
                                { value: `${6 * 60 * 60}`, name: "6 Hours" },
                                { value: `${3 * 60 * 60}`, name: "3 Hours" },
                                { value: `${2 * 60 * 60}`, name: "2 Hours" },
                                { value: `${60 * 60}`, name: "1 Hour" },
                              ].map((logoutTime) => (
                                <option key={logoutTime.value} value={logoutTime.value}>
                                  {logoutTime.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                    {(airportOfficerRole || selfServeAdminRole) && (
                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">Location</h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Choose an optional self-serve location to restrict this user to
                          </p>
                        </div>
                        <div className="pt-2">
                          <div>
                            <label
                              htmlFor="self-serve-location"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Self Serve Location
                            </label>
                            <select
                              id="self-serve-location"
                              name="self-serve-location"
                              disabled={loading}
                              onChange={(e) => setSelfServeLocationId(e.target.value)}
                              value={selfServeLocationId}
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-500 sm:text-sm sm:leading-6"
                            >
                              <option key="all" value="">
                                All
                              </option>
                              {selfServeLocations.map((ssl) => (
                                <option key={ssl.id} value={ssl.id}>
                                  {ssl.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="pt-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Delete User</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Delete a user and all associated data permanently
                        </p>
                      </div>
                      <div className="mt-6">
                        <button
                          type="button"
                          onClick={() => setOpenDeleteUserModal(true)}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <TrashIcon className="h-4 w-4 mr-1" />
                          Delete User
                        </button>
                      </div>
                      <DeleteModal
                        description="Deleting a user is permanent. You will not be able to recover data once deleted"
                        open={openDeleteUserModal}
                        setOpen={setOpenDeleteUserModal}
                        title={"Are you sure you want to delete this user?"}
                        action={() => deleteUser()}
                      />
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/v3/user-management")}
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => updateUser()}
                        disabled={
                          loading ||
                          !username ||
                          !firstName ||
                          !lastName ||
                          !email ||
                          (!adminRole &&
                            !airportAdminRole &&
                            !airportOfficerRole &&
                            !selfServeAdminRole)
                        }
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Save
                      </button>
                      {(!username ||
                        !firstName ||
                        !lastName ||
                        !email ||
                        (!adminRole &&
                          !airportAdminRole &&
                          !airportOfficerRole &&
                          !selfServeAdminRole)) && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to update user
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
